import './Form.svelte.css';
/* src/features/financeDataForm/presentation/Form.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	handle_promise,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out,
	update_await_block_branch
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { Checkbox, InlineLoading, TextInput } from "../../../../_snowpack/pkg/carbon-components-svelte.js";
import "../../../../_snowpack/pkg/flatpickr/dist/l10n/de.js";
import { createForm } from "../../../../_snowpack/pkg/svelte-forms-lib.js";
import { navigate } from "../../../../_snowpack/pkg/svelte-routing.js";
import formController from "../application/FormControllerImpl.js";
import userInformationController from "../application/UserInformationControllerImpl.js";
import { formValidationSchema } from "../domain/formSchema.js";
import AddPersonButton from "./components/AddPersonButton.svelte.js";
import FormNumberTextInput from "./components/FormNumberTextInput.svelte.js";
import HomeCloudButton from "./components/HomeCloudButton.svelte.js";
import ErrorLabel from "./ErrorLabel.svelte.js";

function create_else_block_1(ctx) {
	let h6;

	return {
		c() {
			h6 = element("h6");
			h6.textContent = "1. Person";
			attr(h6, "class", "svelte-17fn2ts");
		},
		m(target, anchor) {
			insert(target, h6, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(h6);
		}
	};
}

// (47:4) {#if userInformation?.full_name}
function create_if_block_3(ctx) {
	let h6;
	let t_value = /*userInformation*/ ctx[3]?.full_name + "";
	let t;

	return {
		c() {
			h6 = element("h6");
			t = text(t_value);
			attr(h6, "class", "svelte-17fn2ts");
		},
		m(target, anchor) {
			insert(target, h6, anchor);
			append(h6, t);
		},
		p(ctx, dirty) {
			if (dirty & /*userInformation*/ 8 && t_value !== (t_value = /*userInformation*/ ctx[3]?.full_name + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(h6);
		}
	};
}

// (85:4) {#if !isShowPerson2Fields}
function create_if_block_2(ctx) {
	let addpersonbutton;
	let current;
	addpersonbutton = new AddPersonButton({});
	addpersonbutton.$on("click", /*showPerson2Fields*/ ctx[7]);

	return {
		c() {
			create_component(addpersonbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(addpersonbutton, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(addpersonbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(addpersonbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(addpersonbutton, detaching);
		}
	};
}

// (88:4) {#if isShowPerson2Fields}
function create_if_block_1(ctx) {
	let h6;
	let t1;
	let textinput0;
	let updating_value;
	let t2;
	let textinput1;
	let updating_value_1;
	let t3;
	let formnumbertextinput;
	let current;

	function textinput0_value_binding_1(value) {
		/*textinput0_value_binding_1*/ ctx[19](value);
	}

	let textinput0_props = {
		id: "birthDate2",
		name: "birthDate2",
		labelText: "Geburtstag",
		type: "date",
		invalidText: /*$errors*/ ctx[5].birthDate2,
		invalid: Boolean(/*$errors*/ ctx[5].birthDate2),
		light: true
	};

	if (/*$form*/ ctx[4].birthDate2 !== void 0) {
		textinput0_props.value = /*$form*/ ctx[4].birthDate2;
	}

	textinput0 = new TextInput({ props: textinput0_props });
	binding_callbacks.push(() => bind(textinput0, 'value', textinput0_value_binding_1));

	textinput0.$on("blur", function () {
		if (is_function(/*wasValidated*/ ctx[2]
		? /*handleChange*/ ctx[12]
		: blur_handler_2)) (/*wasValidated*/ ctx[2]
		? /*handleChange*/ ctx[12]
		: blur_handler_2).apply(this, arguments);
	});

	function textinput1_value_binding_1(value) {
		/*textinput1_value_binding_1*/ ctx[20](value);
	}

	let textinput1_props = {
		id: "employedSinceDate2",
		name: "employedSinceDate2",
		labelText: "Beschäftigt seit",
		type: "date",
		invalidText: /*$errors*/ ctx[5].employedSinceDate2,
		invalid: Boolean(/*$errors*/ ctx[5].employedSinceDate2),
		light: true
	};

	if (/*$form*/ ctx[4].employedSinceDate2 !== void 0) {
		textinput1_props.value = /*$form*/ ctx[4].employedSinceDate2;
	}

	textinput1 = new TextInput({ props: textinput1_props });
	binding_callbacks.push(() => bind(textinput1, 'value', textinput1_value_binding_1));

	textinput1.$on("blur", function () {
		if (is_function(/*wasValidated*/ ctx[2]
		? /*handleChange*/ ctx[12]
		: blur_handler_3)) (/*wasValidated*/ ctx[2]
		? /*handleChange*/ ctx[12]
		: blur_handler_3).apply(this, arguments);
	});

	formnumbertextinput = new FormNumberTextInput({
			props: {
				id: "monthlyNetIncome2",
				name: "monthlyNetIncome2",
				labelText: "Monatliches Nettoeinkommen",
				invalidText: /*$errors*/ ctx[5].monthlyNetIncome2,
				invalid: Boolean(/*$errors*/ ctx[5].monthlyNetIncome2),
				updateField: /*updateField*/ ctx[13],
				updateTouched: /*updateTouched*/ ctx[14],
				wasValidated: /*wasValidated*/ ctx[2]
			}
		});

	return {
		c() {
			h6 = element("h6");
			h6.textContent = "Person 2";
			t1 = space();
			create_component(textinput0.$$.fragment);
			t2 = space();
			create_component(textinput1.$$.fragment);
			t3 = space();
			create_component(formnumbertextinput.$$.fragment);
			attr(h6, "class", "svelte-17fn2ts");
		},
		m(target, anchor) {
			insert(target, h6, anchor);
			insert(target, t1, anchor);
			mount_component(textinput0, target, anchor);
			insert(target, t2, anchor);
			mount_component(textinput1, target, anchor);
			insert(target, t3, anchor);
			mount_component(formnumbertextinput, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const textinput0_changes = {};
			if (dirty & /*$errors*/ 32) textinput0_changes.invalidText = /*$errors*/ ctx[5].birthDate2;
			if (dirty & /*$errors*/ 32) textinput0_changes.invalid = Boolean(/*$errors*/ ctx[5].birthDate2);

			if (!updating_value && dirty & /*$form*/ 16) {
				updating_value = true;
				textinput0_changes.value = /*$form*/ ctx[4].birthDate2;
				add_flush_callback(() => updating_value = false);
			}

			textinput0.$set(textinput0_changes);
			const textinput1_changes = {};
			if (dirty & /*$errors*/ 32) textinput1_changes.invalidText = /*$errors*/ ctx[5].employedSinceDate2;
			if (dirty & /*$errors*/ 32) textinput1_changes.invalid = Boolean(/*$errors*/ ctx[5].employedSinceDate2);

			if (!updating_value_1 && dirty & /*$form*/ 16) {
				updating_value_1 = true;
				textinput1_changes.value = /*$form*/ ctx[4].employedSinceDate2;
				add_flush_callback(() => updating_value_1 = false);
			}

			textinput1.$set(textinput1_changes);
			const formnumbertextinput_changes = {};
			if (dirty & /*$errors*/ 32) formnumbertextinput_changes.invalidText = /*$errors*/ ctx[5].monthlyNetIncome2;
			if (dirty & /*$errors*/ 32) formnumbertextinput_changes.invalid = Boolean(/*$errors*/ ctx[5].monthlyNetIncome2);
			if (dirty & /*wasValidated*/ 4) formnumbertextinput_changes.wasValidated = /*wasValidated*/ ctx[2];
			formnumbertextinput.$set(formnumbertextinput_changes);
		},
		i(local) {
			if (current) return;
			transition_in(textinput0.$$.fragment, local);
			transition_in(textinput1.$$.fragment, local);
			transition_in(formnumbertextinput.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(textinput0.$$.fragment, local);
			transition_out(textinput1.$$.fragment, local);
			transition_out(formnumbertextinput.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h6);
			if (detaching) detach(t1);
			destroy_component(textinput0, detaching);
			if (detaching) detach(t2);
			destroy_component(textinput1, detaching);
			if (detaching) detach(t3);
			destroy_component(formnumbertextinput, detaching);
		}
	};
}

// (181:6) {:else}
function create_else_block(ctx) {
	let div0;
	let checkbox;
	let updating_checked;
	let t0;
	let p0;
	let t2;
	let div1;
	let p1;
	let t3;
	let a0;
	let t5;
	let a1;
	let t7;
	let t8;
	let div2;
	let t10;
	let errorlabel;
	let current;
	let mounted;
	let dispose;

	function checkbox_checked_binding_2(value) {
		/*checkbox_checked_binding_2*/ ctx[24](value);
	}

	let checkbox_props = {
		id: "isHomeContactWanted",
		name: "isHomeContactWanted"
	};

	if (/*$form*/ ctx[4].isHomeContactWanted !== void 0) {
		checkbox_props.checked = /*$form*/ ctx[4].isHomeContactWanted;
	}

	checkbox = new Checkbox({ props: checkbox_props });
	binding_callbacks.push(() => bind(checkbox, 'checked', checkbox_checked_binding_2));

	errorlabel = new ErrorLabel({
			props: {
				error: /*$errors*/ ctx[5].isHomeContactWanted
			}
		});

	return {
		c() {
			div0 = element("div");
			create_component(checkbox.$$.fragment);
			t0 = space();
			p0 = element("p");
			p0.textContent = "Ich möchte eine persönliche Beratung zum Ergebnis meines\n            Finanzierungschecks und stimme der Übermittlung meiner Daten und der\n            Kontaktierung per E-Mail durch einen Finanzierungsprofi zu.";
			t2 = space();
			div1 = element("div");
			p1 = element("p");
			t3 = text("Die Homecloud Datenschutzhinweise findest du\n            \n            ");
			a0 = element("a");
			a0.textContent = "hier";
			t5 = text("\n            und die Datenschutzhinweise unseres Finanzierungspartners\n            \n            ");
			a1 = element("a");
			a1.textContent = "hier";
			t7 = text(".");
			t8 = space();
			div2 = element("div");

			div2.innerHTML = `<p>Wir würden dich gerne über unsere ähnlichen Dienstleistungen
            informieren. Du kannst der Verwendung deiner Daten jederzeit
            kostenfrei widersprechen.</p>`;

			t10 = space();
			create_component(errorlabel.$$.fragment);
			attr(div0, "class", "checkBoxTextContainer svelte-17fn2ts");
			set_style(a0, "color", "#8C8C8C");
			attr(a0, "class", "svelte-17fn2ts");
			set_style(a1, "color", "#8C8C8C");
			attr(a1, "class", "svelte-17fn2ts");
			set_style(div1, "margin-bottom", "15px");
			set_style(div1, "display", "flex");
			set_style(div1, "align-items", "center");
			set_style(div1, "justify-content", "center");
			set_style(div1, "color", "#8C8C8C");
			set_style(div2, "margin-bottom", "15px");
			set_style(div2, "color", "#8C8C8C");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			mount_component(checkbox, div0, null);
			append(div0, t0);
			append(div0, p0);
			insert(target, t2, anchor);
			insert(target, div1, anchor);
			append(div1, p1);
			append(p1, t3);
			append(p1, a0);
			append(p1, t5);
			append(p1, a1);
			append(p1, t7);
			insert(target, t8, anchor);
			insert(target, div2, anchor);
			insert(target, t10, anchor);
			mount_component(errorlabel, target, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(a0, "click", /*click_handler_1*/ ctx[25]),
					listen(a1, "click", /*click_handler_2*/ ctx[26])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			const checkbox_changes = {};

			if (!updating_checked && dirty & /*$form*/ 16) {
				updating_checked = true;
				checkbox_changes.checked = /*$form*/ ctx[4].isHomeContactWanted;
				add_flush_callback(() => updating_checked = false);
			}

			checkbox.$set(checkbox_changes);
			const errorlabel_changes = {};
			if (dirty & /*$errors*/ 32) errorlabel_changes.error = /*$errors*/ ctx[5].isHomeContactWanted;
			errorlabel.$set(errorlabel_changes);
		},
		i(local) {
			if (current) return;
			transition_in(checkbox.$$.fragment, local);
			transition_in(errorlabel.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(checkbox.$$.fragment, local);
			transition_out(errorlabel.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div0);
			destroy_component(checkbox);
			if (detaching) detach(t2);
			if (detaching) detach(div1);
			if (detaching) detach(t8);
			if (detaching) detach(div2);
			if (detaching) detach(t10);
			destroy_component(errorlabel, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (167:6) {#if isHomeContactWanted}
function create_if_block(ctx) {
	let div;
	let checkbox;
	let updating_checked;
	let t0;
	let p;
	let current;

	function checkbox_checked_binding_1(value) {
		/*checkbox_checked_binding_1*/ ctx[23](value);
	}

	let checkbox_props = {
		id: "isHomeContactWanted",
		name: "isHomeContactWanted",
		required: true
	};

	if (/*$form*/ ctx[4].isHomeContactWanted !== void 0) {
		checkbox_props.checked = /*$form*/ ctx[4].isHomeContactWanted;
	}

	checkbox = new Checkbox({ props: checkbox_props });
	binding_callbacks.push(() => bind(checkbox, 'checked', checkbox_checked_binding_1));

	return {
		c() {
			div = element("div");
			create_component(checkbox.$$.fragment);
			t0 = space();
			p = element("p");
			p.textContent = "Ich möchte eine persönliche Beratung zum Ergebnis meines\n            Finanzierungschecks und stimme der Übermittlung meiner Daten und der\n            Kontaktierung per E-Mail durch einen Finanzierungsprofi zu.";
			attr(div, "class", "checkBoxTextContainer svelte-17fn2ts");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(checkbox, div, null);
			append(div, t0);
			append(div, p);
			current = true;
		},
		p(ctx, dirty) {
			const checkbox_changes = {};

			if (!updating_checked && dirty & /*$form*/ 16) {
				updating_checked = true;
				checkbox_changes.checked = /*$form*/ ctx[4].isHomeContactWanted;
				add_flush_callback(() => updating_checked = false);
			}

			checkbox.$set(checkbox_changes);
		},
		i(local) {
			if (current) return;
			transition_in(checkbox.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(checkbox.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(checkbox);
		}
	};
}

// (1:0) <script lang="ts">import { Checkbox, InlineLoading, TextInput }
function create_catch_block(ctx) {
	return {
		c: noop,
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

// (236:6) {:then}
function create_then_block(ctx) {
	let homecloudbutton;
	let current;

	homecloudbutton = new HomeCloudButton({
			props: {
				label: "Finanzierung checken",
				type: "submit",
				onClick: /*handleButtonClick*/ ctx[8]
			}
		});

	return {
		c() {
			create_component(homecloudbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(homecloudbutton, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(homecloudbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(homecloudbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(homecloudbutton, detaching);
		}
	};
}

// (227:30)          <div class="disabledButtonContainer">           <InlineLoading />           <HomeCloudButton             label="Finanzierung checken"             onClick={handleButtonClick}
function create_pending_block(ctx) {
	let div;
	let inlineloading;
	let t;
	let homecloudbutton;
	let current;
	inlineloading = new InlineLoading({});

	homecloudbutton = new HomeCloudButton({
			props: {
				label: "Finanzierung checken",
				onClick: /*handleButtonClick*/ ctx[8],
				disabled: true
			}
		});

	return {
		c() {
			div = element("div");
			create_component(inlineloading.$$.fragment);
			t = space();
			create_component(homecloudbutton.$$.fragment);
			attr(div, "class", "disabledButtonContainer svelte-17fn2ts");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(inlineloading, div, null);
			append(div, t);
			mount_component(homecloudbutton, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(inlineloading.$$.fragment, local);
			transition_in(homecloudbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(inlineloading.$$.fragment, local);
			transition_out(homecloudbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(inlineloading);
			destroy_component(homecloudbutton);
		}
	};
}

function create_fragment(ctx) {
	let form_1;
	let div4;
	let t0;
	let textinput0;
	let updating_value;
	let t1;
	let textinput1;
	let updating_value_1;
	let t2;
	let formnumbertextinput0;
	let t3;
	let t4;
	let t5;
	let div0;
	let t6;
	let formnumbertextinput1;
	let t7;
	let formnumbertextinput2;
	let t8;
	let div2;
	let div1;
	let checkbox;
	let updating_checked;
	let t9;
	let p;
	let t10;
	let a;
	let t12;
	let t13;
	let errorlabel;
	let t14;
	let current_block_type_index;
	let if_block3;
	let t15;
	let div3;
	let promise;
	let current;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*userInformation*/ ctx[3]?.full_name) return create_if_block_3;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);

	function textinput0_value_binding(value) {
		/*textinput0_value_binding*/ ctx[17](value);
	}

	let textinput0_props = {
		id: "birthDate1",
		name: "birthDate1",
		labelText: "Geburtstag",
		type: "date",
		invalidText: /*$errors*/ ctx[5].birthDate1,
		invalid: Boolean(/*$errors*/ ctx[5].birthDate1),
		light: true
	};

	if (/*$form*/ ctx[4].birthDate1 !== void 0) {
		textinput0_props.value = /*$form*/ ctx[4].birthDate1;
	}

	textinput0 = new TextInput({ props: textinput0_props });
	binding_callbacks.push(() => bind(textinput0, 'value', textinput0_value_binding));

	textinput0.$on("blur", function () {
		if (is_function(/*wasValidated*/ ctx[2]
		? /*handleChange*/ ctx[12]
		: blur_handler)) (/*wasValidated*/ ctx[2]
		? /*handleChange*/ ctx[12]
		: blur_handler).apply(this, arguments);
	});

	function textinput1_value_binding(value) {
		/*textinput1_value_binding*/ ctx[18](value);
	}

	let textinput1_props = {
		id: "employedSinceDate1",
		name: "employedSinceDate1",
		labelText: "Beschäftigt seit",
		type: "date",
		invalidText: /*$errors*/ ctx[5].employedSinceDate1,
		invalid: Boolean(/*$errors*/ ctx[5].employedSinceDate1),
		light: true
	};

	if (/*$form*/ ctx[4].employedSinceDate1 !== void 0) {
		textinput1_props.value = /*$form*/ ctx[4].employedSinceDate1;
	}

	textinput1 = new TextInput({ props: textinput1_props });
	binding_callbacks.push(() => bind(textinput1, 'value', textinput1_value_binding));

	textinput1.$on("blur", function () {
		if (is_function(/*wasValidated*/ ctx[2]
		? /*handleChange*/ ctx[12]
		: blur_handler_1)) (/*wasValidated*/ ctx[2]
		? /*handleChange*/ ctx[12]
		: blur_handler_1).apply(this, arguments);
	});

	formnumbertextinput0 = new FormNumberTextInput({
			props: {
				id: "monthlyNetIncome1",
				name: "monthlyNetIncome1",
				labelText: "Monatliches Nettoeinkommen",
				invalidText: /*$errors*/ ctx[5].monthlyNetIncome1,
				invalid: Boolean(/*$errors*/ ctx[5].monthlyNetIncome1),
				updateField: /*updateField*/ ctx[13],
				updateTouched: /*updateTouched*/ ctx[14],
				wasValidated: /*wasValidated*/ ctx[2]
			}
		});

	let if_block1 = !/*isShowPerson2Fields*/ ctx[1] && create_if_block_2(ctx);
	let if_block2 = /*isShowPerson2Fields*/ ctx[1] && create_if_block_1(ctx);

	formnumbertextinput1 = new FormNumberTextInput({
			props: {
				id: "equityTotal",
				name: "equityTotal",
				labelText: "Gesamt verfügbares Eigenkapital",
				invalidText: /*$errors*/ ctx[5].equityTotal,
				invalid: Boolean(/*$errors*/ ctx[5].equityTotal),
				updateField: /*updateField*/ ctx[13],
				updateTouched: /*updateTouched*/ ctx[14],
				wasValidated: /*wasValidated*/ ctx[2]
			}
		});

	formnumbertextinput2 = new FormNumberTextInput({
			props: {
				id: "remainingDebtTotal",
				name: "remainingDebtTotal",
				labelText: "Gesamte Restschuld etwaiger Darlehen",
				invalidText: /*$errors*/ ctx[5].remainingDebtTotal,
				invalid: Boolean(/*$errors*/ ctx[5].remainingDebtTotal),
				updateField: /*updateField*/ ctx[13],
				updateTouched: /*updateTouched*/ ctx[14],
				wasValidated: /*wasValidated*/ ctx[2]
			}
		});

	function checkbox_checked_binding(value) {
		/*checkbox_checked_binding*/ ctx[21](value);
	}

	let checkbox_props = {
		id: "isDataForwardingToRealEstateAgentOk",
		name: "isDataForwardingToRealEstateAgentOk",
		labelText: undefined,
		required: true
	};

	if (/*$form*/ ctx[4].isDataForwardingToRealEstateAgentOk !== void 0) {
		checkbox_props.checked = /*$form*/ ctx[4].isDataForwardingToRealEstateAgentOk;
	}

	checkbox = new Checkbox({ props: checkbox_props });
	binding_callbacks.push(() => bind(checkbox, 'checked', checkbox_checked_binding));

	errorlabel = new ErrorLabel({
			props: {
				error: /*$errors*/ ctx[5].isDataForwardingToRealEstateAgentOk
			}
		});

	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (/*isHomeContactWanted*/ ctx[6]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type_1(ctx, -1);
	if_block3 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: false,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		blocks: [,,,]
	};

	handle_promise(promise = /*loanLeadPromise*/ ctx[0], info);

	return {
		c() {
			form_1 = element("form");
			div4 = element("div");
			if_block0.c();
			t0 = space();
			create_component(textinput0.$$.fragment);
			t1 = space();
			create_component(textinput1.$$.fragment);
			t2 = space();
			create_component(formnumbertextinput0.$$.fragment);
			t3 = space();
			if (if_block1) if_block1.c();
			t4 = space();
			if (if_block2) if_block2.c();
			t5 = space();
			div0 = element("div");
			t6 = space();
			create_component(formnumbertextinput1.$$.fragment);
			t7 = space();
			create_component(formnumbertextinput2.$$.fragment);
			t8 = space();
			div2 = element("div");
			div1 = element("div");
			create_component(checkbox.$$.fragment);
			t9 = space();
			p = element("p");
			t10 = text("Ich stimme dem Finanzierungscheck gemäß den Homecloud\n          \n          ");
			a = element("a");
			a.textContent = "AGB";
			t12 = text("\n          und der Übermittlung des Ergebnisses und meiner Daten an den Immobilienmakler\n          zu.");
			t13 = space();
			create_component(errorlabel.$$.fragment);
			t14 = space();
			if_block3.c();
			t15 = space();
			div3 = element("div");
			info.block.c();
			attr(div0, "class", "divider svelte-17fn2ts");
			attr(a, "class", "svelte-17fn2ts");
			attr(div1, "class", "checkBoxTextContainer svelte-17fn2ts");
			attr(div2, "class", "checkboxContainer svelte-17fn2ts");
			attr(div3, "class", "buttonContainer svelte-17fn2ts");
			attr(div4, "class", "container svelte-17fn2ts");
		},
		m(target, anchor) {
			insert(target, form_1, anchor);
			append(form_1, div4);
			if_block0.m(div4, null);
			append(div4, t0);
			mount_component(textinput0, div4, null);
			append(div4, t1);
			mount_component(textinput1, div4, null);
			append(div4, t2);
			mount_component(formnumbertextinput0, div4, null);
			append(div4, t3);
			if (if_block1) if_block1.m(div4, null);
			append(div4, t4);
			if (if_block2) if_block2.m(div4, null);
			append(div4, t5);
			append(div4, div0);
			append(div4, t6);
			mount_component(formnumbertextinput1, div4, null);
			append(div4, t7);
			mount_component(formnumbertextinput2, div4, null);
			append(div4, t8);
			append(div4, div2);
			append(div2, div1);
			mount_component(checkbox, div1, null);
			append(div1, t9);
			append(div1, p);
			append(p, t10);
			append(p, a);
			append(p, t12);
			append(div2, t13);
			mount_component(errorlabel, div2, null);
			append(div2, t14);
			if_blocks[current_block_type_index].m(div2, null);
			append(div4, t15);
			append(div4, div3);
			info.block.m(div3, info.anchor = null);
			info.mount = () => div3;
			info.anchor = null;
			current = true;

			if (!mounted) {
				dispose = [
					listen(a, "click", /*click_handler*/ ctx[22]),
					listen(form_1, "submit", /*handleSubmit*/ ctx[10])
				];

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div4, t0);
				}
			}

			const textinput0_changes = {};
			if (dirty & /*$errors*/ 32) textinput0_changes.invalidText = /*$errors*/ ctx[5].birthDate1;
			if (dirty & /*$errors*/ 32) textinput0_changes.invalid = Boolean(/*$errors*/ ctx[5].birthDate1);

			if (!updating_value && dirty & /*$form*/ 16) {
				updating_value = true;
				textinput0_changes.value = /*$form*/ ctx[4].birthDate1;
				add_flush_callback(() => updating_value = false);
			}

			textinput0.$set(textinput0_changes);
			const textinput1_changes = {};
			if (dirty & /*$errors*/ 32) textinput1_changes.invalidText = /*$errors*/ ctx[5].employedSinceDate1;
			if (dirty & /*$errors*/ 32) textinput1_changes.invalid = Boolean(/*$errors*/ ctx[5].employedSinceDate1);

			if (!updating_value_1 && dirty & /*$form*/ 16) {
				updating_value_1 = true;
				textinput1_changes.value = /*$form*/ ctx[4].employedSinceDate1;
				add_flush_callback(() => updating_value_1 = false);
			}

			textinput1.$set(textinput1_changes);
			const formnumbertextinput0_changes = {};
			if (dirty & /*$errors*/ 32) formnumbertextinput0_changes.invalidText = /*$errors*/ ctx[5].monthlyNetIncome1;
			if (dirty & /*$errors*/ 32) formnumbertextinput0_changes.invalid = Boolean(/*$errors*/ ctx[5].monthlyNetIncome1);
			if (dirty & /*wasValidated*/ 4) formnumbertextinput0_changes.wasValidated = /*wasValidated*/ ctx[2];
			formnumbertextinput0.$set(formnumbertextinput0_changes);

			if (!/*isShowPerson2Fields*/ ctx[1]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*isShowPerson2Fields*/ 2) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div4, t4);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*isShowPerson2Fields*/ ctx[1]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty & /*isShowPerson2Fields*/ 2) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div4, t5);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			const formnumbertextinput1_changes = {};
			if (dirty & /*$errors*/ 32) formnumbertextinput1_changes.invalidText = /*$errors*/ ctx[5].equityTotal;
			if (dirty & /*$errors*/ 32) formnumbertextinput1_changes.invalid = Boolean(/*$errors*/ ctx[5].equityTotal);
			if (dirty & /*wasValidated*/ 4) formnumbertextinput1_changes.wasValidated = /*wasValidated*/ ctx[2];
			formnumbertextinput1.$set(formnumbertextinput1_changes);
			const formnumbertextinput2_changes = {};
			if (dirty & /*$errors*/ 32) formnumbertextinput2_changes.invalidText = /*$errors*/ ctx[5].remainingDebtTotal;
			if (dirty & /*$errors*/ 32) formnumbertextinput2_changes.invalid = Boolean(/*$errors*/ ctx[5].remainingDebtTotal);
			if (dirty & /*wasValidated*/ 4) formnumbertextinput2_changes.wasValidated = /*wasValidated*/ ctx[2];
			formnumbertextinput2.$set(formnumbertextinput2_changes);
			const checkbox_changes = {};

			if (!updating_checked && dirty & /*$form*/ 16) {
				updating_checked = true;
				checkbox_changes.checked = /*$form*/ ctx[4].isDataForwardingToRealEstateAgentOk;
				add_flush_callback(() => updating_checked = false);
			}

			checkbox.$set(checkbox_changes);
			const errorlabel_changes = {};
			if (dirty & /*$errors*/ 32) errorlabel_changes.error = /*$errors*/ ctx[5].isDataForwardingToRealEstateAgentOk;
			errorlabel.$set(errorlabel_changes);
			if_block3.p(ctx, dirty);
			info.ctx = ctx;

			if (dirty & /*loanLeadPromise*/ 1 && promise !== (promise = /*loanLeadPromise*/ ctx[0]) && handle_promise(promise, info)) {
				
			} else {
				update_await_block_branch(info, ctx, dirty);
			}
		},
		i(local) {
			if (current) return;
			transition_in(textinput0.$$.fragment, local);
			transition_in(textinput1.$$.fragment, local);
			transition_in(formnumbertextinput0.$$.fragment, local);
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(formnumbertextinput1.$$.fragment, local);
			transition_in(formnumbertextinput2.$$.fragment, local);
			transition_in(checkbox.$$.fragment, local);
			transition_in(errorlabel.$$.fragment, local);
			transition_in(if_block3);
			transition_in(info.block);
			current = true;
		},
		o(local) {
			transition_out(textinput0.$$.fragment, local);
			transition_out(textinput1.$$.fragment, local);
			transition_out(formnumbertextinput0.$$.fragment, local);
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(formnumbertextinput1.$$.fragment, local);
			transition_out(formnumbertextinput2.$$.fragment, local);
			transition_out(checkbox.$$.fragment, local);
			transition_out(errorlabel.$$.fragment, local);
			transition_out(if_block3);

			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(form_1);
			if_block0.d();
			destroy_component(textinput0);
			destroy_component(textinput1);
			destroy_component(formnumbertextinput0);
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			destroy_component(formnumbertextinput1);
			destroy_component(formnumbertextinput2);
			destroy_component(checkbox);
			destroy_component(errorlabel);
			if_blocks[current_block_type_index].d();
			info.block.d();
			info.token = null;
			info = null;
			mounted = false;
			run_all(dispose);
		}
	};
}

function openInNewTap(url) {
	window.open(url, "_blank")?.focus();
}

const blur_handler = () => {
	
};

const blur_handler_1 = () => {
	
};

const blur_handler_2 = () => {
	
};

const blur_handler_3 = () => {
	
};

function instance($$self, $$props, $$invalidate) {
	let currentPath;
	let userInformation;
	let $formController;
	let $userInformationController;
	let $form;
	let $errors;
	component_subscribe($$self, formController, $$value => $$invalidate(27, $formController = $$value));
	component_subscribe($$self, userInformationController, $$value => $$invalidate(16, $userInformationController = $$value));
	let { loanLeadPromise } = $$props;
	const isHomeContactWanted = $userInformationController?.finAdviceFilled;
	let isShowPerson2Fields = false;
	let wasValidated = false;

	const showPerson2Fields = () => {
		$$invalidate(1, isShowPerson2Fields = true);
	};

	const handleButtonClick = () => {
		$$invalidate(2, wasValidated = true);
	};

	const handleFormSubmit = async value => {
		try {
			const formData = formValidationSchema.cast(value);
			formController.set(formData);
		} catch(error) {
			console.log(error);
		}

		$$invalidate(15, currentPath = "result");
	};

	const { form, handleSubmit, errors, handleChange, updateField, updateTouched } = createForm({
		initialValues: $formController,
		validationSchema: formValidationSchema,
		onSubmit: handleFormSubmit
	});

	component_subscribe($$self, form, value => $$invalidate(4, $form = value));
	component_subscribe($$self, errors, value => $$invalidate(5, $errors = value));

	function textinput0_value_binding(value) {
		if ($$self.$$.not_equal($form.birthDate1, value)) {
			$form.birthDate1 = value;
			form.set($form);
		}
	}

	function textinput1_value_binding(value) {
		if ($$self.$$.not_equal($form.employedSinceDate1, value)) {
			$form.employedSinceDate1 = value;
			form.set($form);
		}
	}

	function textinput0_value_binding_1(value) {
		if ($$self.$$.not_equal($form.birthDate2, value)) {
			$form.birthDate2 = value;
			form.set($form);
		}
	}

	function textinput1_value_binding_1(value) {
		if ($$self.$$.not_equal($form.employedSinceDate2, value)) {
			$form.employedSinceDate2 = value;
			form.set($form);
		}
	}

	function checkbox_checked_binding(value) {
		if ($$self.$$.not_equal($form.isDataForwardingToRealEstateAgentOk, value)) {
			$form.isDataForwardingToRealEstateAgentOk = value;
			form.set($form);
		}
	}

	const click_handler = () => openInNewTap("https://www.homecloud.de/nutzungsbedingungen");

	function checkbox_checked_binding_1(value) {
		if ($$self.$$.not_equal($form.isHomeContactWanted, value)) {
			$form.isHomeContactWanted = value;
			form.set($form);
		}
	}

	function checkbox_checked_binding_2(value) {
		if ($$self.$$.not_equal($form.isHomeContactWanted, value)) {
			$form.isHomeContactWanted = value;
			form.set($form);
		}
	}

	const click_handler_1 = () => openInNewTap("https://www.homecloud.de/datenschutz");
	const click_handler_2 = () => openInNewTap("https://meinedatenschutzhinweise.de/NAJ03.pdf");

	$$self.$$set = $$props => {
		if ('loanLeadPromise' in $$props) $$invalidate(0, loanLeadPromise = $$props.loanLeadPromise);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*currentPath*/ 32768) {
			$: navigate(currentPath);
		}

		if ($$self.$$.dirty & /*$userInformationController*/ 65536) {
			$: $$invalidate(3, userInformation = $userInformationController);
		}
	};

	$: $$invalidate(15, currentPath = "");

	return [
		loanLeadPromise,
		isShowPerson2Fields,
		wasValidated,
		userInformation,
		$form,
		$errors,
		isHomeContactWanted,
		showPerson2Fields,
		handleButtonClick,
		form,
		handleSubmit,
		errors,
		handleChange,
		updateField,
		updateTouched,
		currentPath,
		$userInformationController,
		textinput0_value_binding,
		textinput1_value_binding,
		textinput0_value_binding_1,
		textinput1_value_binding_1,
		checkbox_checked_binding,
		click_handler,
		checkbox_checked_binding_1,
		checkbox_checked_binding_2,
		click_handler_1,
		click_handler_2
	];
}

class Form extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { loanLeadPromise: 0 });
	}
}

export default Form;