import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

class Endpoint {
  constructor(url = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_BASE_URL) {
    this._url = url;
  }
  getBankContactInfo() {
    return this._extendUrl("getBankContactInfo");
  }
  surveyHasBeenCompleted() {
    return this._extendUrl("surveyHasBeenCompleted");
  }
  doesContactHaveSurvey() {
    return this._extendUrl("doesContactHaveSurvey");
  }
  createLateLoanLead() {
    return this._extendUrl("createLateLoanLead");
  }
  createNoLoanLeadWithTimeout() {
    return this._extendUrl("createNoLoanLeadWithTimeout");
  }
  createEarlyLoanLeadWithTimeout() {
    return this._extendUrl("createEarlyLoanLeadWithTimeout");
  }
  users(userId) {
    return this._extendUrl("users", {param: userId});
  }
  editor() {
    return this._extendUrl("editor");
  }
  metadata() {
    return this._extendUrl("metadata");
  }
  me() {
    return this._extendUrl("me");
  }
  signIn() {
    return this._extendUrl("signIn");
  }
  signUp() {
    return this._extendUrl("signUp");
  }
  signOut() {
    return this._extendUrl("signOut");
  }
  password() {
    return this._extendUrl("password");
  }
  reset() {
    return this._extendUrl("reset");
  }
  send() {
    return this._extendUrl("send");
  }
  email() {
    return this._extendUrl("email");
  }
  verify() {
    return this._extendUrl("verify");
  }
  roles(roleId) {
    return this._extendUrl("roles", {param: roleId});
  }
  organisations(organisationId) {
    return this._extendUrl("organisations", {param: organisationId});
  }
  partners(partnerId) {
    return this._extendUrl("partners", {param: partnerId});
  }
  groups(groupId) {
    return this._extendUrl("groups", {param: groupId});
  }
  files(fileId) {
    return this._extendUrl("files", {param: fileId});
  }
  symbols(symbolId) {
    return this._extendUrl("symbols", {param: symbolId});
  }
  image() {
    return this._extendUrl("image");
  }
  exports(jobId) {
    return this._extendUrl("exports", {param: jobId});
  }
  layers(orderNumber) {
    return this._extendUrl("layers", {param: orderNumber});
  }
  search(searchId) {
    return this._extendUrl("search", {param: searchId});
  }
  domains(domain) {
    return this._extendUrl("domains", {param: domain});
  }
  export(jobId) {
    return this._extendUrl("export", {param: jobId});
  }
  file() {
    return this._extendUrl("file");
  }
  predictions() {
    return this._extendUrl("predictions");
  }
  events() {
    return this._extendUrl("events");
  }
  upload() {
    return this._extendUrl("upload");
  }
  _extendUrl(path, option) {
    this._url = `${this._url}/${path}${option?.param !== void 0 ? `/${option.param}` : ""}`;
    if (option?.query && option.query.length > 0) {
      option.query.map((query, i) => {
        if (i === 0) {
          this._url = `${this._url}?${query.key}=${query.value}`;
        } else {
          this._url = `${this._url}&${query.key}=${query.value}`;
        }
      });
    }
    return this;
  }
  get $() {
    return this._url;
  }
}
export default Endpoint;
