/* src/features/financeDataForm/presentation/components/AddPersonButton.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	bubble,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	safe_not_equal,
	text,
	transition_in,
	transition_out
} from "../../../../../_snowpack/pkg/svelte/internal.js";

import { Button } from "../../../../../_snowpack/pkg/carbon-components-svelte.js";
import AddIcon from "../../../../../_snowpack/pkg/carbon-icons-svelte/lib/Add.svelte.js";

function create_default_slot(ctx) {
	let addicon;
	let t;
	let current;
	addicon = new AddIcon({});

	return {
		c() {
			create_component(addicon.$$.fragment);
			t = text("\n  weitere Person hinzufügen");
		},
		m(target, anchor) {
			mount_component(addicon, target, anchor);
			insert(target, t, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(addicon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(addicon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(addicon, detaching);
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let button;
	let current;

	button = new Button({
			props: {
				style: "background:#8c8c8c; border-radius:4px;display:flex; justify-content:center; align-items:center;height:15px;min-height:15px !important; width:fit-content; min-width:0px !important; padding:14px;",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	button.$on("click", /*click_handler*/ ctx[0]);

	return {
		c() {
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const button_changes = {};

			if (dirty & /*$$scope*/ 2) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
		}
	};
}

function instance($$self) {
	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	return [click_handler];
}

class AddPersonButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default AddPersonButton;