/* src/presentation/routes/HomeRoute.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import userInformationController from "../../features/financeDataForm/application/UserInformationControllerImpl.js";
import FormComponent from "../../features/financeDataForm/presentation/FormComponent.svelte.js";

function create_fragment(ctx) {
	let div;
	let formcomponent;
	let current;
	formcomponent = new FormComponent({});

	return {
		c() {
			div = element("div");
			create_component(formcomponent.$$.fragment);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(formcomponent, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(formcomponent.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(formcomponent.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(formcomponent);
		}
	};
}

function instance($$self) {
	const queryString = window.location.search;
	const params = new URLSearchParams(queryString);

	userInformationController.set({
		estate_id: params.get("estate_id") ?? "",
		contact_id: params.get("contact_id") ?? "",
		finAdviceFilled: params.get("finAdviceFilled") === "true",
		full_name: params.get("full_name") ?? undefined
	});

	return [];
}

class HomeRoute extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default HomeRoute;