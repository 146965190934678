import PromiseParser from "../infrastructure/api/PromiseParser.js";
class tokenFetcher {
  getToken() {
    const data = new URLSearchParams({
      client_id: "webapp_user",
      client_secret: "LFvJhUWk0M12w7A928ixK75ePWLwGhRG",
      grant_type: "client_credentials"
    });
    const token = fetch("https://keycloak-develop.genohub.io/auth/realms/genohub/protocol/openid-connect/token", {
      method: "POST",
      body: data,
      headers: {"Content-Type": "application/x-www-form-urlencoded"}
    });
    const promiseParser = new PromiseParser(token);
    return promiseParser.parse();
  }
}
export default tokenFetcher;
