/* src/presentation/routes/ResultPage.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	handle_promise,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out,
	update_await_block_branch
} from "../../../_snowpack/pkg/svelte/internal.js";

import { Loading } from "../../../_snowpack/pkg/carbon-components-svelte.js";
import formController from "../../features/financeDataForm/application/FormControllerImpl.js";
import surveyController from "../../features/financeDataForm/application/SurveyControllerImpl.js";
import ResultGreen from "../../features/financeDataForm/presentation/ResultGreen.svelte.js";
import ResultYellow from "../../features/financeDataForm/presentation/ResultYellow.svelte.js";
import userInformationController from "../../features/financeDataForm/application/UserInformationControllerImpl.js";

function create_catch_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<h4>Es ist ein Fehler aufgetreten</h4>`;
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (35:0) {:then value}
function create_then_block(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*value*/ ctx[3].feasibilityScoreAsGrade !== "B" && /*value*/ ctx[3].feasibilityScoreAsGrade !== "A") return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (38:2) {:else}
function create_else_block(ctx) {
	let resultgreen;
	let current;

	resultgreen = new ResultGreen({
			props: {
				ContactChecked: /*$formController*/ ctx[0].isHomeContactWanted
			}
		});

	return {
		c() {
			create_component(resultgreen.$$.fragment);
		},
		m(target, anchor) {
			mount_component(resultgreen, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const resultgreen_changes = {};
			if (dirty & /*$formController*/ 1) resultgreen_changes.ContactChecked = /*$formController*/ ctx[0].isHomeContactWanted;
			resultgreen.$set(resultgreen_changes);
		},
		i(local) {
			if (current) return;
			transition_in(resultgreen.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(resultgreen.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(resultgreen, detaching);
		}
	};
}

// (36:2) {#if value.feasibilityScoreAsGrade !== "B" && value.feasibilityScoreAsGrade !== "A"}
function create_if_block(ctx) {
	let resultyellow;
	let current;

	resultyellow = new ResultYellow({
			props: {
				ContactChecked: /*$formController*/ ctx[0].isHomeContactWanted
			}
		});

	return {
		c() {
			create_component(resultyellow.$$.fragment);
		},
		m(target, anchor) {
			mount_component(resultyellow, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const resultyellow_changes = {};
			if (dirty & /*$formController*/ 1) resultyellow_changes.ContactChecked = /*$formController*/ ctx[0].isHomeContactWanted;
			resultyellow.$set(resultyellow_changes);
		},
		i(local) {
			if (current) return;
			transition_in(resultyellow.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(resultyellow.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(resultyellow, detaching);
		}
	};
}

// (33:23)    <Loading /> {:then value}
function create_pending_block(ctx) {
	let loading;
	let current;
	loading = new Loading({});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

function create_fragment(ctx) {
	let await_block_anchor;
	let promise;
	let current;

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: true,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 3,
		blocks: [,,,]
	};

	handle_promise(promise = /*surveyResponse*/ ctx[1], info);

	return {
		c() {
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			info.ctx = ctx;

			if (dirty & /*surveyResponse*/ 2 && promise !== (promise = /*surveyResponse*/ ctx[1]) && handle_promise(promise, info)) {
				
			} else {
				update_await_block_branch(info, ctx, dirty);
			}
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $formController;
	let $userInformationController;
	component_subscribe($$self, formController, $$value => $$invalidate(0, $formController = $$value));
	component_subscribe($$self, userInformationController, $$value => $$invalidate(2, $userInformationController = $$value));
	let surveyResponse;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$userInformationController, $formController*/ 5) {
			$: $$invalidate(1, surveyResponse = surveyController.getResponse({
				fioContactId: $userInformationController?.contact_id ?? "",
				fioEstateId: $userInformationController?.estate_id ?? "",
				firstPersonBudget: {
					birthDate: $formController.birthDate1 ?? "",
					employedSinceDate: $formController.employedSinceDate1 ?? "",
					monthlyNetIncome: $formController.monthlyNetIncome1 ?? 0
				},
				secondPersonBudget: $formController.birthDate2 === undefined || $formController.monthlyNetIncome2 === undefined
				? undefined
				: {
						birthDate: $formController.birthDate2 ?? "",
						employedSinceDate: $formController.employedSinceDate2 ?? "",
						monthlyNetIncome: $formController.monthlyNetIncome2 ?? 0
					},
				householdBudget: {
					equityTotal: $formController.equityTotal ?? 0,
					remainingDebtTotal: $formController.remainingDebtTotal ?? 0
				},
				isDataForwardingToRealEstateAgentOk: $formController.isDataForwardingToRealEstateAgentOk,
				isLoanLeadWanted: $formController.isHomeContactWanted
			}));
		}
	};

	return [$formController, surveyResponse, $userInformationController];
}

class ResultPage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ResultPage;