import './ResultYellow.svelte.css';
/* src/features/financeDataForm/presentation/ResultYellow.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	handle_promise,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out,
	update_await_block_branch
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { Button, Loading } from "../../../../_snowpack/pkg/carbon-components-svelte.js";
import loanLeadController from "../application/LoanLeadControllerImpl.js";
import surveyController from "../application/SurveyControllerImpl.js";
import userInformationController from "../application/UserInformationControllerImpl.js";
import ClosingButton from "./components/ClosingButton.svelte.js";
import HomeCloudButton from "./components/HomeCloudButton.svelte.js";
import ResultDiv from "./ResultDiv.svelte.js";

function create_catch_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<h4 class="svelte-mfsc6g">Es ist ein Fehler aufgetreten</h4>`;
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (30:0) {:then}
function create_then_block(ctx) {
	let div2;
	let t0;
	let t1;
	let div0;
	let current_block_type_index;
	let if_block2;
	let t2;
	let div1;
	let p;
	let t3;
	let a;
	let t5;
	let current;
	let mounted;
	let dispose;
	let if_block0 = !/*ContactChecked*/ ctx[0] && create_if_block_2(ctx);

	function select_block_type(ctx, dirty) {
		if (!/*$userInformationController*/ ctx[3]?.finAdviceFilled && !/*ContactChecked*/ ctx[0]) return create_if_block_1;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block1 = current_block_type(ctx);
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (!/*$userInformationController*/ ctx[3]?.finAdviceFilled && !/*ContactChecked*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type_1(ctx, -1);
	if_block2 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div2 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if_block1.c();
			t1 = space();
			div0 = element("div");
			if_block2.c();
			t2 = space();
			div1 = element("div");
			p = element("p");
			t3 = text("Die Datenschutzhinweise unseres Finanzierungspartners findest du ");
			a = element("a");
			a.textContent = "hier";
			t5 = text(".");
			attr(div0, "class", "buttonContainer svelte-mfsc6g");
			set_style(a, "color", "#8C8C8C");
			attr(a, "class", "svelte-mfsc6g");
			set_style(p, "color", "#8C8C8C");
			set_style(p, "margin-top", "20px");
			attr(p, "class", "svelte-mfsc6g");
			attr(div1, "class", "textBox");
			attr(div2, "class", "container svelte-mfsc6g");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			if (if_block0) if_block0.m(div2, null);
			append(div2, t0);
			if_block1.m(div2, null);
			append(div2, t1);
			append(div2, div0);
			if_blocks[current_block_type_index].m(div0, null);
			append(div2, t2);
			append(div2, div1);
			append(div1, p);
			append(p, t3);
			append(p, a);
			append(p, t5);
			current = true;

			if (!mounted) {
				dispose = listen(a, "click", /*click_handler_1*/ ctx[6]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!/*ContactChecked*/ ctx[0]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*ContactChecked*/ 1) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div2, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block1) {
				if_block1.p(ctx, dirty);
			} else {
				if_block1.d(1);
				if_block1 = current_block_type(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(div2, t1);
				}
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block2 = if_blocks[current_block_type_index];

				if (!if_block2) {
					if_block2 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block2.c();
				} else {
					if_block2.p(ctx, dirty);
				}

				transition_in(if_block2, 1);
				if_block2.m(div0, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block0) if_block0.d();
			if_block1.d();
			if_blocks[current_block_type_index].d();
			mounted = false;
			dispose();
		}
	};
}

// (32:4) {#if !ContactChecked}
function create_if_block_2(ctx) {
	let h4;
	let t1;
	let p;
	let t2;

	let t3_value = Intl.NumberFormat("de-DE", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	}).format(/*purchasePrice*/ ctx[2]) + "";

	let t3;
	let t4;
	let t5;
	let resultdiv;
	let current;
	resultdiv = new ResultDiv({ props: { leadScore: "yellow" } });

	return {
		c() {
			h4 = element("h4");
			h4.textContent = "Dein Ergebnis";
			t1 = space();
			p = element("p");
			t2 = text("Der sofortige Finanzierungscheck hat mit deinen Angaben und einem\n        Kaufpreis von ");
			t3 = text(t3_value);
			t4 = text(" ergeben:");
			t5 = space();
			create_component(resultdiv.$$.fragment);
			attr(h4, "class", "svelte-mfsc6g");
			attr(p, "class", "svelte-mfsc6g");
		},
		m(target, anchor) {
			insert(target, h4, anchor);
			insert(target, t1, anchor);
			insert(target, p, anchor);
			append(p, t2);
			append(p, t3);
			append(p, t4);
			insert(target, t5, anchor);
			mount_component(resultdiv, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*purchasePrice*/ 4) && t3_value !== (t3_value = Intl.NumberFormat("de-DE", {
				style: "currency",
				currency: "EUR",
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			}).format(/*purchasePrice*/ ctx[2]) + "")) set_data(t3, t3_value);
		},
		i(local) {
			if (current) return;
			transition_in(resultdiv.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(resultdiv.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h4);
			if (detaching) detach(t1);
			if (detaching) detach(p);
			if (detaching) detach(t5);
			destroy_component(resultdiv, detaching);
		}
	};
}

// (50:4) {:else}
function create_else_block_1(ctx) {
	let p0;
	let t1;
	let div;
	let p1;
	let t2;
	let a;
	let t4;
	let mounted;
	let dispose;

	return {
		c() {
			p0 = element("p");
			p0.textContent = "Dein Beratungswunsch wird bearbeitet. Du bekommst in Kürze eine\n        Rückmeldung per E-Mail.";
			t1 = space();
			div = element("div");
			p1 = element("p");
			t2 = text("Die Datenschutzhinweise unseres Finanzierungspartners findest du ");
			a = element("a");
			a.textContent = "hier";
			t4 = text(".");
			attr(p0, "class", "svelte-mfsc6g");
			set_style(a, "color", "#8C8C8C");
			attr(a, "class", "svelte-mfsc6g");
			set_style(p1, "color", "#8C8C8C");
			set_style(p1, "margin-top", "29px");
			attr(p1, "class", "svelte-mfsc6g");
			attr(div, "class", "textBox");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			insert(target, t1, anchor);
			insert(target, div, anchor);
			append(div, p1);
			append(p1, t2);
			append(p1, a);
			append(p1, t4);

			if (!mounted) {
				dispose = listen(a, "click", /*click_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (45:4) {#if !$userInformationController?.finAdviceFilled && !ContactChecked}
function create_if_block_1(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			p.textContent = "Teile deine Daten mit einem Finanzierungsprofi und\n        lass dir in einem persönlichen Beratungsgespräch eine passende Finanzierung zusammenstellen.";
			attr(p, "class", "svelte-mfsc6g");
		},
		m(target, anchor) {
			insert(target, p, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (72:6) {:else}
function create_else_block(ctx) {
	let closingbutton;
	let current;

	closingbutton = new ClosingButton({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(closingbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(closingbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const closingbutton_changes = {};

			if (dirty & /*$$scope*/ 256) {
				closingbutton_changes.$$scope = { dirty, ctx };
			}

			closingbutton.$set(closingbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(closingbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(closingbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(closingbutton, detaching);
		}
	};
}

// (67:6) {#if !$userInformationController?.finAdviceFilled && !ContactChecked}
function create_if_block(ctx) {
	let homecloudbutton;
	let current;

	homecloudbutton = new HomeCloudButton({
			props: {
				label: "Kontaktanfrage schicken",
				onClick: /*handleClick*/ ctx[4]
			}
		});

	return {
		c() {
			create_component(homecloudbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(homecloudbutton, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(homecloudbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(homecloudbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(homecloudbutton, detaching);
		}
	};
}

// (73:8) <ClosingButton>
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Zurück zum Exposé");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (28:20)    <Loading /> {:then}
function create_pending_block(ctx) {
	let loading;
	let current;
	loading = new Loading({});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

function create_fragment(ctx) {
	let await_block_anchor;
	let promise;
	let current;

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: true,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		blocks: [,,,]
	};

	handle_promise(promise = /*loanPromise*/ ctx[1], info);

	return {
		c() {
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			info.ctx = ctx;

			if (dirty & /*loanPromise*/ 2 && promise !== (promise = /*loanPromise*/ ctx[1]) && handle_promise(promise, info)) {
				
			} else {
				update_await_block_branch(info, ctx, dirty);
			}
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

function openInNewTap(url) {
	window.open(url, "_blank")?.focus();
}

function instance($$self, $$props, $$invalidate) {
	let $userInformationController;
	let $surveyController;
	component_subscribe($$self, userInformationController, $$value => $$invalidate(3, $userInformationController = $$value));
	component_subscribe($$self, surveyController, $$value => $$invalidate(7, $surveyController = $$value));
	let { ContactChecked } = $$props;
	let loanPromise;
	let purchasePrice;

	if ($surveyController !== undefined) {
		purchasePrice = $surveyController.estateAttributes.purchasePrice;
	}

	const handleClick = () => {
		$$invalidate(0, ContactChecked = true);

		if ($userInformationController !== undefined) {
			$$invalidate(1, loanPromise = loanLeadController.createLateLoanLead({
				fioEstateId: $userInformationController.estate_id,
				fioContactId: $userInformationController.contact_id
			}));
		}
	};

	const click_handler = () => openInNewTap("https://meinedatenschutzhinweise.de/NAJ03.pdf");
	const click_handler_1 = () => openInNewTap("https://meinedatenschutzhinweise.de/NAJ03.pdf");

	$$self.$$set = $$props => {
		if ('ContactChecked' in $$props) $$invalidate(0, ContactChecked = $$props.ContactChecked);
	};

	return [
		ContactChecked,
		loanPromise,
		purchasePrice,
		$userInformationController,
		handleClick,
		click_handler,
		click_handler_1
	];
}

class ResultYellow extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { ContactChecked: 0 });
	}
}

export default ResultYellow;