import Endpoint from "../../../common/Endpoint.js";
import ApiServiceImpl from "../../../infrastructure/api/ApiServiceImpl.js";
class BankContactServiceImpl {
  constructor(service = new ApiServiceImpl()) {
    this.service = service;
  }
  getBankContactInfo(fioEstateId) {
    const url = new Endpoint().getBankContactInfo().$;
    return this.service.post(url, fioEstateId);
  }
}
export default BankContactServiceImpl;
