import './PictureInfo.svelte.css';
/* src/features/financeDataForm/presentation/PictureInfo.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	set_data,
	space,
	src_url_equal,
	text,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { Email, Phone, Time } from "../../../../_snowpack/pkg/carbon-icons-svelte.js";
import ServiceTimesComponent from "./ServiceTimesComponent.svelte.js";
import bankContactInfoController from "../application/BankContactInfoControllerImpl.js";

function create_fragment(ctx) {
	let div5;
	let div0;
	let img;
	let img_src_value;
	let t0;
	let p0;
	let t1_value = /*bankData*/ ctx[0].bankName + "";
	let t1;
	let t2;
	let br0;
	let t3;
	let t4_value = /*bankData*/ ctx[0].street + "";
	let t4;
	let t5;
	let t6_value = /*bankData*/ ctx[0].houseNumber + "";
	let t6;
	let t7;
	let br1;
	let t8;
	let t9_value = /*bankData*/ ctx[0].zipCode + "";
	let t9;
	let t10;
	let t11_value = /*bankData*/ ctx[0].city + "";
	let t11;
	let t12;
	let div4;
	let div1;
	let phone;
	let t13;
	let p1;
	let t14_value = /*bankData*/ ctx[0].phoneNumber + "";
	let t14;
	let t15;
	let div2;
	let email;
	let t16;
	let p2;
	let t17_value = /*bankData*/ ctx[0].email + "";
	let t17;
	let t18;
	let div3;
	let time;
	let t19;
	let p3;
	let t22;
	let servicetimescomponent;
	let current;
	let mounted;
	let dispose;
	phone = new Phone({ props: { style: "margin-right:10px" } });
	email = new Email({ props: { style: "margin-right:10px" } });
	time = new Time({ props: { style: "margin-right:10px" } });

	servicetimescomponent = new ServiceTimesComponent({
			props: {
				ServiceTimeMap: /*ServiceTimeMap*/ ctx[1]
			}
		});

	return {
		c() {
			div5 = element("div");
			div0 = element("div");
			img = element("img");
			t0 = space();
			p0 = element("p");
			t1 = text(t1_value);
			t2 = space();
			br0 = element("br");
			t3 = space();
			t4 = text(t4_value);
			t5 = text(" ");
			t6 = text(t6_value);
			t7 = space();
			br1 = element("br");
			t8 = space();
			t9 = text(t9_value);
			t10 = text("  ");
			t11 = text(t11_value);
			t12 = space();
			div4 = element("div");
			div1 = element("div");
			create_component(phone.$$.fragment);
			t13 = space();
			p1 = element("p");
			t14 = text(t14_value);
			t15 = space();
			div2 = element("div");
			create_component(email.$$.fragment);
			t16 = space();
			p2 = element("p");
			t17 = text(t17_value);
			t18 = space();
			div3 = element("div");
			create_component(time.$$.fragment);
			t19 = space();
			p3 = element("p");
			p3.textContent = `Heute: ${/*getToday*/ ctx[2](/*today*/ ctx[3])}`;
			t22 = space();
			create_component(servicetimescomponent.$$.fragment);
			attr(img, "alt", "");
			if (!src_url_equal(img.src, img_src_value = /*bankData*/ ctx[0].bankAdvisorImageUrl)) attr(img, "src", img_src_value);
			attr(img, "width", "80px");
			attr(img, "height", "80px");
			attr(img, "class", "svelte-1uxtse3");
			attr(p0, "class", "svelte-1uxtse3");
			attr(div0, "class", "imgContainer svelte-1uxtse3");
			attr(p1, "class", "link svelte-1uxtse3");
			attr(div1, "class", "iconContainer svelte-1uxtse3");
			attr(p2, "class", "link svelte-1uxtse3");
			attr(div2, "class", "iconContainer svelte-1uxtse3");
			attr(p3, "class", "svelte-1uxtse3");
			attr(div3, "class", "iconContainer svelte-1uxtse3");
			attr(div4, "class", "contactsContainer svelte-1uxtse3");
			attr(div5, "class", "container svelte-1uxtse3");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div0);
			append(div0, img);
			append(div0, t0);
			append(div0, p0);
			append(p0, t1);
			append(p0, t2);
			append(p0, br0);
			append(p0, t3);
			append(p0, t4);
			append(p0, t5);
			append(p0, t6);
			append(p0, t7);
			append(p0, br1);
			append(p0, t8);
			append(p0, t9);
			append(p0, t10);
			append(p0, t11);
			append(div5, t12);
			append(div5, div4);
			append(div4, div1);
			mount_component(phone, div1, null);
			append(div1, t13);
			append(div1, p1);
			append(p1, t14);
			append(div4, t15);
			append(div4, div2);
			mount_component(email, div2, null);
			append(div2, t16);
			append(div2, p2);
			append(p2, t17);
			append(div4, t18);
			append(div4, div3);
			mount_component(time, div3, null);
			append(div3, t19);
			append(div3, p3);
			append(div4, t22);
			mount_component(servicetimescomponent, div4, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div1, "click", /*telContact*/ ctx[5]),
					listen(div2, "click", /*sendEmail*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*bankData*/ 1 && !src_url_equal(img.src, img_src_value = /*bankData*/ ctx[0].bankAdvisorImageUrl)) {
				attr(img, "src", img_src_value);
			}

			if ((!current || dirty & /*bankData*/ 1) && t1_value !== (t1_value = /*bankData*/ ctx[0].bankName + "")) set_data(t1, t1_value);
			if ((!current || dirty & /*bankData*/ 1) && t4_value !== (t4_value = /*bankData*/ ctx[0].street + "")) set_data(t4, t4_value);
			if ((!current || dirty & /*bankData*/ 1) && t6_value !== (t6_value = /*bankData*/ ctx[0].houseNumber + "")) set_data(t6, t6_value);
			if ((!current || dirty & /*bankData*/ 1) && t9_value !== (t9_value = /*bankData*/ ctx[0].zipCode + "")) set_data(t9, t9_value);
			if ((!current || dirty & /*bankData*/ 1) && t11_value !== (t11_value = /*bankData*/ ctx[0].city + "")) set_data(t11, t11_value);
			if ((!current || dirty & /*bankData*/ 1) && t14_value !== (t14_value = /*bankData*/ ctx[0].phoneNumber + "")) set_data(t14, t14_value);
			if ((!current || dirty & /*bankData*/ 1) && t17_value !== (t17_value = /*bankData*/ ctx[0].email + "")) set_data(t17, t17_value);
		},
		i(local) {
			if (current) return;
			transition_in(phone.$$.fragment, local);
			transition_in(email.$$.fragment, local);
			transition_in(time.$$.fragment, local);
			transition_in(servicetimescomponent.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(phone.$$.fragment, local);
			transition_out(email.$$.fragment, local);
			transition_out(time.$$.fragment, local);
			transition_out(servicetimescomponent.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div5);
			destroy_component(phone);
			destroy_component(email);
			destroy_component(time);
			destroy_component(servicetimescomponent);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $bankContactInfoController;
	component_subscribe($$self, bankContactInfoController, $$value => $$invalidate(6, $bankContactInfoController = $$value));
	let { bankData } = $$props;
	let ServiceTimeMap = new Map();

	const getToday = index => {
		if (index === 0) {
			if (ServiceTimeMap.get(7) !== undefined) {
				const timesArr = ServiceTimeMap.get(7)?.map(sT => sT.from + "-" + sT.to);
				let timesString = timesArr?.at(0);

				if (timesArr !== undefined && timesArr.length > 1) {
					timesString = timesArr.join(" & ") + "\n";
				}

				return timesString + " Uhr";
			} else {
				return "Keine Servicezeit";
			}
		} else {
			if (ServiceTimeMap.get(index) !== undefined) {
				const timesArr = ServiceTimeMap.get(index)?.map(sT => sT.from + "-" + sT.to);
				let timesString = timesArr?.at(0);

				if (timesArr !== undefined && timesArr.length > 1) {
					timesString = timesArr.join(" & ") + "\n";
				}

				return timesString + " Uhr";
			} else {
				return "Keine Servicezeit";
			}
		}
	};

	const today = new Date().getDay();

	const sendEmail = () => {
		window.location.assign(`mailto:${$bankContactInfoController?.email}`);
	};

	const telContact = () => {
		window.location.assign(`tel:${$bankContactInfoController?.phoneNumber}`);
	};

	$$self.$$set = $$props => {
		if ('bankData' in $$props) $$invalidate(0, bankData = $$props.bankData);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*bankData*/ 1) {
			$: bankData.serviceDays.map(serviceDay => serviceDay.weekdays.map(weekday => ServiceTimeMap.set(weekday, serviceDay.serviceTimes)));
		}
	};

	return [bankData, ServiceTimeMap, getToday, today, sendEmail, telContact];
}

class PictureInfo extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { bankData: 0 });
	}
}

export default PictureInfo;