/* src/features/financeDataForm/presentation/components/HomeCloudButton.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	is_function,
	mount_component,
	safe_not_equal,
	set_data,
	text,
	transition_in,
	transition_out
} from "../../../../../_snowpack/pkg/svelte/internal.js";

import { Button } from "../../../../../_snowpack/pkg/carbon-components-svelte.js";

function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text(/*label*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*label*/ 1) set_data(t, /*label*/ ctx[0]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let button;
	let current;

	button = new Button({
			props: {
				type: /*type*/ ctx[2],
				disabled: /*disabled*/ ctx[3],
				style: "border-radius: 8px;\npadding: 50px;\npadding-top: 10px;\npadding-bottom: 10px;\nfont-size: 20px;\nfont-weight: 600;\nbackground-color: #0b3478;\nmax-width: 100%;",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	button.$on("click", function () {
		if (is_function(/*onClick*/ ctx[1])) /*onClick*/ ctx[1].apply(this, arguments);
	});

	return {
		c() {
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			const button_changes = {};
			if (dirty & /*type*/ 4) button_changes.type = /*type*/ ctx[2];
			if (dirty & /*disabled*/ 8) button_changes.disabled = /*disabled*/ ctx[3];

			if (dirty & /*$$scope, label*/ 17) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { label } = $$props;
	let { onClick } = $$props;
	let { type = "button" } = $$props;
	let { disabled = false } = $$props;

	$$self.$$set = $$props => {
		if ('label' in $$props) $$invalidate(0, label = $$props.label);
		if ('onClick' in $$props) $$invalidate(1, onClick = $$props.onClick);
		if ('type' in $$props) $$invalidate(2, type = $$props.type);
		if ('disabled' in $$props) $$invalidate(3, disabled = $$props.disabled);
	};

	return [label, onClick, type, disabled];
}

class HomeCloudButton extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			label: 0,
			onClick: 1,
			type: 2,
			disabled: 3
		});
	}
}

export default HomeCloudButton;