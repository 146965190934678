import PromiseWrapperImpl from "../../../infrastructure/api/PromiseWrapperImpl.js";
import LoanLeadServiceImpl from "../infrastructure/LoanLeadServiceImpl.js";
export class LoanLeadControllerImpl {
  constructor() {
    this.loanLeadService = new LoanLeadServiceImpl();
    this.wrapper = new PromiseWrapperImpl();
  }
  createEarlyLoanLeadWithTimeout(ids) {
    return this.wrapper.wrapPromise(this.loanLeadService.createEarlyLoanLeadWithTimeout(ids), () => {
    });
  }
  createNoLoanLeadWithTimeout(ids) {
    return this.wrapper.wrapPromise(this.loanLeadService.createNoLoanLeadWithTimeout(ids), () => {
    });
  }
  createLateLoanLead(ids) {
    return this.wrapper.wrapPromise(this.loanLeadService.createLateLoanLead(ids), () => {
    });
  }
}
const loanLeadController = new LoanLeadControllerImpl();
export default loanLeadController;
