import './ErrorLabel.svelte.css';
/* src/features/financeDataForm/presentation/ErrorLabel.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	text
} from "../../../../_snowpack/pkg/svelte/internal.js";

function create_if_block(ctx) {
	let p;
	let t;

	return {
		c() {
			p = element("p");
			t = text(/*error*/ ctx[0]);
			attr(p, "class", "errorLabel svelte-lyj8gq");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*error*/ 1) set_data(t, /*error*/ ctx[0]);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = !!/*error*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (!!/*error*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { error = "" } = $$props;

	$$self.$$set = $$props => {
		if ('error' in $$props) $$invalidate(0, error = $$props.error);
	};

	return [error];
}

class ErrorLabel extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { error: 0 });
	}
}

export default ErrorLabel;