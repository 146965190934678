import './FormComponent.svelte.css';
/* src/features/financeDataForm/presentation/FormComponent.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import formController from "../application/FormControllerImpl.js";
import loanLeadController from "../application/LoanLeadControllerImpl.js";
import userInformationController from "../application/UserInformationControllerImpl.js";
import Form from "./Form.svelte.js";

function create_else_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<h4>Es ist ein Fehler aufgetreten</h4>`;
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (30:0) {#if !isError}
function create_if_block(ctx) {
	let p0;
	let t0;
	let t1;
	let p1;
	let t3;
	let p2;
	let t5;
	let form;
	let current;
	let if_block = /*finAdvice*/ ctx[2] && create_if_block_1(ctx);

	form = new Form({
			props: {
				loanLeadPromise: /*loanLeadPromise*/ ctx[0]
			}
		});

	return {
		c() {
			p0 = element("p");
			t0 = text("Die Nachricht wurde erfolgreich an den Immobilienmakler weitergeleitet.\n    ");
			if (if_block) if_block.c();
			t1 = space();
			p1 = element("p");
			p1.innerHTML = `<strong class="svelte-iq7xe">An der Warteliste vorbei mit sofortigem Finanzierungscheck</strong>`;
			t3 = space();
			p2 = element("p");
			p2.textContent = "Mit den folgenen Daten können wir die Machbarkeit einer Finanzierung für\n    dich checken. Diese Information hilft dem Immobilienmakler deine Anfrage zu\n    priorisieren.";
			t5 = space();
			create_component(form.$$.fragment);
			attr(p0, "class", "svelte-iq7xe");
			attr(p1, "class", "svelte-iq7xe");
			attr(p2, "class", "svelte-iq7xe");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			append(p0, t0);
			if (if_block) if_block.m(p0, null);
			insert(target, t1, anchor);
			insert(target, p1, anchor);
			insert(target, t3, anchor);
			insert(target, p2, anchor);
			insert(target, t5, anchor);
			mount_component(form, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const form_changes = {};
			if (dirty & /*loanLeadPromise*/ 1) form_changes.loanLeadPromise = /*loanLeadPromise*/ ctx[0];
			form.$set(form_changes);
		},
		i(local) {
			if (current) return;
			transition_in(form.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(form.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(p0);
			if (if_block) if_block.d();
			if (detaching) detach(t1);
			if (detaching) detach(p1);
			if (detaching) detach(t3);
			if (detaching) detach(p2);
			if (detaching) detach(t5);
			destroy_component(form, detaching);
		}
	};
}

// (33:4) {#if finAdvice}
function create_if_block_1(ctx) {
	let t;

	return {
		c() {
			t = text("Unsere Finanzierungsprofis begleiten Dich gerne beim Objektkauf.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (!/*isError*/ ctx[1]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $formController;
	let $userInformationController;
	component_subscribe($$self, formController, $$value => $$invalidate(3, $formController = $$value));
	component_subscribe($$self, userInformationController, $$value => $$invalidate(4, $userInformationController = $$value));
	const finAdvice = $userInformationController?.finAdviceFilled;
	let isError = false;
	let loanLeadPromise;

	if ($userInformationController) {
		if (finAdvice) {
			loanLeadPromise = loanLeadController.createEarlyLoanLeadWithTimeout({
				fioContactId: $userInformationController.contact_id,
				fioEstateId: $userInformationController.estate_id
			});
		} else {
			loanLeadPromise = loanLeadController.createNoLoanLeadWithTimeout({
				fioContactId: $userInformationController.contact_id,
				fioEstateId: $userInformationController.estate_id
			});
		}
	}

	if (!finAdvice) {
		formController.set({
			...$formController,
			isHomeContactWanted: false
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*loanLeadPromise*/ 1) {
			$: loanLeadPromise.catch(function () {
				$$invalidate(1, isError = true);
			});
		}
	};

	return [loanLeadPromise, isError, finAdvice];
}

class FormComponent extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default FormComponent;