import { S as SvelteComponent, i as init, s as safe_not_equal, L as svg_element, m as text, a as insert, o as append, z as set_data, d as detach, b as assign, K as attr, M as set_svg_attributes, k as get_spread_update, n as noop, c as compute_rest_props, f as exclude_internal_props } from '../../common/index-05235cee.js';

/* node_modules/carbon-icons-svelte/lib/Add.svelte generated by Svelte v3.47.0 */

function create_if_block(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment(ctx) {
	let svg;
	let path;
	let if_block = /*title*/ ctx[1] && create_if_block(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path = svg_element("path");
			attr(path, "d", "M17 15L17 8 15 8 15 15 8 15 8 17 15 17 15 24 17 24 17 17 24 17 24 15z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(svg, path);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class Add extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { size: 0, title: 1 });
	}
}

export default Add;
