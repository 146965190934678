import Endpoint from "../../../common/Endpoint.js";
import ApiServiceImpl from "../../../infrastructure/api/ApiServiceImpl.js";
class SurveyServiceImpl {
  constructor(service = new ApiServiceImpl(), service1 = new ApiServiceImpl()) {
    this.service = service;
    this.service1 = service1;
  }
  doesContactHaveSurvey(fioContactId, fioEstateId) {
    const url = new Endpoint().doesContactHaveSurvey().$;
    return this.service1.post(url, {
      fioContactId: fioContactId.fioContactId,
      fioEstateId: fioEstateId.fioEstateId
    });
  }
  getSurveyHasBeenCompleted(surveyRequest) {
    const url = new Endpoint().surveyHasBeenCompleted().$;
    return this.service.post(url, surveyRequest);
  }
}
export default SurveyServiceImpl;
