import './ResultGreen.svelte.css';
/* src/features/financeDataForm/presentation/ResultGreen.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { Button } from "../../../../_snowpack/pkg/carbon-components-svelte.js";
import { navigate } from "../../../../_snowpack/pkg/svelte-routing.js";
import surveyController from "../application/SurveyControllerImpl.js";
import userInformationController from "../application/UserInformationControllerImpl.js";
import ClosingButton from "./components/ClosingButton.svelte.js";
import HomeCloudButton from "./components/HomeCloudButton.svelte.js";
import ConditionExample from "./ConditionExample.svelte.js";
import ResultDiv from "./ResultDiv.svelte.js";

function create_else_block_1(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			p.textContent = "Dein persönlicher Finanzierungsprofi meldet sich bei Dir, um Dich auf\n        Deinem Weg ins neue Zuhause zu begleiten.";
			attr(p, "class", "svelte-o2panh");
		},
		m(target, anchor) {
			insert(target, p, anchor);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (43:4) {#if !$userInformationController?.finAdviceFilled && !ContactChecked}
function create_if_block_1(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			p.textContent = "Teile deine Daten mit einem Finanzierungsprofi und\n        lass dir in einem persönlichen Beratungsgespräch eine Finanzierungsbestätigung ausstellen\n        um den Kauf zu beschleunigen.";
			set_style(p, "margin-bottom", "10px");
			attr(p, "class", "svelte-o2panh");
		},
		m(target, anchor) {
			insert(target, p, anchor);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (62:4) {:else}
function create_else_block(ctx) {
	let closingbutton;
	let current;

	closingbutton = new ClosingButton({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(closingbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(closingbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const closingbutton_changes = {};

			if (dirty & /*$$scope*/ 512) {
				closingbutton_changes.$$scope = { dirty, ctx };
			}

			closingbutton.$set(closingbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(closingbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(closingbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(closingbutton, detaching);
		}
	};
}

// (57:4) {#if !$userInformationController?.finAdviceFilled && !ContactChecked}
function create_if_block(ctx) {
	let homecloudbutton;
	let current;

	homecloudbutton = new HomeCloudButton({
			props: {
				label: "Kontaktanfrage schicken",
				onClick: /*func*/ ctx[6]
			}
		});

	return {
		c() {
			create_component(homecloudbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(homecloudbutton, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(homecloudbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(homecloudbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(homecloudbutton, detaching);
		}
	};
}

// (63:6) <ClosingButton>
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Zurück zum Exposé");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let div5;
	let h4;
	let t1;
	let div0;
	let p0;
	let t2;

	let t3_value = Intl.NumberFormat("de-DE", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	}).format(/*purchasePrice*/ ctx[1]) + "";

	let t3;
	let t4;
	let t5;
	let resultdiv;
	let t6;
	let div1;
	let conditionexample;
	let t7;
	let div2;
	let t8;
	let div3;
	let current_block_type_index;
	let if_block1;
	let t9;
	let div4;
	let p1;
	let t10;
	let a;
	let t12;
	let current;
	let mounted;
	let dispose;
	resultdiv = new ResultDiv({ props: { leadScore: "green" } });

	conditionexample = new ConditionExample({
			props: { estateAttributes: /*conditions*/ ctx[4] }
		});

	function select_block_type(ctx, dirty) {
		if (!/*$userInformationController*/ ctx[2]?.finAdviceFilled && !/*ContactChecked*/ ctx[0]) return create_if_block_1;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (!/*$userInformationController*/ ctx[2]?.finAdviceFilled && !/*ContactChecked*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type_1(ctx, -1);
	if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div5 = element("div");
			h4 = element("h4");
			h4.textContent = "Dein Ergebnis";
			t1 = space();
			div0 = element("div");
			p0 = element("p");
			t2 = text("Der sofortige Finanzierungscheck hat mit deinen Angaben und einem\n      Kaufpreis von ");
			t3 = text(t3_value);
			t4 = text(" ergeben:");
			t5 = space();
			create_component(resultdiv.$$.fragment);
			t6 = space();
			div1 = element("div");
			create_component(conditionexample.$$.fragment);
			t7 = space();
			div2 = element("div");
			if_block0.c();
			t8 = space();
			div3 = element("div");
			if_block1.c();
			t9 = space();
			div4 = element("div");
			p1 = element("p");
			t10 = text("Die Datenschutzhinweise unseres Finanzierungspartners findest du ");
			a = element("a");
			a.textContent = "hier";
			t12 = text(".");
			attr(h4, "class", "svelte-o2panh");
			attr(p0, "class", "svelte-o2panh");
			attr(div0, "class", "textBox svelte-o2panh");
			attr(div1, "class", "conditionsContainer svelte-o2panh");
			attr(div2, "class", "textBox svelte-o2panh");
			attr(div3, "class", "buttonContainer svelte-o2panh");
			set_style(a, "color", "#8C8C8C");
			attr(a, "class", "svelte-o2panh");
			set_style(p1, "color", "#8C8C8C");
			set_style(p1, "margin-top", "20px");
			attr(p1, "class", "svelte-o2panh");
			attr(div4, "class", "textBox svelte-o2panh");
			attr(div5, "class", "container svelte-o2panh");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, h4);
			append(div5, t1);
			append(div5, div0);
			append(div0, p0);
			append(p0, t2);
			append(p0, t3);
			append(p0, t4);
			append(div5, t5);
			mount_component(resultdiv, div5, null);
			append(div5, t6);
			append(div5, div1);
			mount_component(conditionexample, div1, null);
			append(div5, t7);
			append(div5, div2);
			if_block0.m(div2, null);
			append(div5, t8);
			append(div5, div3);
			if_blocks[current_block_type_index].m(div3, null);
			append(div5, t9);
			append(div5, div4);
			append(div4, p1);
			append(p1, t10);
			append(p1, a);
			append(p1, t12);
			current = true;

			if (!mounted) {
				dispose = listen(a, "click", /*click_handler*/ ctx[7]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*purchasePrice*/ 2) && t3_value !== (t3_value = Intl.NumberFormat("de-DE", {
				style: "currency",
				currency: "EUR",
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			}).format(/*purchasePrice*/ ctx[1]) + "")) set_data(t3, t3_value);

			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div2, null);
				}
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block1 = if_blocks[current_block_type_index];

				if (!if_block1) {
					if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block1.c();
				} else {
					if_block1.p(ctx, dirty);
				}

				transition_in(if_block1, 1);
				if_block1.m(div3, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(resultdiv.$$.fragment, local);
			transition_in(conditionexample.$$.fragment, local);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(resultdiv.$$.fragment, local);
			transition_out(conditionexample.$$.fragment, local);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div5);
			destroy_component(resultdiv);
			destroy_component(conditionexample);
			if_block0.d();
			if_blocks[current_block_type_index].d();
			mounted = false;
			dispose();
		}
	};
}

function openInNewTap(url) {
	window.open(url, "_blank")?.focus();
}

function instance($$self, $$props, $$invalidate) {
	let currentPath;
	let $surveyController;
	let $userInformationController;
	component_subscribe($$self, surveyController, $$value => $$invalidate(8, $surveyController = $$value));
	component_subscribe($$self, userInformationController, $$value => $$invalidate(2, $userInformationController = $$value));
	let { ContactChecked } = $$props;
	let purchasePrice;

	const handleClick = path => {
		$$invalidate(5, currentPath = path);
	};

	let conditions = $surveyController?.exampleLoanConditions;

	if ($surveyController !== undefined) {
		purchasePrice = $surveyController.estateAttributes.purchasePrice;
	}

	const func = () => handleClick("/contact");
	const click_handler = () => openInNewTap("https://meinedatenschutzhinweise.de/NAJ03.pdf");

	$$self.$$set = $$props => {
		if ('ContactChecked' in $$props) $$invalidate(0, ContactChecked = $$props.ContactChecked);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*currentPath*/ 32) {
			$: navigate(currentPath);
		}
	};

	$: $$invalidate(5, currentPath = "/result");

	return [
		ContactChecked,
		purchasePrice,
		$userInformationController,
		handleClick,
		conditions,
		currentPath,
		func,
		click_handler
	];
}

class ResultGreen extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { ContactChecked: 0 });
	}
}

export default ResultGreen;