import './ConditionExample.svelte.css';
/* src/features/financeDataForm/presentation/ConditionExample.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { Column, Grid, Row } from "../../../../_snowpack/pkg/carbon-components-svelte.js";

function create_default_slot_5(ctx) {
	let strong0;
	let t1;
	let strong1;

	return {
		c() {
			strong0 = element("strong");
			strong0.textContent = "Monatliche Rate";
			t1 = space();
			strong1 = element("strong");
			strong1.textContent = `${/*monthlyPayment*/ ctx[3]} €`;
			attr(strong0, "class", "svelte-bukuwz");
			attr(strong1, "class", "svelte-bukuwz");
		},
		m(target, anchor) {
			insert(target, strong0, anchor);
			insert(target, t1, anchor);
			insert(target, strong1, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(strong0);
			if (detaching) detach(t1);
			if (detaching) detach(strong1);
		}
	};
}

// (20:8) <Row>
function create_default_slot_4(ctx) {
	let p0;
	let t1;
	let p1;

	return {
		c() {
			p0 = element("p");
			p0.textContent = "Effektiver Jahreszins";
			t1 = space();
			p1 = element("p");
			p1.textContent = `${/*effectiveInterestRate*/ ctx[2]}%`;
			attr(p0, "class", "svelte-bukuwz");
			attr(p1, "class", "svelte-bukuwz");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			insert(target, t1, anchor);
			insert(target, p1, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t1);
			if (detaching) detach(p1);
		}
	};
}

// (24:8) <Row>
function create_default_slot_3(ctx) {
	let p0;
	let t1;
	let p1;

	return {
		c() {
			p0 = element("p");
			p0.textContent = "Gebundener Sollzins";
			t1 = space();
			p1 = element("p");
			p1.textContent = `${/*fixedInterestRate*/ ctx[1]}%`;
			attr(p0, "class", "svelte-bukuwz");
			attr(p1, "class", "svelte-bukuwz");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			insert(target, t1, anchor);
			insert(target, p1, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t1);
			if (detaching) detach(p1);
		}
	};
}

// (28:8) <Row>
function create_default_slot_2(ctx) {
	let p0;
	let t1;
	let p1;
	let t2_value = /*estateAttributes*/ ctx[0]?.fixedInterestPeriodYears + "";
	let t2;
	let t3;

	return {
		c() {
			p0 = element("p");
			p0.textContent = "Sollzinsbindung";
			t1 = space();
			p1 = element("p");
			t2 = text(t2_value);
			t3 = text(" Jahre");
			attr(p0, "class", "svelte-bukuwz");
			attr(p1, "class", "svelte-bukuwz");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			insert(target, t1, anchor);
			insert(target, p1, anchor);
			append(p1, t2);
			append(p1, t3);
		},
		p(ctx, dirty) {
			if (dirty & /*estateAttributes*/ 1 && t2_value !== (t2_value = /*estateAttributes*/ ctx[0]?.fixedInterestPeriodYears + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t1);
			if (detaching) detach(p1);
		}
	};
}

// (15:6) <Column>
function create_default_slot_1(ctx) {
	let row0;
	let t0;
	let row1;
	let t1;
	let row2;
	let t2;
	let row3;
	let current;

	row0 = new Row({
			props: {
				style: "margin-bottom:13px;",
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	row1 = new Row({
			props: {
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			}
		});

	row2 = new Row({
			props: {
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	row3 = new Row({
			props: {
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(row0.$$.fragment);
			t0 = space();
			create_component(row1.$$.fragment);
			t1 = space();
			create_component(row2.$$.fragment);
			t2 = space();
			create_component(row3.$$.fragment);
		},
		m(target, anchor) {
			mount_component(row0, target, anchor);
			insert(target, t0, anchor);
			mount_component(row1, target, anchor);
			insert(target, t1, anchor);
			mount_component(row2, target, anchor);
			insert(target, t2, anchor);
			mount_component(row3, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const row0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row0_changes.$$scope = { dirty, ctx };
			}

			row0.$set(row0_changes);
			const row1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row1_changes.$$scope = { dirty, ctx };
			}

			row1.$set(row1_changes);
			const row2_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row2_changes.$$scope = { dirty, ctx };
			}

			row2.$set(row2_changes);
			const row3_changes = {};

			if (dirty & /*$$scope, estateAttributes*/ 17) {
				row3_changes.$$scope = { dirty, ctx };
			}

			row3.$set(row3_changes);
		},
		i(local) {
			if (current) return;
			transition_in(row0.$$.fragment, local);
			transition_in(row1.$$.fragment, local);
			transition_in(row2.$$.fragment, local);
			transition_in(row3.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(row0.$$.fragment, local);
			transition_out(row1.$$.fragment, local);
			transition_out(row2.$$.fragment, local);
			transition_out(row3.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(row0, detaching);
			if (detaching) detach(t0);
			destroy_component(row1, detaching);
			if (detaching) detach(t1);
			destroy_component(row2, detaching);
			if (detaching) detach(t2);
			destroy_component(row3, detaching);
		}
	};
}

// (14:4) <Grid padding>
function create_default_slot(ctx) {
	let column;
	let current;

	column = new Column({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(column.$$.fragment);
		},
		m(target, anchor) {
			mount_component(column, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const column_changes = {};

			if (dirty & /*$$scope, estateAttributes*/ 17) {
				column_changes.$$scope = { dirty, ctx };
			}

			column.$set(column_changes);
		},
		i(local) {
			if (current) return;
			transition_in(column.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(column.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(column, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let t1;
	let div1;
	let grid;
	let current;

	grid = new Grid({
			props: {
				padding: true,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			div0.textContent = "Konditionsbeispiel:";
			t1 = space();
			div1 = element("div");
			create_component(grid.$$.fragment);
			attr(div0, "class", "textContainer svelte-bukuwz");
			attr(div1, "class", "container svelte-bukuwz");
			attr(div2, "class", "box svelte-bukuwz");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t1);
			append(div2, div1);
			mount_component(grid, div1, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const grid_changes = {};

			if (dirty & /*$$scope, estateAttributes*/ 17) {
				grid_changes.$$scope = { dirty, ctx };
			}

			grid.$set(grid_changes);
		},
		i(local) {
			if (current) return;
			transition_in(grid.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(grid.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_component(grid);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { estateAttributes } = $$props;
	const fixedInterestRate = Intl.NumberFormat("de-DE").format(estateAttributes?.fixedInterestRate ?? 0);
	const effectiveInterestRate = Intl.NumberFormat("de-DE").format(estateAttributes?.effectiveInterestRate ?? 0);

	const monthlyPayment = Intl.NumberFormat("de-DE", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	}).format(estateAttributes?.monthlyPayment ?? 0);

	$$self.$$set = $$props => {
		if ('estateAttributes' in $$props) $$invalidate(0, estateAttributes = $$props.estateAttributes);
	};

	return [estateAttributes, fixedInterestRate, effectiveInterestRate, monthlyPayment];
}

class ConditionExample extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { estateAttributes: 0 });
	}
}

export default ConditionExample;