import Endpoint from "../../../common/Endpoint.js";
import ApiServiceImpl from "../../../infrastructure/api/ApiServiceImpl.js";
class LoanLeadServiceImpl {
  constructor(service = new ApiServiceImpl()) {
    this.service = service;
  }
  createEarlyLoanLeadWithTimeout(ids) {
    const url = new Endpoint().createEarlyLoanLeadWithTimeout().$;
    return this.service.postWithoutParse(url, ids);
  }
  createNoLoanLeadWithTimeout(ids) {
    const url = new Endpoint().createNoLoanLeadWithTimeout().$;
    return this.service.postWithoutParse(url, ids);
  }
  createLateLoanLead(ids) {
    const url = new Endpoint().createLateLoanLead().$;
    return this.service.postWithoutParse(url, ids);
  }
}
export default LoanLeadServiceImpl;
