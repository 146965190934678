import * as yup from "../../../../_snowpack/pkg/yup.js";
import {addYears} from "../../../../_snowpack/pkg/date-fns.js";
import {
  dateTransform,
  euroFieldTransform
} from "../../../common/ValidationHelpers.js";
import ValidationMessages from "../../../common/ValidationMessages.js";
export const formValidationSchema = yup.object({
  equityTotal: yup.number().transform(euroFieldTransform).test("test", "Kein gültiges Eigenkapital", (value) => {
    if (value !== void 0) {
      return value >= 0 && value < 1e7;
    }
    return false;
  }).required(ValidationMessages.REQUIRED),
  remainingDebtTotal: yup.number().transform(euroFieldTransform).test("test", "Keine gültige Restschuld", (value) => {
    if (value !== void 0) {
      return value >= 0 && value < 1e7;
    }
    return false;
  }).required(ValidationMessages.REQUIRED),
  monthlyNetIncome1: yup.number().transform(euroFieldTransform).test("test", "Keine gültiges Monatseinkommen", (value) => {
    if (value !== void 0) {
      return value >= 0 && value < 1e5;
    }
    return false;
  }).required(ValidationMessages.REQUIRED),
  employedSinceDate1: yup.string().transform(dateTransform).test("test", "Datum darf nicht mehr als 70 Jahre zurückliegen", (value) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) > addYears(new Date(), -70);
      } else {
        return true;
      }
    }
    return false;
  }).test("test", "Datum darf nicht in der Zukunft liegen", (value) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) < new Date();
      } else {
        return true;
      }
    }
    return false;
  }).test("test", "Datum darf nicht vor Geburtstag liegen", (value, ctx) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) > new Date(ctx.parent.birthDate1);
      } else {
        return true;
      }
    }
    return false;
  }).required(ValidationMessages.NOT_A_VALID_DATE),
  birthDate1: yup.string().transform(dateTransform).test("test", "Datum darf nicht mehr als 90 Jahre zurückliegen", (value) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) > addYears(new Date(), -90);
      } else {
        return true;
      }
    }
    return true;
  }).test("test", "Person muss mindestens 16 Jahre alt sein", (value) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) <= addYears(new Date(), -16);
      } else {
        return true;
      }
    }
    return true;
  }).notRequired(),
  monthlyNetIncome2: yup.number().transform(euroFieldTransform).test("test", "Keine gültiges Monatseinkommen", (value) => {
    if (value !== void 0) {
      return value >= 0 && value < 1e5;
    }
    return true;
  }).notRequired(),
  employedSinceDate2: yup.string().transform(dateTransform).test("test", "Datum darf nicht mehr als 70 Jahre zurückliegen", (value) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) > addYears(new Date(), -70);
      } else {
        return true;
      }
    }
    return true;
  }).test("test", "Datum darf nicht in der Zukunft liegen", (value) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) < new Date();
      } else {
        return true;
      }
    }
    return true;
  }).test("test", "Datum darf nicht vor Geburtstag liegen", (value, ctx) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) > new Date(ctx.parent.birthDate2);
      } else {
        return true;
      }
    }
    return true;
  }).notRequired(),
  birthDate2: yup.string().transform(dateTransform).test("test", "Datum darf nicht mehr als 90 Jahre zurückliegen", (value) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) > addYears(new Date(), -90);
      } else {
        return true;
      }
    }
    return true;
  }).test("test", "Person muss mindestens 16 Jahre alt sein", (value) => {
    if (value !== void 0) {
      if (value !== "") {
        return new Date(value) <= addYears(new Date(), -16);
      } else {
        return true;
      }
    }
    return true;
  }).notRequired(),
  isDataForwardingToRealEstateAgentOk: yup.boolean().required().isTrue(),
  isHomeContactWanted: yup.boolean().required()
});
