import {get} from "../../../../_snowpack/pkg/svelte/store.js";
import ControllerImpl from "../../controller/ControllerImpl.js";
class NotificationControllerImpl extends ControllerImpl {
  constructor() {
    super([]);
    this.count = 0;
  }
  remove(notification) {
    const indexer = (entity, entities) => entities.findIndex((e) => e.id === entity.id);
    this._deleteState(notification, indexer);
  }
  add(notification) {
    this._setState([
      ...get(this.state),
      {...notification, id: this.count}
    ]);
    this.count++;
  }
}
const notificationController = new NotificationControllerImpl();
export default notificationController;
