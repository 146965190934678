import ControllerImpl from "../../../application/controller/ControllerImpl.js";
export class UserInformationControllerImpl extends ControllerImpl {
  constructor() {
    super(void 0);
  }
  set(userInformation) {
    this._setState(userInformation);
  }
}
const userInformationController = new UserInformationControllerImpl();
export default userInformationController;
