class PromiseParser {
  constructor(promise) {
    this.promise = promise;
    this._response = void 0;
  }
  async parse() {
    const response = await this._getResponse();
    const contentType = response.headers.get("content-type");
    let parsedValue;
    switch (contentType) {
      case "application/json":
        parsedValue = await this._parseJson();
        break;
      case "application/pdf":
        parsedValue = await this._parseBlob();
        break;
      case "image/png":
        parsedValue = await this._parseBlob();
        break;
      case "image/jpeg":
        parsedValue = await this._parseBlob();
        break;
      default:
        parsedValue = await this._parseJson();
        break;
    }
    return parsedValue;
  }
  async _getResponse() {
    if (!this._response) {
      this._response = await this.promise;
    }
    if (!this._response.ok) {
      throw Error("Response is not ok");
    }
    if (!this._response.body) {
      throw Error("Missing body in response");
    }
    if (this._response.status !== 200) {
      const error = new Error(this._response.statusText);
      error.name = `Status: ${this._response.status}`;
      throw error;
    }
    return this._response;
  }
  async _parseJson() {
    try {
      const response = await this._getResponse();
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }
  async _parseBlob() {
    try {
      const response = await this._getResponse();
      const blob = await response.blob();
      return blob;
    } catch (e) {
      throw e;
    }
  }
}
export default PromiseParser;
