import { S as SvelteComponent, i as init, s as safe_not_equal, e as empty, a as insert, n as noop, d as detach, c as compute_rest_props, b as assign, f as exclude_internal_props, g as bubble, h as element, j as set_attributes, t as toggle_class, l as listen, k as get_spread_update, r as run_all, m as text, o as append, p as group_outros, q as transition_out, u as check_outros, v as transition_in, w as getContext, x as compute_slots, y as binding_callbacks, z as set_data, A as create_slot, B as space, C as create_component, D as mount_component, E as update_slot_base, F as get_all_dirty_from_scope, G as get_slot_changes, H as destroy_component, I as get_spread_object, J as createEventDispatcher, K as attr, L as svg_element, M as set_svg_attributes, N as onMount, O as afterUpdate, P as component_subscribe, Q as setContext, R as set_input_value, T as bind, U as add_flush_callback, V as update_keyed_each, W as outro_and_destroy_block, X as stop_propagation } from './common/index-05235cee.js';
import { w as writable } from './common/index-7490ca7a.js';

/* node_modules/carbon-components-svelte/src/Button/ButtonSkeleton.svelte generated by Svelte v3.47.0 */

function create_else_block(ctx) {
	let div;
	let mounted;
	let dispose;
	let div_levels = [/*$$restProps*/ ctx[2]];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			set_attributes(div, div_data);
			toggle_class(div, "bx--skeleton", true);
			toggle_class(div, "bx--btn", true);
			toggle_class(div, "bx--btn--field", /*size*/ ctx[1] === 'field');
			toggle_class(div, "bx--btn--sm", /*size*/ ctx[1] === 'small');
			toggle_class(div, "bx--btn--lg", /*size*/ ctx[1] === 'lg');
			toggle_class(div, "bx--btn--xl", /*size*/ ctx[1] === 'xl');
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = [
					listen(div, "click", /*click_handler_1*/ ctx[7]),
					listen(div, "mouseover", /*mouseover_handler_1*/ ctx[8]),
					listen(div, "mouseenter", /*mouseenter_handler_1*/ ctx[9]),
					listen(div, "mouseleave", /*mouseleave_handler_1*/ ctx[10])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			set_attributes(div, div_data = get_spread_update(div_levels, [dirty & /*$$restProps*/ 4 && /*$$restProps*/ ctx[2]]));
			toggle_class(div, "bx--skeleton", true);
			toggle_class(div, "bx--btn", true);
			toggle_class(div, "bx--btn--field", /*size*/ ctx[1] === 'field');
			toggle_class(div, "bx--btn--sm", /*size*/ ctx[1] === 'small');
			toggle_class(div, "bx--btn--lg", /*size*/ ctx[1] === 'lg');
			toggle_class(div, "bx--btn--xl", /*size*/ ctx[1] === 'xl');
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (16:0) {#if href}
function create_if_block(ctx) {
	let a;
	let t_value = "" + "";
	let t;
	let a_rel_value;
	let mounted;
	let dispose;

	let a_levels = [
		{ href: /*href*/ ctx[0] },
		{
			rel: a_rel_value = /*$$restProps*/ ctx[2].target === '_blank'
			? 'noopener noreferrer'
			: undefined
		},
		{ role: "button" },
		/*$$restProps*/ ctx[2]
	];

	let a_data = {};

	for (let i = 0; i < a_levels.length; i += 1) {
		a_data = assign(a_data, a_levels[i]);
	}

	return {
		c() {
			a = element("a");
			t = text(t_value);
			set_attributes(a, a_data);
			toggle_class(a, "bx--skeleton", true);
			toggle_class(a, "bx--btn", true);
			toggle_class(a, "bx--btn--field", /*size*/ ctx[1] === 'field');
			toggle_class(a, "bx--btn--sm", /*size*/ ctx[1] === 'small');
			toggle_class(a, "bx--btn--lg", /*size*/ ctx[1] === 'lg');
			toggle_class(a, "bx--btn--xl", /*size*/ ctx[1] === 'xl');
		},
		m(target, anchor) {
			insert(target, a, anchor);
			append(a, t);

			if (!mounted) {
				dispose = [
					listen(a, "click", /*click_handler*/ ctx[3]),
					listen(a, "mouseover", /*mouseover_handler*/ ctx[4]),
					listen(a, "mouseenter", /*mouseenter_handler*/ ctx[5]),
					listen(a, "mouseleave", /*mouseleave_handler*/ ctx[6])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			set_attributes(a, a_data = get_spread_update(a_levels, [
				dirty & /*href*/ 1 && { href: /*href*/ ctx[0] },
				dirty & /*$$restProps*/ 4 && a_rel_value !== (a_rel_value = /*$$restProps*/ ctx[2].target === '_blank'
				? 'noopener noreferrer'
				: undefined) && { rel: a_rel_value },
				{ role: "button" },
				dirty & /*$$restProps*/ 4 && /*$$restProps*/ ctx[2]
			]));

			toggle_class(a, "bx--skeleton", true);
			toggle_class(a, "bx--btn", true);
			toggle_class(a, "bx--btn--field", /*size*/ ctx[1] === 'field');
			toggle_class(a, "bx--btn--sm", /*size*/ ctx[1] === 'small');
			toggle_class(a, "bx--btn--lg", /*size*/ ctx[1] === 'lg');
			toggle_class(a, "bx--btn--xl", /*size*/ ctx[1] === 'xl');
		},
		d(detaching) {
			if (detaching) detach(a);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*href*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const omit_props_names = ["href","size"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { href = undefined } = $$props;
	let { size = "default" } = $$props;

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	function click_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(2, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('href' in $$new_props) $$invalidate(0, href = $$new_props.href);
		if ('size' in $$new_props) $$invalidate(1, size = $$new_props.size);
	};

	return [
		href,
		size,
		$$restProps,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler,
		click_handler_1,
		mouseover_handler_1,
		mouseenter_handler_1,
		mouseleave_handler_1
	];
}

class ButtonSkeleton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { href: 0, size: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/Button/Button.svelte generated by Svelte v3.47.0 */
const get_default_slot_changes = dirty => ({ props: dirty[0] & /*buttonProps*/ 512 });
const get_default_slot_context = ctx => ({ props: /*buttonProps*/ ctx[9] });

// (163:0) {:else}
function create_else_block$1(ctx) {
	let button;
	let t;
	let switch_instance;
	let current;
	let mounted;
	let dispose;
	let if_block = /*hasIconOnly*/ ctx[8] && create_if_block_4(ctx);
	const default_slot_template = /*#slots*/ ctx[19].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[18], null);
	var switch_value = /*icon*/ ctx[2];

	function switch_props(ctx) {
		return {
			props: {
				"aria-hidden": "true",
				class: "bx--btn__icon",
				"aria-label": /*iconDescription*/ ctx[3]
			}
		};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	let button_levels = [/*buttonProps*/ ctx[9]];
	let button_data = {};

	for (let i = 0; i < button_levels.length; i += 1) {
		button_data = assign(button_data, button_levels[i]);
	}

	return {
		c() {
			button = element("button");
			if (if_block) if_block.c();
			t = space();
			if (default_slot) default_slot.c();
			if (switch_instance) create_component(switch_instance.$$.fragment);
			set_attributes(button, button_data);
		},
		m(target, anchor) {
			insert(target, button, anchor);
			if (if_block) if_block.m(button, null);
			append(button, t);

			if (default_slot) {
				default_slot.m(button, null);
			}

			if (switch_instance) {
				mount_component(switch_instance, button, null);
			}

			if (button.autofocus) button.focus();
			/*button_binding*/ ctx[33](button);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button, "click", /*click_handler_2*/ ctx[24]),
					listen(button, "mouseover", /*mouseover_handler_2*/ ctx[25]),
					listen(button, "mouseenter", /*mouseenter_handler_2*/ ctx[26]),
					listen(button, "mouseleave", /*mouseleave_handler_2*/ ctx[27])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (/*hasIconOnly*/ ctx[8]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_4(ctx);
					if_block.c();
					if_block.m(button, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty[0] & /*$$scope*/ 262144)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[18],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[18])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[18], dirty, null),
						null
					);
				}
			}

			const switch_instance_changes = {};
			if (dirty[0] & /*iconDescription*/ 8) switch_instance_changes["aria-label"] = /*iconDescription*/ ctx[3];

			if (switch_value !== (switch_value = /*icon*/ ctx[2])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, button, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}

			set_attributes(button, button_data = get_spread_update(button_levels, [dirty[0] & /*buttonProps*/ 512 && /*buttonProps*/ ctx[9]]));
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(button);
			if (if_block) if_block.d();
			if (default_slot) default_slot.d(detaching);
			if (switch_instance) destroy_component(switch_instance);
			/*button_binding*/ ctx[33](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (143:28) 
function create_if_block_2(ctx) {
	let a;
	let t;
	let switch_instance;
	let current;
	let mounted;
	let dispose;
	let if_block = /*hasIconOnly*/ ctx[8] && create_if_block_3(ctx);
	const default_slot_template = /*#slots*/ ctx[19].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[18], null);
	var switch_value = /*icon*/ ctx[2];

	function switch_props(ctx) {
		return {
			props: {
				"aria-hidden": "true",
				class: "bx--btn__icon",
				"aria-label": /*iconDescription*/ ctx[3]
			}
		};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	let a_levels = [/*buttonProps*/ ctx[9]];
	let a_data = {};

	for (let i = 0; i < a_levels.length; i += 1) {
		a_data = assign(a_data, a_levels[i]);
	}

	return {
		c() {
			a = element("a");
			if (if_block) if_block.c();
			t = space();
			if (default_slot) default_slot.c();
			if (switch_instance) create_component(switch_instance.$$.fragment);
			set_attributes(a, a_data);
		},
		m(target, anchor) {
			insert(target, a, anchor);
			if (if_block) if_block.m(a, null);
			append(a, t);

			if (default_slot) {
				default_slot.m(a, null);
			}

			if (switch_instance) {
				mount_component(switch_instance, a, null);
			}

			/*a_binding*/ ctx[32](a);
			current = true;

			if (!mounted) {
				dispose = [
					listen(a, "click", /*click_handler_1*/ ctx[20]),
					listen(a, "mouseover", /*mouseover_handler_1*/ ctx[21]),
					listen(a, "mouseenter", /*mouseenter_handler_1*/ ctx[22]),
					listen(a, "mouseleave", /*mouseleave_handler_1*/ ctx[23])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (/*hasIconOnly*/ ctx[8]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					if_block.m(a, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty[0] & /*$$scope*/ 262144)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[18],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[18])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[18], dirty, null),
						null
					);
				}
			}

			const switch_instance_changes = {};
			if (dirty[0] & /*iconDescription*/ 8) switch_instance_changes["aria-label"] = /*iconDescription*/ ctx[3];

			if (switch_value !== (switch_value = /*icon*/ ctx[2])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, a, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}

			set_attributes(a, a_data = get_spread_update(a_levels, [dirty[0] & /*buttonProps*/ 512 && /*buttonProps*/ ctx[9]]));
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(a);
			if (if_block) if_block.d();
			if (default_slot) default_slot.d(detaching);
			if (switch_instance) destroy_component(switch_instance);
			/*a_binding*/ ctx[32](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (141:13) 
function create_if_block_1(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[19].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[18], get_default_slot_context);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty[0] & /*$$scope, buttonProps*/ 262656)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[18],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[18])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[18], dirty, get_default_slot_changes),
						get_default_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (130:0) {#if skeleton}
function create_if_block$1(ctx) {
	let buttonskeleton;
	let current;

	const buttonskeleton_spread_levels = [
		{ href: /*href*/ ctx[7] },
		{ size: /*size*/ ctx[1] },
		/*$$restProps*/ ctx[10],
		{
			style: /*hasIconOnly*/ ctx[8] && 'width: 3rem;'
		}
	];

	let buttonskeleton_props = {};

	for (let i = 0; i < buttonskeleton_spread_levels.length; i += 1) {
		buttonskeleton_props = assign(buttonskeleton_props, buttonskeleton_spread_levels[i]);
	}

	buttonskeleton = new ButtonSkeleton({ props: buttonskeleton_props });
	buttonskeleton.$on("click", /*click_handler*/ ctx[28]);
	buttonskeleton.$on("mouseover", /*mouseover_handler*/ ctx[29]);
	buttonskeleton.$on("mouseenter", /*mouseenter_handler*/ ctx[30]);
	buttonskeleton.$on("mouseleave", /*mouseleave_handler*/ ctx[31]);

	return {
		c() {
			create_component(buttonskeleton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(buttonskeleton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const buttonskeleton_changes = (dirty[0] & /*href, size, $$restProps, hasIconOnly*/ 1410)
			? get_spread_update(buttonskeleton_spread_levels, [
					dirty[0] & /*href*/ 128 && { href: /*href*/ ctx[7] },
					dirty[0] & /*size*/ 2 && { size: /*size*/ ctx[1] },
					dirty[0] & /*$$restProps*/ 1024 && get_spread_object(/*$$restProps*/ ctx[10]),
					dirty[0] & /*hasIconOnly*/ 256 && {
						style: /*hasIconOnly*/ ctx[8] && 'width: 3rem;'
					}
				])
			: {};

			buttonskeleton.$set(buttonskeleton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(buttonskeleton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(buttonskeleton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(buttonskeleton, detaching);
		}
	};
}

// (172:4) {#if hasIconOnly}
function create_if_block_4(ctx) {
	let span;
	let t;

	return {
		c() {
			span = element("span");
			t = text(/*iconDescription*/ ctx[3]);
			toggle_class(span, "bx--assistive-text", true);
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*iconDescription*/ 8) set_data(t, /*iconDescription*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (153:4) {#if hasIconOnly}
function create_if_block_3(ctx) {
	let span;
	let t;

	return {
		c() {
			span = element("span");
			t = text(/*iconDescription*/ ctx[3]);
			toggle_class(span, "bx--assistive-text", true);
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*iconDescription*/ 8) set_data(t, /*iconDescription*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function create_fragment$1(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block$1, create_if_block_1, create_if_block_2, create_else_block$1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*skeleton*/ ctx[5]) return 0;
		if (/*as*/ ctx[4]) return 1;
		if (/*href*/ ctx[7] && !/*disabled*/ ctx[6]) return 2;
		return 3;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let hasIconOnly;
	let buttonProps;

	const omit_props_names = [
		"kind","size","expressive","isSelected","icon","iconDescription","tooltipAlignment","tooltipPosition","as","skeleton","disabled","href","tabindex","type","ref"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	const $$slots = compute_slots(slots);
	let { kind = "primary" } = $$props;
	let { size = "default" } = $$props;
	let { expressive = false } = $$props;
	let { isSelected = false } = $$props;
	let { icon = undefined } = $$props;
	let { iconDescription = undefined } = $$props;
	let { tooltipAlignment = "center" } = $$props;
	let { tooltipPosition = "bottom" } = $$props;
	let { as = false } = $$props;
	let { skeleton = false } = $$props;
	let { disabled = false } = $$props;
	let { href = undefined } = $$props;
	let { tabindex = "0" } = $$props;
	let { type = "button" } = $$props;
	let { ref = null } = $$props;
	const ctx = getContext("ComposedModal");

	function click_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function click_handler_2(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler_2(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler_2(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler_2(event) {
		bubble.call(this, $$self, event);
	}

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	function a_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			ref = $$value;
			$$invalidate(0, ref);
		});
	}

	function button_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			ref = $$value;
			$$invalidate(0, ref);
		});
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(10, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('kind' in $$new_props) $$invalidate(11, kind = $$new_props.kind);
		if ('size' in $$new_props) $$invalidate(1, size = $$new_props.size);
		if ('expressive' in $$new_props) $$invalidate(12, expressive = $$new_props.expressive);
		if ('isSelected' in $$new_props) $$invalidate(13, isSelected = $$new_props.isSelected);
		if ('icon' in $$new_props) $$invalidate(2, icon = $$new_props.icon);
		if ('iconDescription' in $$new_props) $$invalidate(3, iconDescription = $$new_props.iconDescription);
		if ('tooltipAlignment' in $$new_props) $$invalidate(14, tooltipAlignment = $$new_props.tooltipAlignment);
		if ('tooltipPosition' in $$new_props) $$invalidate(15, tooltipPosition = $$new_props.tooltipPosition);
		if ('as' in $$new_props) $$invalidate(4, as = $$new_props.as);
		if ('skeleton' in $$new_props) $$invalidate(5, skeleton = $$new_props.skeleton);
		if ('disabled' in $$new_props) $$invalidate(6, disabled = $$new_props.disabled);
		if ('href' in $$new_props) $$invalidate(7, href = $$new_props.href);
		if ('tabindex' in $$new_props) $$invalidate(16, tabindex = $$new_props.tabindex);
		if ('type' in $$new_props) $$invalidate(17, type = $$new_props.type);
		if ('ref' in $$new_props) $$invalidate(0, ref = $$new_props.ref);
		if ('$$scope' in $$new_props) $$invalidate(18, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*ref*/ 1) {
			 if (ctx && ref) {
				ctx.declareRef(ref);
			}
		}

		if ($$self.$$.dirty[0] & /*icon*/ 4) {
			 $$invalidate(8, hasIconOnly = icon && !$$slots.default);
		}

		 $$invalidate(9, buttonProps = {
			type: href && !disabled ? undefined : type,
			tabindex,
			disabled: disabled === true ? true : undefined,
			href,
			"aria-pressed": hasIconOnly && kind === "ghost" && !href
			? isSelected
			: undefined,
			...$$restProps,
			class: [
				"bx--btn",
				expressive && "bx--btn--expressive",
				(size === "small" && !expressive || size === "sm" && !expressive || size === "small" && !expressive) && "bx--btn--sm",
				size === "field" && !expressive || size === "md" && !expressive && "bx--btn--md",
				size === "field" && "bx--btn--field",
				size === "small" && "bx--btn--sm",
				size === "lg" && "bx--btn--lg",
				size === "xl" && "bx--btn--xl",
				kind && `bx--btn--${kind}`,
				disabled && "bx--btn--disabled",
				hasIconOnly && "bx--btn--icon-only",
				hasIconOnly && "bx--tooltip__trigger",
				hasIconOnly && "bx--tooltip--a11y",
				hasIconOnly && tooltipPosition && `bx--btn--icon-only--${tooltipPosition}`,
				hasIconOnly && tooltipAlignment && `bx--tooltip--align-${tooltipAlignment}`,
				hasIconOnly && isSelected && kind === "ghost" && "bx--btn--selected",
				$$restProps.class
			].filter(Boolean).join(" ")
		});
	};

	return [
		ref,
		size,
		icon,
		iconDescription,
		as,
		skeleton,
		disabled,
		href,
		hasIconOnly,
		buttonProps,
		$$restProps,
		kind,
		expressive,
		isSelected,
		tooltipAlignment,
		tooltipPosition,
		tabindex,
		type,
		$$scope,
		slots,
		click_handler_1,
		mouseover_handler_1,
		mouseenter_handler_1,
		mouseleave_handler_1,
		click_handler_2,
		mouseover_handler_2,
		mouseenter_handler_2,
		mouseleave_handler_2,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler,
		a_binding,
		button_binding
	];
}

class Button extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$1,
			create_fragment$1,
			safe_not_equal,
			{
				kind: 11,
				size: 1,
				expressive: 12,
				isSelected: 13,
				icon: 2,
				iconDescription: 3,
				tooltipAlignment: 14,
				tooltipPosition: 15,
				as: 4,
				skeleton: 5,
				disabled: 6,
				href: 7,
				tabindex: 16,
				type: 17,
				ref: 0
			},
			null,
			[-1, -1]
		);
	}
}

/* node_modules/carbon-components-svelte/src/Checkbox/CheckboxSkeleton.svelte generated by Svelte v3.47.0 */

function create_fragment$2(ctx) {
	let div;
	let span;
	let mounted;
	let dispose;
	let div_levels = [/*$$restProps*/ ctx[0]];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			span = element("span");
			toggle_class(span, "bx--checkbox-label-text", true);
			toggle_class(span, "bx--skeleton", true);
			set_attributes(div, div_data);
			toggle_class(div, "bx--form-item", true);
			toggle_class(div, "bx--checkbox-wrapper", true);
			toggle_class(div, "bx--checkbox-label", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span);

			if (!mounted) {
				dispose = [
					listen(div, "click", /*click_handler*/ ctx[1]),
					listen(div, "mouseover", /*mouseover_handler*/ ctx[2]),
					listen(div, "mouseenter", /*mouseenter_handler*/ ctx[3]),
					listen(div, "mouseleave", /*mouseleave_handler*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			set_attributes(div, div_data = get_spread_update(div_levels, [dirty & /*$$restProps*/ 1 && /*$$restProps*/ ctx[0]]));
			toggle_class(div, "bx--form-item", true);
			toggle_class(div, "bx--checkbox-wrapper", true);
			toggle_class(div, "bx--checkbox-label", true);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	const omit_props_names = [];
	let $$restProps = compute_rest_props($$props, omit_props_names);

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(0, $$restProps = compute_rest_props($$props, omit_props_names));
	};

	return [
		$$restProps,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler
	];
}

class CheckboxSkeleton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$2, create_fragment$2, safe_not_equal, {});
	}
}

/* node_modules/carbon-components-svelte/src/Checkbox/Checkbox.svelte generated by Svelte v3.47.0 */
const get_labelText_slot_changes = dirty => ({});
const get_labelText_slot_context = ctx => ({});

// (76:0) {:else}
function create_else_block$2(ctx) {
	let div;
	let input;
	let t;
	let label;
	let span;
	let current;
	let mounted;
	let dispose;
	const labelText_slot_template = /*#slots*/ ctx[17].labelText;
	const labelText_slot = create_slot(labelText_slot_template, ctx, /*$$scope*/ ctx[16], get_labelText_slot_context);
	const labelText_slot_or_fallback = labelText_slot || fallback_block(ctx);
	let div_levels = [/*$$restProps*/ ctx[15]];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			input = element("input");
			t = space();
			label = element("label");
			span = element("span");
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.c();
			attr(input, "type", "checkbox");
			input.value = /*value*/ ctx[3];
			input.checked = /*checked*/ ctx[0];
			input.disabled = /*disabled*/ ctx[8];
			attr(input, "id", /*id*/ ctx[13]);
			input.indeterminate = /*indeterminate*/ ctx[4];
			attr(input, "name", /*name*/ ctx[11]);
			input.required = /*required*/ ctx[6];
			input.readOnly = /*readonly*/ ctx[7];
			toggle_class(input, "bx--checkbox", true);
			toggle_class(span, "bx--checkbox-label-text", true);
			toggle_class(span, "bx--visually-hidden", /*hideLabel*/ ctx[10]);
			attr(label, "for", /*id*/ ctx[13]);
			attr(label, "title", /*title*/ ctx[12]);
			toggle_class(label, "bx--checkbox-label", true);
			set_attributes(div, div_data);
			toggle_class(div, "bx--form-item", true);
			toggle_class(div, "bx--checkbox-wrapper", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, input);
			/*input_binding*/ ctx[28](input);
			append(div, t);
			append(div, label);
			append(label, span);

			if (labelText_slot_or_fallback) {
				labelText_slot_or_fallback.m(span, null);
			}

			current = true;

			if (!mounted) {
				dispose = [
					listen(input, "change", /*change_handler_1*/ ctx[29]),
					listen(input, "change", /*change_handler*/ ctx[22]),
					listen(input, "blur", /*blur_handler*/ ctx[23]),
					listen(div, "click", /*click_handler_1*/ ctx[18]),
					listen(div, "mouseover", /*mouseover_handler_1*/ ctx[19]),
					listen(div, "mouseenter", /*mouseenter_handler_1*/ ctx[20]),
					listen(div, "mouseleave", /*mouseleave_handler_1*/ ctx[21])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!current || dirty & /*value*/ 8) {
				input.value = /*value*/ ctx[3];
			}

			if (!current || dirty & /*checked*/ 1) {
				input.checked = /*checked*/ ctx[0];
			}

			if (!current || dirty & /*disabled*/ 256) {
				input.disabled = /*disabled*/ ctx[8];
			}

			if (!current || dirty & /*id*/ 8192) {
				attr(input, "id", /*id*/ ctx[13]);
			}

			if (!current || dirty & /*indeterminate*/ 16) {
				input.indeterminate = /*indeterminate*/ ctx[4];
			}

			if (!current || dirty & /*name*/ 2048) {
				attr(input, "name", /*name*/ ctx[11]);
			}

			if (!current || dirty & /*required*/ 64) {
				input.required = /*required*/ ctx[6];
			}

			if (!current || dirty & /*readonly*/ 128) {
				input.readOnly = /*readonly*/ ctx[7];
			}

			if (labelText_slot) {
				if (labelText_slot.p && (!current || dirty & /*$$scope*/ 65536)) {
					update_slot_base(
						labelText_slot,
						labelText_slot_template,
						ctx,
						/*$$scope*/ ctx[16],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[16])
						: get_slot_changes(labelText_slot_template, /*$$scope*/ ctx[16], dirty, get_labelText_slot_changes),
						get_labelText_slot_context
					);
				}
			} else {
				if (labelText_slot_or_fallback && labelText_slot_or_fallback.p && (!current || dirty & /*labelText*/ 512)) {
					labelText_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (dirty & /*hideLabel*/ 1024) {
				toggle_class(span, "bx--visually-hidden", /*hideLabel*/ ctx[10]);
			}

			if (!current || dirty & /*id*/ 8192) {
				attr(label, "for", /*id*/ ctx[13]);
			}

			if (!current || dirty & /*title*/ 4096) {
				attr(label, "title", /*title*/ ctx[12]);
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [dirty & /*$$restProps*/ 32768 && /*$$restProps*/ ctx[15]]));
			toggle_class(div, "bx--form-item", true);
			toggle_class(div, "bx--checkbox-wrapper", true);
		},
		i(local) {
			if (current) return;
			transition_in(labelText_slot_or_fallback, local);
			current = true;
		},
		o(local) {
			transition_out(labelText_slot_or_fallback, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			/*input_binding*/ ctx[28](null);
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.d(detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (68:0) {#if skeleton}
function create_if_block$2(ctx) {
	let checkboxskeleton;
	let current;
	const checkboxskeleton_spread_levels = [/*$$restProps*/ ctx[15]];
	let checkboxskeleton_props = {};

	for (let i = 0; i < checkboxskeleton_spread_levels.length; i += 1) {
		checkboxskeleton_props = assign(checkboxskeleton_props, checkboxskeleton_spread_levels[i]);
	}

	checkboxskeleton = new CheckboxSkeleton({ props: checkboxskeleton_props });
	checkboxskeleton.$on("click", /*click_handler*/ ctx[24]);
	checkboxskeleton.$on("mouseover", /*mouseover_handler*/ ctx[25]);
	checkboxskeleton.$on("mouseenter", /*mouseenter_handler*/ ctx[26]);
	checkboxskeleton.$on("mouseleave", /*mouseleave_handler*/ ctx[27]);

	return {
		c() {
			create_component(checkboxskeleton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(checkboxskeleton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const checkboxskeleton_changes = (dirty & /*$$restProps*/ 32768)
			? get_spread_update(checkboxskeleton_spread_levels, [get_spread_object(/*$$restProps*/ ctx[15])])
			: {};

			checkboxskeleton.$set(checkboxskeleton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(checkboxskeleton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(checkboxskeleton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(checkboxskeleton, detaching);
		}
	};
}

// (115:31)            
function fallback_block(ctx) {
	let t;

	return {
		c() {
			t = text(/*labelText*/ ctx[9]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*labelText*/ 512) set_data(t, /*labelText*/ ctx[9]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment$3(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block$2, create_else_block$2];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*skeleton*/ ctx[5]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let useGroup;

	const omit_props_names = [
		"value","checked","group","indeterminate","skeleton","required","readonly","disabled","labelText","hideLabel","name","title","id","ref"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { value = "" } = $$props;
	let { checked = false } = $$props;
	let { group = undefined } = $$props;
	let { indeterminate = false } = $$props;
	let { skeleton = false } = $$props;
	let { required = false } = $$props;
	let { readonly = false } = $$props;
	let { disabled = false } = $$props;
	let { labelText = "" } = $$props;
	let { hideLabel = false } = $$props;
	let { name = "" } = $$props;
	let { title = undefined } = $$props;
	let { id = "ccs-" + Math.random().toString(36) } = $$props;
	let { ref = null } = $$props;
	const dispatch = createEventDispatcher();

	function click_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function change_handler(event) {
		bubble.call(this, $$self, event);
	}

	function blur_handler(event) {
		bubble.call(this, $$self, event);
	}

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	function input_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			ref = $$value;
			$$invalidate(2, ref);
		});
	}

	const change_handler_1 = () => {
		if (useGroup) {
			$$invalidate(1, group = group.includes(value)
			? group.filter(_value => _value !== value)
			: [...group, value]);
		} else {
			$$invalidate(0, checked = !checked);
		}
	};

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(15, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('value' in $$new_props) $$invalidate(3, value = $$new_props.value);
		if ('checked' in $$new_props) $$invalidate(0, checked = $$new_props.checked);
		if ('group' in $$new_props) $$invalidate(1, group = $$new_props.group);
		if ('indeterminate' in $$new_props) $$invalidate(4, indeterminate = $$new_props.indeterminate);
		if ('skeleton' in $$new_props) $$invalidate(5, skeleton = $$new_props.skeleton);
		if ('required' in $$new_props) $$invalidate(6, required = $$new_props.required);
		if ('readonly' in $$new_props) $$invalidate(7, readonly = $$new_props.readonly);
		if ('disabled' in $$new_props) $$invalidate(8, disabled = $$new_props.disabled);
		if ('labelText' in $$new_props) $$invalidate(9, labelText = $$new_props.labelText);
		if ('hideLabel' in $$new_props) $$invalidate(10, hideLabel = $$new_props.hideLabel);
		if ('name' in $$new_props) $$invalidate(11, name = $$new_props.name);
		if ('title' in $$new_props) $$invalidate(12, title = $$new_props.title);
		if ('id' in $$new_props) $$invalidate(13, id = $$new_props.id);
		if ('ref' in $$new_props) $$invalidate(2, ref = $$new_props.ref);
		if ('$$scope' in $$new_props) $$invalidate(16, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*group*/ 2) {
			 $$invalidate(14, useGroup = Array.isArray(group));
		}

		if ($$self.$$.dirty & /*useGroup, group, value, checked*/ 16395) {
			 $$invalidate(0, checked = useGroup ? group.includes(value) : checked);
		}

		if ($$self.$$.dirty & /*checked*/ 1) {
			 dispatch("check", checked);
		}
	};

	return [
		checked,
		group,
		ref,
		value,
		indeterminate,
		skeleton,
		required,
		readonly,
		disabled,
		labelText,
		hideLabel,
		name,
		title,
		id,
		useGroup,
		$$restProps,
		$$scope,
		slots,
		click_handler_1,
		mouseover_handler_1,
		mouseenter_handler_1,
		mouseleave_handler_1,
		change_handler,
		blur_handler,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler,
		input_binding,
		change_handler_1
	];
}

class Checkbox extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$3, create_fragment$3, safe_not_equal, {
			value: 3,
			checked: 0,
			group: 1,
			indeterminate: 4,
			skeleton: 5,
			required: 6,
			readonly: 7,
			disabled: 8,
			labelText: 9,
			hideLabel: 10,
			name: 11,
			title: 12,
			id: 13,
			ref: 2
		});
	}
}

/* node_modules/carbon-components-svelte/src/icons/WarningFilled.svelte generated by Svelte v3.47.0 */

function create_if_block$3(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$4(ctx) {
	let svg;
	let path0;
	let path1;
	let if_block = /*title*/ ctx[1] && create_if_block$3(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "d", "M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14C30,8.3,23.7,2,16,2z M14.9,8h2.2v11h-2.2V8z M16,25\tc-0.8,0-1.5-0.7-1.5-1.5S15.2,22,16,22c0.8,0,1.5,0.7,1.5,1.5S16.8,25,16,25z");
			attr(path1, "fill", "none");
			attr(path1, "d", "M17.5,23.5c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5S15.2,22,16,22\tC16.8,22,17.5,22.7,17.5,23.5z M17.1,8h-2.2v11h2.2V8z");
			attr(path1, "data-icon-path", "inner-path");
			attr(path1, "opacity", "0");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$3(ctx);
					if_block.c();
					if_block.m(svg, path0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$4($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class WarningFilled extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$4, create_fragment$4, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/icons/WarningAltFilled.svelte generated by Svelte v3.47.0 */

function create_if_block$4(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$5(ctx) {
	let svg;
	let path0;
	let path1;
	let path2;
	let if_block = /*title*/ ctx[1] && create_if_block$4(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path0 = svg_element("path");
			path1 = svg_element("path");
			path2 = svg_element("path");
			attr(path0, "fill", "none");
			attr(path0, "d", "M16,26a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,16,26Zm-1.125-5h2.25V12h-2.25Z");
			attr(path0, "data-icon-path", "inner-path");
			attr(path1, "d", "M16.002,6.1714h-.004L4.6487,27.9966,4.6506,28H27.3494l.0019-.0034ZM14.875,12h2.25v9h-2.25ZM16,26a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,16,26Z");
			attr(path2, "d", "M29,30H3a1,1,0,0,1-.8872-1.4614l13-25a1,1,0,0,1,1.7744,0l13,25A1,1,0,0,1,29,30ZM4.6507,28H27.3493l.002-.0033L16.002,6.1714h-.004L4.6487,27.9967Z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path0);
			append(svg, path1);
			append(svg, path2);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$4(ctx);
					if_block.c();
					if_block.m(svg, path0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$5($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class WarningAltFilled extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$5, create_fragment$5, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/icons/ChevronDown.svelte generated by Svelte v3.47.0 */

function create_if_block$5(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$6(ctx) {
	let svg;
	let path;
	let if_block = /*title*/ ctx[1] && create_if_block$5(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path = svg_element("path");
			attr(path, "d", "M16 22L6 12 7.4 10.6 16 19.2 24.6 10.6 26 12z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$5(ctx);
					if_block.c();
					if_block.m(svg, path);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$6($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class ChevronDown extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$6, create_fragment$6, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/icons/Close.svelte generated by Svelte v3.47.0 */

function create_if_block$6(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$7(ctx) {
	let svg;
	let path;
	let if_block = /*title*/ ctx[1] && create_if_block$6(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path = svg_element("path");
			attr(path, "d", "M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$6(ctx);
					if_block.c();
					if_block.m(svg, path);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$7($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class Close extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$7, create_fragment$7, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/icons/CheckmarkFilled.svelte generated by Svelte v3.47.0 */

function create_if_block$7(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$8(ctx) {
	let svg;
	let path0;
	let path1;
	let if_block = /*title*/ ctx[1] && create_if_block$7(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "d", "M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2ZM14,21.5908l-5-5L10.5906,15,14,18.4092,21.41,11l1.5957,1.5859Z");
			attr(path1, "fill", "none");
			attr(path1, "d", "M14 21.591L9 16.591 10.591 15 14 18.409 21.41 11 23.005 12.585 14 21.591z");
			attr(path1, "data-icon-path", "inner-path");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$7(ctx);
					if_block.c();
					if_block.m(svg, path0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$8($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class CheckmarkFilled extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$8, create_fragment$8, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/Loading/Loading.svelte generated by Svelte v3.47.0 */

function create_else_block$3(ctx) {
	let div;
	let label;
	let t0;
	let t1;
	let svg;
	let title;
	let t2;
	let circle;
	let div_aria_live_value;
	let if_block = /*small*/ ctx[0] && create_if_block_2$1(ctx);

	let div_levels = [
		{ "aria-atomic": "true" },
		{ "aria-labelledby": /*id*/ ctx[4] },
		{
			"aria-live": div_aria_live_value = /*active*/ ctx[1] ? 'assertive' : 'off'
		},
		/*$$restProps*/ ctx[6]
	];

	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			label = element("label");
			t0 = text(/*description*/ ctx[3]);
			t1 = space();
			svg = svg_element("svg");
			title = svg_element("title");
			t2 = text(/*description*/ ctx[3]);
			if (if_block) if_block.c();
			circle = svg_element("circle");
			attr(label, "id", /*id*/ ctx[4]);
			toggle_class(label, "bx--visually-hidden", true);
			attr(circle, "cx", "50%");
			attr(circle, "cy", "50%");
			attr(circle, "r", /*spinnerRadius*/ ctx[5]);
			toggle_class(circle, "bx--loading__stroke", true);
			attr(svg, "viewBox", "0 0 100 100");
			toggle_class(svg, "bx--loading__svg", true);
			set_attributes(div, div_data);
			toggle_class(div, "bx--loading", true);
			toggle_class(div, "bx--loading--small", /*small*/ ctx[0]);
			toggle_class(div, "bx--loading--stop", !/*active*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, label);
			append(label, t0);
			append(div, t1);
			append(div, svg);
			append(svg, title);
			append(title, t2);
			if (if_block) if_block.m(svg, null);
			append(svg, circle);
		},
		p(ctx, dirty) {
			if (dirty & /*description*/ 8) set_data(t0, /*description*/ ctx[3]);

			if (dirty & /*id*/ 16) {
				attr(label, "id", /*id*/ ctx[4]);
			}

			if (dirty & /*description*/ 8) set_data(t2, /*description*/ ctx[3]);

			if (/*small*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2$1(ctx);
					if_block.c();
					if_block.m(svg, circle);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*spinnerRadius*/ 32) {
				attr(circle, "r", /*spinnerRadius*/ ctx[5]);
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [
				{ "aria-atomic": "true" },
				dirty & /*id*/ 16 && { "aria-labelledby": /*id*/ ctx[4] },
				dirty & /*active*/ 2 && div_aria_live_value !== (div_aria_live_value = /*active*/ ctx[1] ? 'assertive' : 'off') && { "aria-live": div_aria_live_value },
				dirty & /*$$restProps*/ 64 && /*$$restProps*/ ctx[6]
			]));

			toggle_class(div, "bx--loading", true);
			toggle_class(div, "bx--loading--small", /*small*/ ctx[0]);
			toggle_class(div, "bx--loading--stop", !/*active*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

// (20:0) {#if withOverlay}
function create_if_block$8(ctx) {
	let div1;
	let div0;
	let label;
	let t0;
	let t1;
	let svg;
	let title;
	let t2;
	let circle;
	let div0_aria_live_value;
	let if_block = /*small*/ ctx[0] && create_if_block_1$1(ctx);
	let div1_levels = [/*$$restProps*/ ctx[6]];
	let div1_data = {};

	for (let i = 0; i < div1_levels.length; i += 1) {
		div1_data = assign(div1_data, div1_levels[i]);
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			label = element("label");
			t0 = text(/*description*/ ctx[3]);
			t1 = space();
			svg = svg_element("svg");
			title = svg_element("title");
			t2 = text(/*description*/ ctx[3]);
			if (if_block) if_block.c();
			circle = svg_element("circle");
			attr(label, "id", /*id*/ ctx[4]);
			toggle_class(label, "bx--visually-hidden", true);
			attr(circle, "cx", "50%");
			attr(circle, "cy", "50%");
			attr(circle, "r", /*spinnerRadius*/ ctx[5]);
			toggle_class(circle, "bx--loading__stroke", true);
			attr(svg, "viewBox", "0 0 100 100");
			toggle_class(svg, "bx--loading__svg", true);
			attr(div0, "aria-atomic", "true");
			attr(div0, "aria-labelledby", /*id*/ ctx[4]);
			attr(div0, "aria-live", div0_aria_live_value = /*active*/ ctx[1] ? 'assertive' : 'off');
			toggle_class(div0, "bx--loading", true);
			toggle_class(div0, "bx--loading--small", /*small*/ ctx[0]);
			toggle_class(div0, "bx--loading--stop", !/*active*/ ctx[1]);
			set_attributes(div1, div1_data);
			toggle_class(div1, "bx--loading-overlay", true);
			toggle_class(div1, "bx--loading-overlay--stop", !/*active*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, label);
			append(label, t0);
			append(div0, t1);
			append(div0, svg);
			append(svg, title);
			append(title, t2);
			if (if_block) if_block.m(svg, null);
			append(svg, circle);
		},
		p(ctx, dirty) {
			if (dirty & /*description*/ 8) set_data(t0, /*description*/ ctx[3]);

			if (dirty & /*id*/ 16) {
				attr(label, "id", /*id*/ ctx[4]);
			}

			if (dirty & /*description*/ 8) set_data(t2, /*description*/ ctx[3]);

			if (/*small*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1$1(ctx);
					if_block.c();
					if_block.m(svg, circle);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*spinnerRadius*/ 32) {
				attr(circle, "r", /*spinnerRadius*/ ctx[5]);
			}

			if (dirty & /*id*/ 16) {
				attr(div0, "aria-labelledby", /*id*/ ctx[4]);
			}

			if (dirty & /*active*/ 2 && div0_aria_live_value !== (div0_aria_live_value = /*active*/ ctx[1] ? 'assertive' : 'off')) {
				attr(div0, "aria-live", div0_aria_live_value);
			}

			if (dirty & /*small*/ 1) {
				toggle_class(div0, "bx--loading--small", /*small*/ ctx[0]);
			}

			if (dirty & /*active*/ 2) {
				toggle_class(div0, "bx--loading--stop", !/*active*/ ctx[1]);
			}

			set_attributes(div1, div1_data = get_spread_update(div1_levels, [dirty & /*$$restProps*/ 64 && /*$$restProps*/ ctx[6]]));
			toggle_class(div1, "bx--loading-overlay", true);
			toggle_class(div1, "bx--loading-overlay--stop", !/*active*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
		}
	};
}

// (67:6) {#if small}
function create_if_block_2$1(ctx) {
	let circle;

	return {
		c() {
			circle = svg_element("circle");
			attr(circle, "cx", "50%");
			attr(circle, "cy", "50%");
			attr(circle, "r", /*spinnerRadius*/ ctx[5]);
			toggle_class(circle, "bx--loading__background", true);
		},
		m(target, anchor) {
			insert(target, circle, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*spinnerRadius*/ 32) {
				attr(circle, "r", /*spinnerRadius*/ ctx[5]);
			}
		},
		d(detaching) {
			if (detaching) detach(circle);
		}
	};
}

// (38:8) {#if small}
function create_if_block_1$1(ctx) {
	let circle;

	return {
		c() {
			circle = svg_element("circle");
			attr(circle, "cx", "50%");
			attr(circle, "cy", "50%");
			attr(circle, "r", /*spinnerRadius*/ ctx[5]);
			toggle_class(circle, "bx--loading__background", true);
		},
		m(target, anchor) {
			insert(target, circle, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*spinnerRadius*/ 32) {
				attr(circle, "r", /*spinnerRadius*/ ctx[5]);
			}
		},
		d(detaching) {
			if (detaching) detach(circle);
		}
	};
}

function create_fragment$9(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*withOverlay*/ ctx[2]) return create_if_block$8;
		return create_else_block$3;
	}

	let current_block_type = select_block_type(ctx);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$9($$self, $$props, $$invalidate) {
	let spinnerRadius;
	const omit_props_names = ["small","active","withOverlay","description","id"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { small = false } = $$props;
	let { active = true } = $$props;
	let { withOverlay = true } = $$props;
	let { description = "Active loading indicator" } = $$props;
	let { id = "ccs-" + Math.random().toString(36) } = $$props;

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(6, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('small' in $$new_props) $$invalidate(0, small = $$new_props.small);
		if ('active' in $$new_props) $$invalidate(1, active = $$new_props.active);
		if ('withOverlay' in $$new_props) $$invalidate(2, withOverlay = $$new_props.withOverlay);
		if ('description' in $$new_props) $$invalidate(3, description = $$new_props.description);
		if ('id' in $$new_props) $$invalidate(4, id = $$new_props.id);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*small*/ 1) {
			 $$invalidate(5, spinnerRadius = small ? "42" : "44");
		}
	};

	return [small, active, withOverlay, description, id, spinnerRadius, $$restProps];
}

class Loading extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$9, create_fragment$9, safe_not_equal, {
			small: 0,
			active: 1,
			withOverlay: 2,
			description: 3,
			id: 4
		});
	}
}

/* node_modules/carbon-components-svelte/src/Grid/Grid.svelte generated by Svelte v3.47.0 */

const get_default_slot_changes$1 = dirty => ({ props: dirty & /*props*/ 2 });
const get_default_slot_context$1 = ctx => ({ props: /*props*/ ctx[1] });

// (54:0) {:else}
function create_else_block$4(ctx) {
	let div;
	let current;
	const default_slot_template = /*#slots*/ ctx[10].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[9], null);
	let div_levels = [/*props*/ ctx[1]];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			set_attributes(div, div_data);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 512)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[9],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[9])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[9], dirty, null),
						null
					);
				}
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [dirty & /*props*/ 2 && /*props*/ ctx[1]]));
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (52:0) {#if as}
function create_if_block$9(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[10].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[9], get_default_slot_context$1);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope, props*/ 514)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[9],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[9])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[9], dirty, get_default_slot_changes$1),
						get_default_slot_context$1
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment$a(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block$9, create_else_block$4];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*as*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$a($$self, $$props, $$invalidate) {
	let props;

	const omit_props_names = [
		"as","condensed","narrow","fullWidth","noGutter","noGutterLeft","noGutterRight","padding"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { as = false } = $$props;
	let { condensed = false } = $$props;
	let { narrow = false } = $$props;
	let { fullWidth = false } = $$props;
	let { noGutter = false } = $$props;
	let { noGutterLeft = false } = $$props;
	let { noGutterRight = false } = $$props;
	let { padding = false } = $$props;

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(11, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('as' in $$new_props) $$invalidate(0, as = $$new_props.as);
		if ('condensed' in $$new_props) $$invalidate(2, condensed = $$new_props.condensed);
		if ('narrow' in $$new_props) $$invalidate(3, narrow = $$new_props.narrow);
		if ('fullWidth' in $$new_props) $$invalidate(4, fullWidth = $$new_props.fullWidth);
		if ('noGutter' in $$new_props) $$invalidate(5, noGutter = $$new_props.noGutter);
		if ('noGutterLeft' in $$new_props) $$invalidate(6, noGutterLeft = $$new_props.noGutterLeft);
		if ('noGutterRight' in $$new_props) $$invalidate(7, noGutterRight = $$new_props.noGutterRight);
		if ('padding' in $$new_props) $$invalidate(8, padding = $$new_props.padding);
		if ('$$scope' in $$new_props) $$invalidate(9, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		 $$invalidate(1, props = {
			...$$restProps,
			class: [
				$$restProps.class,
				"bx--grid",
				condensed && "bx--grid--condensed",
				narrow && "bx--grid--narrow",
				fullWidth && "bx--grid--full-width",
				noGutter && "bx--no-gutter",
				noGutterLeft && "bx--no-gutter--left",
				noGutterRight && "bx--no-gutter--right",
				padding && "bx--row-padding"
			].filter(Boolean).join(" ")
		});
	};

	return [
		as,
		props,
		condensed,
		narrow,
		fullWidth,
		noGutter,
		noGutterLeft,
		noGutterRight,
		padding,
		$$scope,
		slots
	];
}

class Grid extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$a, create_fragment$a, safe_not_equal, {
			as: 0,
			condensed: 2,
			narrow: 3,
			fullWidth: 4,
			noGutter: 5,
			noGutterLeft: 6,
			noGutterRight: 7,
			padding: 8
		});
	}
}

/* node_modules/carbon-components-svelte/src/Grid/Row.svelte generated by Svelte v3.47.0 */

const get_default_slot_changes$2 = dirty => ({ props: dirty & /*props*/ 2 });
const get_default_slot_context$2 = ctx => ({ props: /*props*/ ctx[1] });

// (50:0) {:else}
function create_else_block$5(ctx) {
	let div;
	let current;
	const default_slot_template = /*#slots*/ ctx[9].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[8], null);
	let div_levels = [/*props*/ ctx[1]];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			set_attributes(div, div_data);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 256)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[8],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[8])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[8], dirty, null),
						null
					);
				}
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [dirty & /*props*/ 2 && /*props*/ ctx[1]]));
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (48:0) {#if as}
function create_if_block$a(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[9].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[8], get_default_slot_context$2);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope, props*/ 258)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[8],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[8])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[8], dirty, get_default_slot_changes$2),
						get_default_slot_context$2
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment$b(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block$a, create_else_block$5];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*as*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$b($$self, $$props, $$invalidate) {
	let props;
	const omit_props_names = ["as","condensed","narrow","noGutter","noGutterLeft","noGutterRight","padding"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { as = false } = $$props;
	let { condensed = false } = $$props;
	let { narrow = false } = $$props;
	let { noGutter = false } = $$props;
	let { noGutterLeft = false } = $$props;
	let { noGutterRight = false } = $$props;
	let { padding = false } = $$props;

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(10, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('as' in $$new_props) $$invalidate(0, as = $$new_props.as);
		if ('condensed' in $$new_props) $$invalidate(2, condensed = $$new_props.condensed);
		if ('narrow' in $$new_props) $$invalidate(3, narrow = $$new_props.narrow);
		if ('noGutter' in $$new_props) $$invalidate(4, noGutter = $$new_props.noGutter);
		if ('noGutterLeft' in $$new_props) $$invalidate(5, noGutterLeft = $$new_props.noGutterLeft);
		if ('noGutterRight' in $$new_props) $$invalidate(6, noGutterRight = $$new_props.noGutterRight);
		if ('padding' in $$new_props) $$invalidate(7, padding = $$new_props.padding);
		if ('$$scope' in $$new_props) $$invalidate(8, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		 $$invalidate(1, props = {
			...$$restProps,
			class: [
				$$restProps.class,
				"bx--row",
				condensed && "bx--row--condensed",
				narrow && "bx--row--narrow",
				noGutter && "bx--no-gutter",
				noGutterLeft && "bx--no-gutter--left",
				noGutterRight && "bx--no-gutter--right",
				padding && "bx--row-padding"
			].filter(Boolean).join(" ")
		});
	};

	return [
		as,
		props,
		condensed,
		narrow,
		noGutter,
		noGutterLeft,
		noGutterRight,
		padding,
		$$scope,
		slots
	];
}

class Row extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$b, create_fragment$b, safe_not_equal, {
			as: 0,
			condensed: 2,
			narrow: 3,
			noGutter: 4,
			noGutterLeft: 5,
			noGutterRight: 6,
			padding: 7
		});
	}
}

/* node_modules/carbon-components-svelte/src/Grid/Column.svelte generated by Svelte v3.47.0 */

const get_default_slot_changes$3 = dirty => ({ props: dirty & /*props*/ 2 });
const get_default_slot_context$3 = ctx => ({ props: /*props*/ ctx[1] });

// (115:0) {:else}
function create_else_block$6(ctx) {
	let div;
	let current;
	const default_slot_template = /*#slots*/ ctx[14].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[13], null);
	let div_levels = [/*props*/ ctx[1]];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			set_attributes(div, div_data);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8192)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[13],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[13])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[13], dirty, null),
						null
					);
				}
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [dirty & /*props*/ 2 && /*props*/ ctx[1]]));
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (113:0) {#if as}
function create_if_block$b(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[14].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[13], get_default_slot_context$3);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope, props*/ 8194)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[13],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[13])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[13], dirty, get_default_slot_changes$3),
						get_default_slot_context$3
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment$c(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block$b, create_else_block$6];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*as*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$c($$self, $$props, $$invalidate) {
	let columnClass;
	let props;

	const omit_props_names = [
		"as","noGutter","noGutterLeft","noGutterRight","padding","aspectRatio","sm","md","lg","xlg","max"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { as = false } = $$props;
	let { noGutter = false } = $$props;
	let { noGutterLeft = false } = $$props;
	let { noGutterRight = false } = $$props;
	let { padding = false } = $$props;
	let { aspectRatio = undefined } = $$props;
	let { sm = undefined } = $$props;
	let { md = undefined } = $$props;
	let { lg = undefined } = $$props;
	let { xlg = undefined } = $$props;
	let { max = undefined } = $$props;
	const breakpoints = ["sm", "md", "lg", "xlg", "max"];

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(16, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('as' in $$new_props) $$invalidate(0, as = $$new_props.as);
		if ('noGutter' in $$new_props) $$invalidate(2, noGutter = $$new_props.noGutter);
		if ('noGutterLeft' in $$new_props) $$invalidate(3, noGutterLeft = $$new_props.noGutterLeft);
		if ('noGutterRight' in $$new_props) $$invalidate(4, noGutterRight = $$new_props.noGutterRight);
		if ('padding' in $$new_props) $$invalidate(5, padding = $$new_props.padding);
		if ('aspectRatio' in $$new_props) $$invalidate(6, aspectRatio = $$new_props.aspectRatio);
		if ('sm' in $$new_props) $$invalidate(7, sm = $$new_props.sm);
		if ('md' in $$new_props) $$invalidate(8, md = $$new_props.md);
		if ('lg' in $$new_props) $$invalidate(9, lg = $$new_props.lg);
		if ('xlg' in $$new_props) $$invalidate(10, xlg = $$new_props.xlg);
		if ('max' in $$new_props) $$invalidate(11, max = $$new_props.max);
		if ('$$scope' in $$new_props) $$invalidate(13, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*sm, md, lg, xlg, max*/ 3968) {
			 $$invalidate(12, columnClass = [sm, md, lg, xlg, max].map((breakpoint, i) => {
				const name = breakpoints[i];

				if (breakpoint === true) {
					return `bx--col-${name}`;
				} else if (typeof breakpoint === "number") {
					return `bx--col-${name}-${breakpoint}`;
				} else if (typeof breakpoint === "object") {
					let bp = [];

					if (typeof breakpoint.span === "number") {
						bp = [...bp, `bx--col-${name}-${breakpoint.span}`];
					} else if (breakpoint.span === true) {
						bp = [...bp, `bx--col-${name}`];
					}

					if (typeof breakpoint.offset === "number") {
						bp = [...bp, `bx--offset-${name}-${breakpoint.offset}`];
					}

					return bp.join(" ");
				}
			}).filter(Boolean).join(" "));
		}

		 $$invalidate(1, props = {
			...$$restProps,
			class: [
				$$restProps.class,
				columnClass,
				!columnClass && "bx--col",
				noGutter && "bx--no-gutter",
				noGutterLeft && "bx--no-gutter--left",
				noGutterRight && "bx--no-gutter--right",
				aspectRatio && `bx--aspect-ratio bx--aspect-ratio--${aspectRatio}`,
				padding && "bx--col-padding"
			].filter(Boolean).join(" ")
		});
	};

	return [
		as,
		props,
		noGutter,
		noGutterLeft,
		noGutterRight,
		padding,
		aspectRatio,
		sm,
		md,
		lg,
		xlg,
		max,
		columnClass,
		$$scope,
		slots
	];
}

class Column extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$c, create_fragment$c, safe_not_equal, {
			as: 0,
			noGutter: 2,
			noGutterLeft: 3,
			noGutterRight: 4,
			padding: 5,
			aspectRatio: 6,
			sm: 7,
			md: 8,
			lg: 9,
			xlg: 10,
			max: 11
		});
	}
}

/* node_modules/carbon-components-svelte/src/icons/ErrorFilled.svelte generated by Svelte v3.47.0 */

function create_if_block$c(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$d(ctx) {
	let svg;
	let path0;
	let path1;
	let if_block = /*title*/ ctx[1] && create_if_block$c(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "fill", "none");
			attr(path0, "d", "M14.9 7.2H17.1V24.799H14.9z");
			attr(path0, "data-icon-path", "inner-path");
			attr(path0, "transform", "rotate(-45 16 16)");
			attr(path1, "d", "M16,2A13.914,13.914,0,0,0,2,16,13.914,13.914,0,0,0,16,30,13.914,13.914,0,0,0,30,16,13.914,13.914,0,0,0,16,2Zm5.4449,21L9,10.5557,10.5557,9,23,21.4448Z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$c(ctx);
					if_block.c();
					if_block.m(svg, path0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$d($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class ErrorFilled extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$d, create_fragment$d, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/InlineLoading/InlineLoading.svelte generated by Svelte v3.47.0 */

function create_if_block_3$1(ctx) {
	let loading;
	let current;

	loading = new Loading({
			props: {
				small: true,
				description: /*iconDescription*/ ctx[2],
				withOverlay: false,
				active: /*status*/ ctx[0] === 'active'
			}
		});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const loading_changes = {};
			if (dirty & /*iconDescription*/ 4) loading_changes.description = /*iconDescription*/ ctx[2];
			if (dirty & /*status*/ 1) loading_changes.active = /*status*/ ctx[0] === 'active';
			loading.$set(loading_changes);
		},
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

// (63:36) 
function create_if_block_2$2(ctx) {
	let checkmarkfilled;
	let current;

	checkmarkfilled = new CheckmarkFilled({
			props: {
				class: "bx--inline-loading__checkmark-container",
				title: /*iconDescription*/ ctx[2]
			}
		});

	return {
		c() {
			create_component(checkmarkfilled.$$.fragment);
		},
		m(target, anchor) {
			mount_component(checkmarkfilled, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const checkmarkfilled_changes = {};
			if (dirty & /*iconDescription*/ 4) checkmarkfilled_changes.title = /*iconDescription*/ ctx[2];
			checkmarkfilled.$set(checkmarkfilled_changes);
		},
		i(local) {
			if (current) return;
			transition_in(checkmarkfilled.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(checkmarkfilled.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(checkmarkfilled, detaching);
		}
	};
}

// (58:4) {#if status === "error"}
function create_if_block_1$2(ctx) {
	let errorfilled;
	let current;

	errorfilled = new ErrorFilled({
			props: {
				class: "bx--inline-loading--error",
				title: /*iconDescription*/ ctx[2]
			}
		});

	return {
		c() {
			create_component(errorfilled.$$.fragment);
		},
		m(target, anchor) {
			mount_component(errorfilled, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const errorfilled_changes = {};
			if (dirty & /*iconDescription*/ 4) errorfilled_changes.title = /*iconDescription*/ ctx[2];
			errorfilled.$set(errorfilled_changes);
		},
		i(local) {
			if (current) return;
			transition_in(errorfilled.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(errorfilled.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(errorfilled, detaching);
		}
	};
}

// (77:2) {#if description}
function create_if_block$d(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*description*/ ctx[1]);
			toggle_class(div, "bx--inline-loading__text", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*description*/ 2) set_data(t, /*description*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment$e(ctx) {
	let div1;
	let div0;
	let current_block_type_index;
	let if_block0;
	let t;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block_1$2, create_if_block_2$2, create_if_block_3$1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*status*/ ctx[0] === "error") return 0;
		if (/*status*/ ctx[0] === "finished") return 1;
		if (/*status*/ ctx[0] === "inactive" || /*status*/ ctx[0] === "active") return 2;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx))) {
		if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	let if_block1 = /*description*/ ctx[1] && create_if_block$d(ctx);
	let div1_levels = [{ "aria-live": "assertive" }, /*$$restProps*/ ctx[3]];
	let div1_data = {};

	for (let i = 0; i < div1_levels.length; i += 1) {
		div1_data = assign(div1_data, div1_levels[i]);
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			toggle_class(div0, "bx--inline-loading__animation", true);
			set_attributes(div1, div1_data);
			toggle_class(div1, "bx--inline-loading", true);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(div0, null);
			}

			append(div1, t);
			if (if_block1) if_block1.m(div1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div1, "click", /*click_handler*/ ctx[5]),
					listen(div1, "mouseover", /*mouseover_handler*/ ctx[6]),
					listen(div1, "mouseenter", /*mouseenter_handler*/ ctx[7]),
					listen(div1, "mouseleave", /*mouseleave_handler*/ ctx[8])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block0) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block0 = if_blocks[current_block_type_index];

					if (!if_block0) {
						if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block0.c();
					} else {
						if_block0.p(ctx, dirty);
					}

					transition_in(if_block0, 1);
					if_block0.m(div0, null);
				} else {
					if_block0 = null;
				}
			}

			if (/*description*/ ctx[1]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block$d(ctx);
					if_block1.c();
					if_block1.m(div1, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			set_attributes(div1, div1_data = get_spread_update(div1_levels, [
				{ "aria-live": "assertive" },
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));

			toggle_class(div1, "bx--inline-loading", true);
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d();
			}

			if (if_block1) if_block1.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$e($$self, $$props, $$invalidate) {
	const omit_props_names = ["status","description","iconDescription","successDelay"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { status = "active" } = $$props;
	let { description = undefined } = $$props;
	let { iconDescription = undefined } = $$props;
	let { successDelay = 1500 } = $$props;
	const dispatch = createEventDispatcher();
	let timeout = undefined;

	onMount(() => {
		return () => {
			clearTimeout(timeout);
		};
	});

	afterUpdate(() => {
		if (status === "finished") {
			timeout = setTimeout(
				() => {
					dispatch("success");
				},
				successDelay
			);
		}
	});

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('status' in $$new_props) $$invalidate(0, status = $$new_props.status);
		if ('description' in $$new_props) $$invalidate(1, description = $$new_props.description);
		if ('iconDescription' in $$new_props) $$invalidate(2, iconDescription = $$new_props.iconDescription);
		if ('successDelay' in $$new_props) $$invalidate(4, successDelay = $$new_props.successDelay);
	};

	return [
		status,
		description,
		iconDescription,
		$$restProps,
		successDelay,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler
	];
}

class InlineLoading extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$e, create_fragment$e, safe_not_equal, {
			status: 0,
			description: 1,
			iconDescription: 2,
			successDelay: 4
		});
	}
}

/* node_modules/carbon-components-svelte/src/LocalStorage/LocalStorage.svelte generated by Svelte v3.47.0 */

function clearAll() {
	localStorage.clear();
}

function instance$f($$self, $$props, $$invalidate) {
	let { key = "local-storage-key" } = $$props;
	let { value = "" } = $$props;

	function clearItem() {
		localStorage.removeItem(key);
	}

	const dispatch = createEventDispatcher();
	let prevValue = value;

	function setItem() {
		if (typeof value === "object") {
			localStorage.setItem(key, JSON.stringify(value));
		} else {
			localStorage.setItem(key, value);
		}
	}

	onMount(() => {
		const item = localStorage.getItem(key);

		if (item != null) {
			try {
				$$invalidate(0, value = JSON.parse(item));
			} catch(e) {
				$$invalidate(0, value = item);
			}
		} else {
			setItem();
			dispatch("save");
		}
	});

	afterUpdate(() => {
		if (prevValue !== value) {
			setItem();
			dispatch("update", { prevValue, value });
		}

		prevValue = value;
	});

	$$self.$$set = $$props => {
		if ('key' in $$props) $$invalidate(1, key = $$props.key);
		if ('value' in $$props) $$invalidate(0, value = $$props.value);
	};

	return [value, key, clearItem, clearAll];
}

class LocalStorage extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$f, null, safe_not_equal, {
			key: 1,
			value: 0,
			clearItem: 2,
			clearAll: 3
		});
	}

	get clearItem() {
		return this.$$.ctx[2];
	}

	get clearAll() {
		return clearAll;
	}
}

/* node_modules/carbon-components-svelte/src/Notification/NotificationButton.svelte generated by Svelte v3.47.0 */

function create_fragment$f(ctx) {
	let button;
	let switch_instance;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*icon*/ ctx[1];

	function switch_props(ctx) {
		return {
			props: {
				size: 20,
				title: /*title*/ ctx[2],
				class: "bx--" + /*notificationType*/ ctx[0] + "-notification__close-icon"
			}
		};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	let button_levels = [
		{ type: "button" },
		{ "aria-label": /*iconDescription*/ ctx[3] },
		{ title: /*iconDescription*/ ctx[3] },
		/*$$restProps*/ ctx[4]
	];

	let button_data = {};

	for (let i = 0; i < button_levels.length; i += 1) {
		button_data = assign(button_data, button_levels[i]);
	}

	return {
		c() {
			button = element("button");
			if (switch_instance) create_component(switch_instance.$$.fragment);
			set_attributes(button, button_data);
			toggle_class(button, "bx--toast-notification__close-button", /*notificationType*/ ctx[0] === 'toast');
			toggle_class(button, "bx--inline-notification__close-button", /*notificationType*/ ctx[0] === 'inline');
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (switch_instance) {
				mount_component(switch_instance, button, null);
			}

			if (button.autofocus) button.focus();
			current = true;

			if (!mounted) {
				dispose = [
					listen(button, "click", /*click_handler*/ ctx[5]),
					listen(button, "mouseover", /*mouseover_handler*/ ctx[6]),
					listen(button, "mouseenter", /*mouseenter_handler*/ ctx[7]),
					listen(button, "mouseleave", /*mouseleave_handler*/ ctx[8])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const switch_instance_changes = {};
			if (dirty & /*title*/ 4) switch_instance_changes.title = /*title*/ ctx[2];
			if (dirty & /*notificationType*/ 1) switch_instance_changes.class = "bx--" + /*notificationType*/ ctx[0] + "-notification__close-icon";

			if (switch_value !== (switch_value = /*icon*/ ctx[1])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, button, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}

			set_attributes(button, button_data = get_spread_update(button_levels, [
				{ type: "button" },
				(!current || dirty & /*iconDescription*/ 8) && { "aria-label": /*iconDescription*/ ctx[3] },
				(!current || dirty & /*iconDescription*/ 8) && { title: /*iconDescription*/ ctx[3] },
				dirty & /*$$restProps*/ 16 && /*$$restProps*/ ctx[4]
			]));

			toggle_class(button, "bx--toast-notification__close-button", /*notificationType*/ ctx[0] === 'toast');
			toggle_class(button, "bx--inline-notification__close-button", /*notificationType*/ ctx[0] === 'inline');
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(button);
			if (switch_instance) destroy_component(switch_instance);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$g($$self, $$props, $$invalidate) {
	const omit_props_names = ["notificationType","icon","title","iconDescription"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { notificationType = "toast" } = $$props;
	let { icon = Close } = $$props;
	let { title = undefined } = $$props;
	let { iconDescription = "Close icon" } = $$props;

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(4, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('notificationType' in $$new_props) $$invalidate(0, notificationType = $$new_props.notificationType);
		if ('icon' in $$new_props) $$invalidate(1, icon = $$new_props.icon);
		if ('title' in $$new_props) $$invalidate(2, title = $$new_props.title);
		if ('iconDescription' in $$new_props) $$invalidate(3, iconDescription = $$new_props.iconDescription);
	};

	return [
		notificationType,
		icon,
		title,
		iconDescription,
		$$restProps,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler
	];
}

class NotificationButton extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$g, create_fragment$f, safe_not_equal, {
			notificationType: 0,
			icon: 1,
			title: 2,
			iconDescription: 3
		});
	}
}

/* node_modules/carbon-components-svelte/src/icons/InformationFilled.svelte generated by Svelte v3.47.0 */

function create_if_block$e(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$g(ctx) {
	let svg;
	let path0;
	let path1;
	let if_block = /*title*/ ctx[1] && create_if_block$e(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "fill", "none");
			attr(path0, "d", "M16,8a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,8Zm4,13.875H17.125v-8H13v2.25h1.875v5.75H12v2.25h8Z");
			attr(path0, "data-icon-path", "inner-path");
			attr(path1, "d", "M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,6a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,8Zm4,16.125H12v-2.25h2.875v-5.75H13v-2.25h4.125v8H20Z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$e(ctx);
					if_block.c();
					if_block.m(svg, path0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$h($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class InformationFilled extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$h, create_fragment$g, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/icons/InformationSquareFilled.svelte generated by Svelte v3.47.0 */

function create_if_block$f(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$h(ctx) {
	let svg;
	let path0;
	let path1;
	let if_block = /*title*/ ctx[1] && create_if_block$f(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "fill", "none");
			attr(path0, "d", "M16,8a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,8Zm4,13.875H17.125v-8H13v2.25h1.875v5.75H12v2.25h8Z");
			attr(path0, "data-icon-path", "inner-path");
			attr(path1, "d", "M26,4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V6A2,2,0,0,0,26,4ZM16,8a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,8Zm4,16.125H12v-2.25h2.875v-5.75H13v-2.25h4.125v8H20Z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$f(ctx);
					if_block.c();
					if_block.m(svg, path0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$i($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class InformationSquareFilled extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$i, create_fragment$h, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/Notification/NotificationIcon.svelte generated by Svelte v3.47.0 */

function create_fragment$i(ctx) {
	let switch_instance;
	let switch_instance_anchor;
	let current;
	var switch_value = /*icons*/ ctx[3][/*kind*/ ctx[0]];

	function switch_props(ctx) {
		return {
			props: {
				size: 20,
				title: /*iconDescription*/ ctx[2],
				class: "bx--" + /*notificationType*/ ctx[1] + "-notification__icon"
			}
		};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	return {
		c() {
			if (switch_instance) create_component(switch_instance.$$.fragment);
			switch_instance_anchor = empty();
		},
		m(target, anchor) {
			if (switch_instance) {
				mount_component(switch_instance, target, anchor);
			}

			insert(target, switch_instance_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const switch_instance_changes = {};
			if (dirty & /*iconDescription*/ 4) switch_instance_changes.title = /*iconDescription*/ ctx[2];
			if (dirty & /*notificationType*/ 2) switch_instance_changes.class = "bx--" + /*notificationType*/ ctx[1] + "-notification__icon";

			if (switch_value !== (switch_value = /*icons*/ ctx[3][/*kind*/ ctx[0]])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, switch_instance_anchor.parentNode, switch_instance_anchor);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(switch_instance_anchor);
			if (switch_instance) destroy_component(switch_instance, detaching);
		}
	};
}

function instance$j($$self, $$props, $$invalidate) {
	let { kind = "error" } = $$props;
	let { notificationType = "toast" } = $$props;
	let { iconDescription = "Closes notification" } = $$props;

	const icons = {
		error: ErrorFilled,
		"info-square": InformationSquareFilled,
		info: InformationFilled,
		success: CheckmarkFilled,
		warning: WarningFilled,
		"warning-alt": WarningAltFilled
	};

	$$self.$$set = $$props => {
		if ('kind' in $$props) $$invalidate(0, kind = $$props.kind);
		if ('notificationType' in $$props) $$invalidate(1, notificationType = $$props.notificationType);
		if ('iconDescription' in $$props) $$invalidate(2, iconDescription = $$props.iconDescription);
	};

	return [kind, notificationType, iconDescription, icons];
}

class NotificationIcon extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$j, create_fragment$i, safe_not_equal, {
			kind: 0,
			notificationType: 1,
			iconDescription: 2
		});
	}
}

/* node_modules/carbon-components-svelte/src/Notification/ToastNotification.svelte generated by Svelte v3.47.0 */
const get_caption_slot_changes = dirty => ({});
const get_caption_slot_context = ctx => ({});
const get_subtitle_slot_changes = dirty => ({});
const get_subtitle_slot_context = ctx => ({});
const get_title_slot_changes = dirty => ({});
const get_title_slot_context = ctx => ({});

// (62:0) {#if open}
function create_if_block$g(ctx) {
	let div3;
	let notificationicon;
	let t0;
	let div2;
	let h3;
	let t1;
	let div0;
	let t2;
	let div1;
	let t3;
	let t4;
	let current;
	let mounted;
	let dispose;
	notificationicon = new NotificationIcon({ props: { kind: /*kind*/ ctx[0] } });
	const title_slot_template = /*#slots*/ ctx[13].title;
	const title_slot = create_slot(title_slot_template, ctx, /*$$scope*/ ctx[12], get_title_slot_context);
	const title_slot_or_fallback = title_slot || fallback_block_2(ctx);
	const subtitle_slot_template = /*#slots*/ ctx[13].subtitle;
	const subtitle_slot = create_slot(subtitle_slot_template, ctx, /*$$scope*/ ctx[12], get_subtitle_slot_context);
	const subtitle_slot_or_fallback = subtitle_slot || fallback_block_1(ctx);
	const caption_slot_template = /*#slots*/ ctx[13].caption;
	const caption_slot = create_slot(caption_slot_template, ctx, /*$$scope*/ ctx[12], get_caption_slot_context);
	const caption_slot_or_fallback = caption_slot || fallback_block$1(ctx);
	const default_slot_template = /*#slots*/ ctx[13].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[12], null);
	let if_block = !/*hideCloseButton*/ ctx[7] && create_if_block_1$3(ctx);
	let div3_levels = [{ role: /*role*/ ctx[2] }, { kind: /*kind*/ ctx[0] }, /*$$restProps*/ ctx[10]];
	let div3_data = {};

	for (let i = 0; i < div3_levels.length; i += 1) {
		div3_data = assign(div3_data, div3_levels[i]);
	}

	return {
		c() {
			div3 = element("div");
			create_component(notificationicon.$$.fragment);
			t0 = space();
			div2 = element("div");
			h3 = element("h3");
			if (title_slot_or_fallback) title_slot_or_fallback.c();
			t1 = space();
			div0 = element("div");
			if (subtitle_slot_or_fallback) subtitle_slot_or_fallback.c();
			t2 = space();
			div1 = element("div");
			if (caption_slot_or_fallback) caption_slot_or_fallback.c();
			t3 = space();
			if (default_slot) default_slot.c();
			t4 = space();
			if (if_block) if_block.c();
			toggle_class(h3, "bx--toast-notification__title", true);
			toggle_class(div0, "bx--toast-notification__subtitle", true);
			toggle_class(div1, "bx--toast-notification__caption", true);
			toggle_class(div2, "bx--toast-notification__details", true);
			set_attributes(div3, div3_data);
			toggle_class(div3, "bx--toast-notification", true);
			toggle_class(div3, "bx--toast-notification--low-contrast", /*lowContrast*/ ctx[1]);
			toggle_class(div3, "bx--toast-notification--error", /*kind*/ ctx[0] === 'error');
			toggle_class(div3, "bx--toast-notification--info", /*kind*/ ctx[0] === 'info');
			toggle_class(div3, "bx--toast-notification--info-square", /*kind*/ ctx[0] === 'info-square');
			toggle_class(div3, "bx--toast-notification--success", /*kind*/ ctx[0] === 'success');
			toggle_class(div3, "bx--toast-notification--warning", /*kind*/ ctx[0] === 'warning');
			toggle_class(div3, "bx--toast-notification--warning-alt", /*kind*/ ctx[0] === 'warning-alt');
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			mount_component(notificationicon, div3, null);
			append(div3, t0);
			append(div3, div2);
			append(div2, h3);

			if (title_slot_or_fallback) {
				title_slot_or_fallback.m(h3, null);
			}

			append(div2, t1);
			append(div2, div0);

			if (subtitle_slot_or_fallback) {
				subtitle_slot_or_fallback.m(div0, null);
			}

			append(div2, t2);
			append(div2, div1);

			if (caption_slot_or_fallback) {
				caption_slot_or_fallback.m(div1, null);
			}

			append(div2, t3);

			if (default_slot) {
				default_slot.m(div2, null);
			}

			append(div3, t4);
			if (if_block) if_block.m(div3, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div3, "click", /*click_handler*/ ctx[14]),
					listen(div3, "mouseover", /*mouseover_handler*/ ctx[15]),
					listen(div3, "mouseenter", /*mouseenter_handler*/ ctx[16]),
					listen(div3, "mouseleave", /*mouseleave_handler*/ ctx[17])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			const notificationicon_changes = {};
			if (dirty & /*kind*/ 1) notificationicon_changes.kind = /*kind*/ ctx[0];
			notificationicon.$set(notificationicon_changes);

			if (title_slot) {
				if (title_slot.p && (!current || dirty & /*$$scope*/ 4096)) {
					update_slot_base(
						title_slot,
						title_slot_template,
						ctx,
						/*$$scope*/ ctx[12],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[12])
						: get_slot_changes(title_slot_template, /*$$scope*/ ctx[12], dirty, get_title_slot_changes),
						get_title_slot_context
					);
				}
			} else {
				if (title_slot_or_fallback && title_slot_or_fallback.p && (!current || dirty & /*title*/ 8)) {
					title_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (subtitle_slot) {
				if (subtitle_slot.p && (!current || dirty & /*$$scope*/ 4096)) {
					update_slot_base(
						subtitle_slot,
						subtitle_slot_template,
						ctx,
						/*$$scope*/ ctx[12],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[12])
						: get_slot_changes(subtitle_slot_template, /*$$scope*/ ctx[12], dirty, get_subtitle_slot_changes),
						get_subtitle_slot_context
					);
				}
			} else {
				if (subtitle_slot_or_fallback && subtitle_slot_or_fallback.p && (!current || dirty & /*subtitle*/ 16)) {
					subtitle_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (caption_slot) {
				if (caption_slot.p && (!current || dirty & /*$$scope*/ 4096)) {
					update_slot_base(
						caption_slot,
						caption_slot_template,
						ctx,
						/*$$scope*/ ctx[12],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[12])
						: get_slot_changes(caption_slot_template, /*$$scope*/ ctx[12], dirty, get_caption_slot_changes),
						get_caption_slot_context
					);
				}
			} else {
				if (caption_slot_or_fallback && caption_slot_or_fallback.p && (!current || dirty & /*caption*/ 32)) {
					caption_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4096)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[12],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[12])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[12], dirty, null),
						null
					);
				}
			}

			if (!/*hideCloseButton*/ ctx[7]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*hideCloseButton*/ 128) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_1$3(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div3, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			set_attributes(div3, div3_data = get_spread_update(div3_levels, [
				(!current || dirty & /*role*/ 4) && { role: /*role*/ ctx[2] },
				(!current || dirty & /*kind*/ 1) && { kind: /*kind*/ ctx[0] },
				dirty & /*$$restProps*/ 1024 && /*$$restProps*/ ctx[10]
			]));

			toggle_class(div3, "bx--toast-notification", true);
			toggle_class(div3, "bx--toast-notification--low-contrast", /*lowContrast*/ ctx[1]);
			toggle_class(div3, "bx--toast-notification--error", /*kind*/ ctx[0] === 'error');
			toggle_class(div3, "bx--toast-notification--info", /*kind*/ ctx[0] === 'info');
			toggle_class(div3, "bx--toast-notification--info-square", /*kind*/ ctx[0] === 'info-square');
			toggle_class(div3, "bx--toast-notification--success", /*kind*/ ctx[0] === 'success');
			toggle_class(div3, "bx--toast-notification--warning", /*kind*/ ctx[0] === 'warning');
			toggle_class(div3, "bx--toast-notification--warning-alt", /*kind*/ ctx[0] === 'warning-alt');
		},
		i(local) {
			if (current) return;
			transition_in(notificationicon.$$.fragment, local);
			transition_in(title_slot_or_fallback, local);
			transition_in(subtitle_slot_or_fallback, local);
			transition_in(caption_slot_or_fallback, local);
			transition_in(default_slot, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(notificationicon.$$.fragment, local);
			transition_out(title_slot_or_fallback, local);
			transition_out(subtitle_slot_or_fallback, local);
			transition_out(caption_slot_or_fallback, local);
			transition_out(default_slot, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			destroy_component(notificationicon);
			if (title_slot_or_fallback) title_slot_or_fallback.d(detaching);
			if (subtitle_slot_or_fallback) subtitle_slot_or_fallback.d(detaching);
			if (caption_slot_or_fallback) caption_slot_or_fallback.d(detaching);
			if (default_slot) default_slot.d(detaching);
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (83:27) {title}
function fallback_block_2(ctx) {
	let t;

	return {
		c() {
			t = text(/*title*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 8) set_data(t, /*title*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (86:30) {subtitle}
function fallback_block_1(ctx) {
	let t;

	return {
		c() {
			t = text(/*subtitle*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*subtitle*/ 16) set_data(t, /*subtitle*/ ctx[4]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (89:29) {caption}
function fallback_block$1(ctx) {
	let t;

	return {
		c() {
			t = text(/*caption*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*caption*/ 32) set_data(t, /*caption*/ ctx[5]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (93:4) {#if !hideCloseButton}
function create_if_block_1$3(ctx) {
	let notificationbutton;
	let current;

	notificationbutton = new NotificationButton({
			props: {
				iconDescription: /*iconDescription*/ ctx[6]
			}
		});

	notificationbutton.$on("click", /*close*/ ctx[9]);

	return {
		c() {
			create_component(notificationbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(notificationbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const notificationbutton_changes = {};
			if (dirty & /*iconDescription*/ 64) notificationbutton_changes.iconDescription = /*iconDescription*/ ctx[6];
			notificationbutton.$set(notificationbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(notificationbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(notificationbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(notificationbutton, detaching);
		}
	};
}

function create_fragment$j(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*open*/ ctx[8] && create_if_block$g(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*open*/ ctx[8]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*open*/ 256) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$g(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$k($$self, $$props, $$invalidate) {
	const omit_props_names = [
		"kind","lowContrast","timeout","role","title","subtitle","caption","iconDescription","hideCloseButton"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { kind = "error" } = $$props;
	let { lowContrast = false } = $$props;
	let { timeout = 0 } = $$props;
	let { role = "alert" } = $$props;
	let { title = "" } = $$props;
	let { subtitle = "" } = $$props;
	let { caption = "" } = $$props;
	let { iconDescription = "Closes notification" } = $$props;
	let { hideCloseButton = false } = $$props;
	const dispatch = createEventDispatcher();
	let open = true;
	let timeoutId = undefined;

	function close(closeFromTimeout) {
		$$invalidate(8, open = false);
		dispatch("close", { timeout: closeFromTimeout === true });
	}

	onMount(() => {
		if (timeout) {
			timeoutId = setTimeout(() => close(true), timeout);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	});

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(10, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('kind' in $$new_props) $$invalidate(0, kind = $$new_props.kind);
		if ('lowContrast' in $$new_props) $$invalidate(1, lowContrast = $$new_props.lowContrast);
		if ('timeout' in $$new_props) $$invalidate(11, timeout = $$new_props.timeout);
		if ('role' in $$new_props) $$invalidate(2, role = $$new_props.role);
		if ('title' in $$new_props) $$invalidate(3, title = $$new_props.title);
		if ('subtitle' in $$new_props) $$invalidate(4, subtitle = $$new_props.subtitle);
		if ('caption' in $$new_props) $$invalidate(5, caption = $$new_props.caption);
		if ('iconDescription' in $$new_props) $$invalidate(6, iconDescription = $$new_props.iconDescription);
		if ('hideCloseButton' in $$new_props) $$invalidate(7, hideCloseButton = $$new_props.hideCloseButton);
		if ('$$scope' in $$new_props) $$invalidate(12, $$scope = $$new_props.$$scope);
	};

	return [
		kind,
		lowContrast,
		role,
		title,
		subtitle,
		caption,
		iconDescription,
		hideCloseButton,
		open,
		close,
		$$restProps,
		timeout,
		$$scope,
		slots,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler
	];
}

class ToastNotification extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$k, create_fragment$j, safe_not_equal, {
			kind: 0,
			lowContrast: 1,
			timeout: 11,
			role: 2,
			title: 3,
			subtitle: 4,
			caption: 5,
			iconDescription: 6,
			hideCloseButton: 7
		});
	}
}

/* node_modules/carbon-components-svelte/src/Notification/InlineNotification.svelte generated by Svelte v3.47.0 */
const get_actions_slot_changes = dirty => ({});
const get_actions_slot_context = ctx => ({});
const get_subtitle_slot_changes$1 = dirty => ({});
const get_subtitle_slot_context$1 = ctx => ({});
const get_title_slot_changes$1 = dirty => ({});
const get_title_slot_context$1 = ctx => ({});

// (59:0) {#if open}
function create_if_block$h(ctx) {
	let div3;
	let div2;
	let notificationicon;
	let t0;
	let div1;
	let p;
	let t1;
	let div0;
	let t2;
	let t3;
	let t4;
	let current;
	let mounted;
	let dispose;

	notificationicon = new NotificationIcon({
			props: {
				notificationType: "inline",
				kind: /*kind*/ ctx[0]
			}
		});

	const title_slot_template = /*#slots*/ ctx[12].title;
	const title_slot = create_slot(title_slot_template, ctx, /*$$scope*/ ctx[11], get_title_slot_context$1);
	const title_slot_or_fallback = title_slot || fallback_block_1$1(ctx);
	const subtitle_slot_template = /*#slots*/ ctx[12].subtitle;
	const subtitle_slot = create_slot(subtitle_slot_template, ctx, /*$$scope*/ ctx[11], get_subtitle_slot_context$1);
	const subtitle_slot_or_fallback = subtitle_slot || fallback_block$2(ctx);
	const default_slot_template = /*#slots*/ ctx[12].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[11], null);
	const actions_slot_template = /*#slots*/ ctx[12].actions;
	const actions_slot = create_slot(actions_slot_template, ctx, /*$$scope*/ ctx[11], get_actions_slot_context);
	let if_block = !/*hideCloseButton*/ ctx[5] && create_if_block_1$4(ctx);
	let div3_levels = [{ role: /*role*/ ctx[2] }, { kind: /*kind*/ ctx[0] }, /*$$restProps*/ ctx[9]];
	let div3_data = {};

	for (let i = 0; i < div3_levels.length; i += 1) {
		div3_data = assign(div3_data, div3_levels[i]);
	}

	return {
		c() {
			div3 = element("div");
			div2 = element("div");
			create_component(notificationicon.$$.fragment);
			t0 = space();
			div1 = element("div");
			p = element("p");
			if (title_slot_or_fallback) title_slot_or_fallback.c();
			t1 = space();
			div0 = element("div");
			if (subtitle_slot_or_fallback) subtitle_slot_or_fallback.c();
			t2 = space();
			if (default_slot) default_slot.c();
			t3 = space();
			if (actions_slot) actions_slot.c();
			t4 = space();
			if (if_block) if_block.c();
			toggle_class(p, "bx--inline-notification__title", true);
			toggle_class(div0, "bx--inline-notification__subtitle", true);
			toggle_class(div1, "bx--inline-notification__text-wrapper", true);
			toggle_class(div2, "bx--inline-notification__details", true);
			set_attributes(div3, div3_data);
			toggle_class(div3, "bx--inline-notification", true);
			toggle_class(div3, "bx--inline-notification--low-contrast", /*lowContrast*/ ctx[1]);
			toggle_class(div3, "bx--inline-notification--hide-close-button", /*hideCloseButton*/ ctx[5]);
			toggle_class(div3, "bx--inline-notification--error", /*kind*/ ctx[0] === 'error');
			toggle_class(div3, "bx--inline-notification--info", /*kind*/ ctx[0] === 'info');
			toggle_class(div3, "bx--inline-notification--info-square", /*kind*/ ctx[0] === 'info-square');
			toggle_class(div3, "bx--inline-notification--success", /*kind*/ ctx[0] === 'success');
			toggle_class(div3, "bx--inline-notification--warning", /*kind*/ ctx[0] === 'warning');
			toggle_class(div3, "bx--inline-notification--warning-alt", /*kind*/ ctx[0] === 'warning-alt');
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div2);
			mount_component(notificationicon, div2, null);
			append(div2, t0);
			append(div2, div1);
			append(div1, p);

			if (title_slot_or_fallback) {
				title_slot_or_fallback.m(p, null);
			}

			append(div1, t1);
			append(div1, div0);

			if (subtitle_slot_or_fallback) {
				subtitle_slot_or_fallback.m(div0, null);
			}

			append(div1, t2);

			if (default_slot) {
				default_slot.m(div1, null);
			}

			append(div3, t3);

			if (actions_slot) {
				actions_slot.m(div3, null);
			}

			append(div3, t4);
			if (if_block) if_block.m(div3, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div3, "click", /*click_handler*/ ctx[13]),
					listen(div3, "mouseover", /*mouseover_handler*/ ctx[14]),
					listen(div3, "mouseenter", /*mouseenter_handler*/ ctx[15]),
					listen(div3, "mouseleave", /*mouseleave_handler*/ ctx[16])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			const notificationicon_changes = {};
			if (dirty & /*kind*/ 1) notificationicon_changes.kind = /*kind*/ ctx[0];
			notificationicon.$set(notificationicon_changes);

			if (title_slot) {
				if (title_slot.p && (!current || dirty & /*$$scope*/ 2048)) {
					update_slot_base(
						title_slot,
						title_slot_template,
						ctx,
						/*$$scope*/ ctx[11],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[11])
						: get_slot_changes(title_slot_template, /*$$scope*/ ctx[11], dirty, get_title_slot_changes$1),
						get_title_slot_context$1
					);
				}
			} else {
				if (title_slot_or_fallback && title_slot_or_fallback.p && (!current || dirty & /*title*/ 8)) {
					title_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (subtitle_slot) {
				if (subtitle_slot.p && (!current || dirty & /*$$scope*/ 2048)) {
					update_slot_base(
						subtitle_slot,
						subtitle_slot_template,
						ctx,
						/*$$scope*/ ctx[11],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[11])
						: get_slot_changes(subtitle_slot_template, /*$$scope*/ ctx[11], dirty, get_subtitle_slot_changes$1),
						get_subtitle_slot_context$1
					);
				}
			} else {
				if (subtitle_slot_or_fallback && subtitle_slot_or_fallback.p && (!current || dirty & /*subtitle*/ 16)) {
					subtitle_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 2048)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[11],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[11])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[11], dirty, null),
						null
					);
				}
			}

			if (actions_slot) {
				if (actions_slot.p && (!current || dirty & /*$$scope*/ 2048)) {
					update_slot_base(
						actions_slot,
						actions_slot_template,
						ctx,
						/*$$scope*/ ctx[11],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[11])
						: get_slot_changes(actions_slot_template, /*$$scope*/ ctx[11], dirty, get_actions_slot_changes),
						get_actions_slot_context
					);
				}
			}

			if (!/*hideCloseButton*/ ctx[5]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*hideCloseButton*/ 32) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_1$4(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div3, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			set_attributes(div3, div3_data = get_spread_update(div3_levels, [
				(!current || dirty & /*role*/ 4) && { role: /*role*/ ctx[2] },
				(!current || dirty & /*kind*/ 1) && { kind: /*kind*/ ctx[0] },
				dirty & /*$$restProps*/ 512 && /*$$restProps*/ ctx[9]
			]));

			toggle_class(div3, "bx--inline-notification", true);
			toggle_class(div3, "bx--inline-notification--low-contrast", /*lowContrast*/ ctx[1]);
			toggle_class(div3, "bx--inline-notification--hide-close-button", /*hideCloseButton*/ ctx[5]);
			toggle_class(div3, "bx--inline-notification--error", /*kind*/ ctx[0] === 'error');
			toggle_class(div3, "bx--inline-notification--info", /*kind*/ ctx[0] === 'info');
			toggle_class(div3, "bx--inline-notification--info-square", /*kind*/ ctx[0] === 'info-square');
			toggle_class(div3, "bx--inline-notification--success", /*kind*/ ctx[0] === 'success');
			toggle_class(div3, "bx--inline-notification--warning", /*kind*/ ctx[0] === 'warning');
			toggle_class(div3, "bx--inline-notification--warning-alt", /*kind*/ ctx[0] === 'warning-alt');
		},
		i(local) {
			if (current) return;
			transition_in(notificationicon.$$.fragment, local);
			transition_in(title_slot_or_fallback, local);
			transition_in(subtitle_slot_or_fallback, local);
			transition_in(default_slot, local);
			transition_in(actions_slot, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(notificationicon.$$.fragment, local);
			transition_out(title_slot_or_fallback, local);
			transition_out(subtitle_slot_or_fallback, local);
			transition_out(default_slot, local);
			transition_out(actions_slot, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			destroy_component(notificationicon);
			if (title_slot_or_fallback) title_slot_or_fallback.d(detaching);
			if (subtitle_slot_or_fallback) subtitle_slot_or_fallback.d(detaching);
			if (default_slot) default_slot.d(detaching);
			if (actions_slot) actions_slot.d(detaching);
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (82:29) {title}
function fallback_block_1$1(ctx) {
	let t;

	return {
		c() {
			t = text(/*title*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 8) set_data(t, /*title*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (85:32) {subtitle}
function fallback_block$2(ctx) {
	let t;

	return {
		c() {
			t = text(/*subtitle*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*subtitle*/ 16) set_data(t, /*subtitle*/ ctx[4]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (91:4) {#if !hideCloseButton}
function create_if_block_1$4(ctx) {
	let notificationbutton;
	let current;

	notificationbutton = new NotificationButton({
			props: {
				iconDescription: /*iconDescription*/ ctx[6],
				notificationType: "inline"
			}
		});

	notificationbutton.$on("click", /*close*/ ctx[8]);

	return {
		c() {
			create_component(notificationbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(notificationbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const notificationbutton_changes = {};
			if (dirty & /*iconDescription*/ 64) notificationbutton_changes.iconDescription = /*iconDescription*/ ctx[6];
			notificationbutton.$set(notificationbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(notificationbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(notificationbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(notificationbutton, detaching);
		}
	};
}

function create_fragment$k(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*open*/ ctx[7] && create_if_block$h(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*open*/ ctx[7]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*open*/ 128) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$h(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$l($$self, $$props, $$invalidate) {
	const omit_props_names = [
		"kind","lowContrast","timeout","role","title","subtitle","hideCloseButton","iconDescription"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { kind = "error" } = $$props;
	let { lowContrast = false } = $$props;
	let { timeout = 0 } = $$props;
	let { role = "alert" } = $$props;
	let { title = "" } = $$props;
	let { subtitle = "" } = $$props;
	let { hideCloseButton = false } = $$props;
	let { iconDescription = "Closes notification" } = $$props;
	const dispatch = createEventDispatcher();
	let open = true;
	let timeoutId = undefined;

	function close(closeFromTimeout) {
		$$invalidate(7, open = false);
		dispatch("close", { timeout: closeFromTimeout === true });
	}

	onMount(() => {
		if (timeout) {
			timeoutId = setTimeout(() => close(true), timeout);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	});

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(9, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('kind' in $$new_props) $$invalidate(0, kind = $$new_props.kind);
		if ('lowContrast' in $$new_props) $$invalidate(1, lowContrast = $$new_props.lowContrast);
		if ('timeout' in $$new_props) $$invalidate(10, timeout = $$new_props.timeout);
		if ('role' in $$new_props) $$invalidate(2, role = $$new_props.role);
		if ('title' in $$new_props) $$invalidate(3, title = $$new_props.title);
		if ('subtitle' in $$new_props) $$invalidate(4, subtitle = $$new_props.subtitle);
		if ('hideCloseButton' in $$new_props) $$invalidate(5, hideCloseButton = $$new_props.hideCloseButton);
		if ('iconDescription' in $$new_props) $$invalidate(6, iconDescription = $$new_props.iconDescription);
		if ('$$scope' in $$new_props) $$invalidate(11, $$scope = $$new_props.$$scope);
	};

	return [
		kind,
		lowContrast,
		role,
		title,
		subtitle,
		hideCloseButton,
		iconDescription,
		open,
		close,
		$$restProps,
		timeout,
		$$scope,
		slots,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler
	];
}

class InlineNotification extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$l, create_fragment$k, safe_not_equal, {
			kind: 0,
			lowContrast: 1,
			timeout: 10,
			role: 2,
			title: 3,
			subtitle: 4,
			hideCloseButton: 5,
			iconDescription: 6
		});
	}
}

/* node_modules/carbon-components-svelte/src/icons/EditOff.svelte generated by Svelte v3.47.0 */

function create_if_block$i(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$l(ctx) {
	let svg;
	let path;
	let if_block = /*title*/ ctx[1] && create_if_block$i(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path = svg_element("path");
			attr(path, "d", "M30 28.6L3.4 2 2 3.4l10.1 10.1L4 21.6V28h6.4l8.1-8.1L28.6 30 30 28.6zM9.6 26H6v-3.6l7.5-7.5 3.6 3.6L9.6 26zM29.4 6.2L29.4 6.2l-3.6-3.6c-.8-.8-2-.8-2.8 0l0 0 0 0-8 8 1.4 1.4L20 8.4l3.6 3.6L20 15.6l1.4 1.4 8-8C30.2 8.2 30.2 7 29.4 6.2L29.4 6.2zM25 10.6L21.4 7l3-3L28 7.6 25 10.6z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$i(ctx);
					if_block.c();
					if_block.m(svg, path);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$m($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class EditOff extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$m, create_fragment$l, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/Select/Select.svelte generated by Svelte v3.47.0 */
const get_labelText_slot_changes$1 = dirty => ({});
const get_labelText_slot_context$1 = ctx => ({});

// (95:4) {#if !noLabel}
function create_if_block_10(ctx) {
	let label;
	let current;
	const labelText_slot_template = /*#slots*/ ctx[21].labelText;
	const labelText_slot = create_slot(labelText_slot_template, ctx, /*$$scope*/ ctx[20], get_labelText_slot_context$1);
	const labelText_slot_or_fallback = labelText_slot || fallback_block$3(ctx);

	return {
		c() {
			label = element("label");
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.c();
			attr(label, "for", /*id*/ ctx[5]);
			toggle_class(label, "bx--label", true);
			toggle_class(label, "bx--visually-hidden", /*hideLabel*/ ctx[14]);
			toggle_class(label, "bx--label--disabled", /*disabled*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, label, anchor);

			if (labelText_slot_or_fallback) {
				labelText_slot_or_fallback.m(label, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (labelText_slot) {
				if (labelText_slot.p && (!current || dirty[0] & /*$$scope*/ 1048576)) {
					update_slot_base(
						labelText_slot,
						labelText_slot_template,
						ctx,
						/*$$scope*/ ctx[20],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[20])
						: get_slot_changes(labelText_slot_template, /*$$scope*/ ctx[20], dirty, get_labelText_slot_changes$1),
						get_labelText_slot_context$1
					);
				}
			} else {
				if (labelText_slot_or_fallback && labelText_slot_or_fallback.p && (!current || dirty[0] & /*labelText*/ 8192)) {
					labelText_slot_or_fallback.p(ctx, !current ? [-1, -1] : dirty);
				}
			}

			if (!current || dirty[0] & /*id*/ 32) {
				attr(label, "for", /*id*/ ctx[5]);
			}

			if (dirty[0] & /*hideLabel*/ 16384) {
				toggle_class(label, "bx--visually-hidden", /*hideLabel*/ ctx[14]);
			}

			if (dirty[0] & /*disabled*/ 16) {
				toggle_class(label, "bx--label--disabled", /*disabled*/ ctx[4]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(labelText_slot_or_fallback, local);
			current = true;
		},
		o(local) {
			transition_out(labelText_slot_or_fallback, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(label);
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.d(detaching);
		}
	};
}

// (102:31)            
function fallback_block$3(ctx) {
	let t;

	return {
		c() {
			t = text(/*labelText*/ ctx[13]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*labelText*/ 8192) set_data(t, /*labelText*/ ctx[13]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (107:4) {#if inline}
function create_if_block_6(ctx) {
	let div1;
	let div0;
	let select;
	let select_aria_describedby_value;
	let select_aria_invalid_value;
	let select_disabled_value;
	let select_required_value;
	let select_class_value;
	let t0;
	let chevrondown;
	let t1;
	let div0_data_invalid_value;
	let t2;
	let t3;
	let if_block2_anchor;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[21].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[20], null);
	chevrondown = new ChevronDown({ props: { class: "bx--select__arrow" } });
	let if_block0 = /*invalid*/ ctx[7] && create_if_block_9();
	let if_block1 = /*invalid*/ ctx[7] && create_if_block_8(ctx);
	let if_block2 = /*helperText*/ ctx[11] && create_if_block_7(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			select = element("select");
			if (default_slot) default_slot.c();
			t0 = space();
			create_component(chevrondown.$$.fragment);
			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();
			if (if_block2) if_block2.c();
			if_block2_anchor = empty();
			attr(select, "aria-describedby", select_aria_describedby_value = /*invalid*/ ctx[7] ? /*errorId*/ ctx[16] : undefined);
			attr(select, "aria-invalid", select_aria_invalid_value = /*invalid*/ ctx[7] || undefined);
			select.disabled = select_disabled_value = /*disabled*/ ctx[4] || undefined;
			select.required = select_required_value = /*required*/ ctx[15] || undefined;
			attr(select, "id", /*id*/ ctx[5]);
			attr(select, "name", /*name*/ ctx[6]);
			attr(select, "class", select_class_value = /*size*/ ctx[1] && `bx--select-input--${/*size*/ ctx[1]}`);
			toggle_class(select, "bx--select-input", true);
			attr(div0, "data-invalid", div0_data_invalid_value = /*invalid*/ ctx[7] || undefined);
			toggle_class(div0, "bx--select-input__wrapper", true);
			toggle_class(div1, "bx--select-input--inline__wrapper", true);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, select);

			if (default_slot) {
				default_slot.m(select, null);
			}

			/*select_binding*/ ctx[28](select);
			append(div0, t0);
			mount_component(chevrondown, div0, null);
			append(div0, t1);
			if (if_block0) if_block0.m(div0, null);
			append(div1, t2);
			if (if_block1) if_block1.m(div1, null);
			insert(target, t3, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert(target, if_block2_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(select, "change", /*change_handler*/ ctx[29]),
					listen(select, "input", /*input_handler*/ ctx[25]),
					listen(select, "focus", /*focus_handler*/ ctx[26]),
					listen(select, "blur", /*blur_handler*/ ctx[27])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty[0] & /*$$scope*/ 1048576)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[20],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[20])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[20], dirty, null),
						null
					);
				}
			}

			if (!current || dirty[0] & /*invalid, errorId*/ 65664 && select_aria_describedby_value !== (select_aria_describedby_value = /*invalid*/ ctx[7] ? /*errorId*/ ctx[16] : undefined)) {
				attr(select, "aria-describedby", select_aria_describedby_value);
			}

			if (!current || dirty[0] & /*invalid*/ 128 && select_aria_invalid_value !== (select_aria_invalid_value = /*invalid*/ ctx[7] || undefined)) {
				attr(select, "aria-invalid", select_aria_invalid_value);
			}

			if (!current || dirty[0] & /*disabled*/ 16 && select_disabled_value !== (select_disabled_value = /*disabled*/ ctx[4] || undefined)) {
				select.disabled = select_disabled_value;
			}

			if (!current || dirty[0] & /*required*/ 32768 && select_required_value !== (select_required_value = /*required*/ ctx[15] || undefined)) {
				select.required = select_required_value;
			}

			if (!current || dirty[0] & /*id*/ 32) {
				attr(select, "id", /*id*/ ctx[5]);
			}

			if (!current || dirty[0] & /*name*/ 64) {
				attr(select, "name", /*name*/ ctx[6]);
			}

			if (!current || dirty[0] & /*size*/ 2 && select_class_value !== (select_class_value = /*size*/ ctx[1] && `bx--select-input--${/*size*/ ctx[1]}`)) {
				attr(select, "class", select_class_value);
			}

			if (dirty[0] & /*size*/ 2) {
				toggle_class(select, "bx--select-input", true);
			}

			if (/*invalid*/ ctx[7]) {
				if (if_block0) {
					if (dirty[0] & /*invalid*/ 128) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_9();
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div0, null);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*invalid*/ 128 && div0_data_invalid_value !== (div0_data_invalid_value = /*invalid*/ ctx[7] || undefined)) {
				attr(div0, "data-invalid", div0_data_invalid_value);
			}

			if (/*invalid*/ ctx[7]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_8(ctx);
					if_block1.c();
					if_block1.m(div1, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*helperText*/ ctx[11]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_7(ctx);
					if_block2.c();
					if_block2.m(if_block2_anchor.parentNode, if_block2_anchor);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			transition_in(chevrondown.$$.fragment, local);
			transition_in(if_block0);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			transition_out(chevrondown.$$.fragment, local);
			transition_out(if_block0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (default_slot) default_slot.d(detaching);
			/*select_binding*/ ctx[28](null);
			destroy_component(chevrondown);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (detaching) detach(t3);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach(if_block2_anchor);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (133:10) {#if invalid}
function create_if_block_9(ctx) {
	let warningfilled;
	let current;

	warningfilled = new WarningFilled({
			props: { class: "bx--select__invalid-icon" }
		});

	return {
		c() {
			create_component(warningfilled.$$.fragment);
		},
		m(target, anchor) {
			mount_component(warningfilled, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(warningfilled.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(warningfilled.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(warningfilled, detaching);
		}
	};
}

// (137:8) {#if invalid}
function create_if_block_8(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*invalidText*/ ctx[8]);
			attr(div, "id", /*errorId*/ ctx[16]);
			toggle_class(div, "bx--form-requirement", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*invalidText*/ 256) set_data(t, /*invalidText*/ ctx[8]);

			if (dirty[0] & /*errorId*/ 65536) {
				attr(div, "id", /*errorId*/ ctx[16]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (143:6) {#if helperText}
function create_if_block_7(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*helperText*/ ctx[11]);
			toggle_class(div, "bx--form__helper-text", true);
			toggle_class(div, "bx--form__helper-text--disabled", /*disabled*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*helperText*/ 2048) set_data(t, /*helperText*/ ctx[11]);

			if (dirty[0] & /*disabled*/ 16) {
				toggle_class(div, "bx--form__helper-text--disabled", /*disabled*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (152:4) {#if !inline}
function create_if_block$j(ctx) {
	let div;
	let select;
	let select_aria_describedby_value;
	let select_disabled_value;
	let select_required_value;
	let select_aria_invalid_value;
	let select_class_value;
	let t0;
	let chevrondown;
	let t1;
	let t2;
	let div_data_invalid_value;
	let t3;
	let t4;
	let t5;
	let if_block4_anchor;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[21].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[20], null);
	chevrondown = new ChevronDown({ props: { class: "bx--select__arrow" } });
	let if_block0 = /*invalid*/ ctx[7] && create_if_block_5();
	let if_block1 = !/*invalid*/ ctx[7] && /*warn*/ ctx[9] && create_if_block_4$1();
	let if_block2 = !/*invalid*/ ctx[7] && /*helperText*/ ctx[11] && create_if_block_3$2(ctx);
	let if_block3 = /*invalid*/ ctx[7] && create_if_block_2$3(ctx);
	let if_block4 = !/*invalid*/ ctx[7] && /*warn*/ ctx[9] && create_if_block_1$5(ctx);

	return {
		c() {
			div = element("div");
			select = element("select");
			if (default_slot) default_slot.c();
			t0 = space();
			create_component(chevrondown.$$.fragment);
			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();
			if (if_block2) if_block2.c();
			t4 = space();
			if (if_block3) if_block3.c();
			t5 = space();
			if (if_block4) if_block4.c();
			if_block4_anchor = empty();
			attr(select, "id", /*id*/ ctx[5]);
			attr(select, "name", /*name*/ ctx[6]);
			attr(select, "aria-describedby", select_aria_describedby_value = /*invalid*/ ctx[7] ? /*errorId*/ ctx[16] : undefined);
			select.disabled = select_disabled_value = /*disabled*/ ctx[4] || undefined;
			select.required = select_required_value = /*required*/ ctx[15] || undefined;
			attr(select, "aria-invalid", select_aria_invalid_value = /*invalid*/ ctx[7] || undefined);
			attr(select, "class", select_class_value = /*size*/ ctx[1] && `bx--select-input--${/*size*/ ctx[1]}`);
			toggle_class(select, "bx--select-input", true);
			attr(div, "data-invalid", div_data_invalid_value = /*invalid*/ ctx[7] || undefined);
			toggle_class(div, "bx--select-input__wrapper", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, select);

			if (default_slot) {
				default_slot.m(select, null);
			}

			/*select_binding_1*/ ctx[30](select);
			append(div, t0);
			mount_component(chevrondown, div, null);
			append(div, t1);
			if (if_block0) if_block0.m(div, null);
			append(div, t2);
			if (if_block1) if_block1.m(div, null);
			insert(target, t3, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert(target, t4, anchor);
			if (if_block3) if_block3.m(target, anchor);
			insert(target, t5, anchor);
			if (if_block4) if_block4.m(target, anchor);
			insert(target, if_block4_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(select, "change", /*change_handler_1*/ ctx[31]),
					listen(select, "input", /*input_handler_1*/ ctx[22]),
					listen(select, "focus", /*focus_handler_1*/ ctx[23]),
					listen(select, "blur", /*blur_handler_1*/ ctx[24])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty[0] & /*$$scope*/ 1048576)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[20],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[20])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[20], dirty, null),
						null
					);
				}
			}

			if (!current || dirty[0] & /*id*/ 32) {
				attr(select, "id", /*id*/ ctx[5]);
			}

			if (!current || dirty[0] & /*name*/ 64) {
				attr(select, "name", /*name*/ ctx[6]);
			}

			if (!current || dirty[0] & /*invalid, errorId*/ 65664 && select_aria_describedby_value !== (select_aria_describedby_value = /*invalid*/ ctx[7] ? /*errorId*/ ctx[16] : undefined)) {
				attr(select, "aria-describedby", select_aria_describedby_value);
			}

			if (!current || dirty[0] & /*disabled*/ 16 && select_disabled_value !== (select_disabled_value = /*disabled*/ ctx[4] || undefined)) {
				select.disabled = select_disabled_value;
			}

			if (!current || dirty[0] & /*required*/ 32768 && select_required_value !== (select_required_value = /*required*/ ctx[15] || undefined)) {
				select.required = select_required_value;
			}

			if (!current || dirty[0] & /*invalid*/ 128 && select_aria_invalid_value !== (select_aria_invalid_value = /*invalid*/ ctx[7] || undefined)) {
				attr(select, "aria-invalid", select_aria_invalid_value);
			}

			if (!current || dirty[0] & /*size*/ 2 && select_class_value !== (select_class_value = /*size*/ ctx[1] && `bx--select-input--${/*size*/ ctx[1]}`)) {
				attr(select, "class", select_class_value);
			}

			if (dirty[0] & /*size*/ 2) {
				toggle_class(select, "bx--select-input", true);
			}

			if (/*invalid*/ ctx[7]) {
				if (if_block0) {
					if (dirty[0] & /*invalid*/ 128) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_5();
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div, t2);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (!/*invalid*/ ctx[7] && /*warn*/ ctx[9]) {
				if (if_block1) {
					if (dirty[0] & /*invalid, warn*/ 640) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_4$1();
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*invalid*/ 128 && div_data_invalid_value !== (div_data_invalid_value = /*invalid*/ ctx[7] || undefined)) {
				attr(div, "data-invalid", div_data_invalid_value);
			}

			if (!/*invalid*/ ctx[7] && /*helperText*/ ctx[11]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_3$2(ctx);
					if_block2.c();
					if_block2.m(t4.parentNode, t4);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*invalid*/ ctx[7]) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block_2$3(ctx);
					if_block3.c();
					if_block3.m(t5.parentNode, t5);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (!/*invalid*/ ctx[7] && /*warn*/ ctx[9]) {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_1$5(ctx);
					if_block4.c();
					if_block4.m(if_block4_anchor.parentNode, if_block4_anchor);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			transition_in(chevrondown.$$.fragment, local);
			transition_in(if_block0);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			transition_out(chevrondown.$$.fragment, local);
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
			/*select_binding_1*/ ctx[30](null);
			destroy_component(chevrondown);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (detaching) detach(t3);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach(t4);
			if (if_block3) if_block3.d(detaching);
			if (detaching) detach(t5);
			if (if_block4) if_block4.d(detaching);
			if (detaching) detach(if_block4_anchor);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (177:8) {#if invalid}
function create_if_block_5(ctx) {
	let warningfilled;
	let current;

	warningfilled = new WarningFilled({
			props: { class: "bx--select__invalid-icon" }
		});

	return {
		c() {
			create_component(warningfilled.$$.fragment);
		},
		m(target, anchor) {
			mount_component(warningfilled, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(warningfilled.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(warningfilled.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(warningfilled, detaching);
		}
	};
}

// (180:8) {#if !invalid && warn}
function create_if_block_4$1(ctx) {
	let warningaltfilled;
	let current;

	warningaltfilled = new WarningAltFilled({
			props: {
				class: "bx--select__invalid-icon bx--select__invalid-icon--warning"
			}
		});

	return {
		c() {
			create_component(warningaltfilled.$$.fragment);
		},
		m(target, anchor) {
			mount_component(warningaltfilled, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(warningaltfilled.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(warningaltfilled.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(warningaltfilled, detaching);
		}
	};
}

// (186:6) {#if !invalid && helperText}
function create_if_block_3$2(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*helperText*/ ctx[11]);
			toggle_class(div, "bx--form__helper-text", true);
			toggle_class(div, "bx--form__helper-text--disabled", /*disabled*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*helperText*/ 2048) set_data(t, /*helperText*/ ctx[11]);

			if (dirty[0] & /*disabled*/ 16) {
				toggle_class(div, "bx--form__helper-text--disabled", /*disabled*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (194:6) {#if invalid}
function create_if_block_2$3(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*invalidText*/ ctx[8]);
			attr(div, "id", /*errorId*/ ctx[16]);
			toggle_class(div, "bx--form-requirement", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*invalidText*/ 256) set_data(t, /*invalidText*/ ctx[8]);

			if (dirty[0] & /*errorId*/ 65536) {
				attr(div, "id", /*errorId*/ ctx[16]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (199:6) {#if !invalid && warn}
function create_if_block_1$5(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*warnText*/ ctx[10]);
			attr(div, "id", /*errorId*/ ctx[16]);
			toggle_class(div, "bx--form-requirement", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*warnText*/ 1024) set_data(t, /*warnText*/ ctx[10]);

			if (dirty[0] & /*errorId*/ 65536) {
				attr(div, "id", /*errorId*/ ctx[16]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment$m(ctx) {
	let div1;
	let div0;
	let t0;
	let t1;
	let current;
	let if_block0 = !/*noLabel*/ ctx[12] && create_if_block_10(ctx);
	let if_block1 = /*inline*/ ctx[2] && create_if_block_6(ctx);
	let if_block2 = !/*inline*/ ctx[2] && create_if_block$j(ctx);
	let div1_levels = [/*$$restProps*/ ctx[18]];
	let div1_data = {};

	for (let i = 0; i < div1_levels.length; i += 1) {
		div1_data = assign(div1_data, div1_levels[i]);
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			toggle_class(div0, "bx--select", true);
			toggle_class(div0, "bx--select--inline", /*inline*/ ctx[2]);
			toggle_class(div0, "bx--select--light", /*light*/ ctx[3]);
			toggle_class(div0, "bx--select--invalid", /*invalid*/ ctx[7]);
			toggle_class(div0, "bx--select--disabled", /*disabled*/ ctx[4]);
			toggle_class(div0, "bx--select--warning", /*warn*/ ctx[9]);
			set_attributes(div1, div1_data);
			toggle_class(div1, "bx--form-item", true);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			if (if_block0) if_block0.m(div0, null);
			append(div0, t0);
			if (if_block1) if_block1.m(div0, null);
			append(div0, t1);
			if (if_block2) if_block2.m(div0, null);
			current = true;
		},
		p(ctx, dirty) {
			if (!/*noLabel*/ ctx[12]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*noLabel*/ 4096) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_10(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div0, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*inline*/ ctx[2]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*inline*/ 4) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_6(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div0, t1);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!/*inline*/ ctx[2]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty[0] & /*inline*/ 4) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block$j(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div0, null);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*inline*/ 4) {
				toggle_class(div0, "bx--select--inline", /*inline*/ ctx[2]);
			}

			if (dirty[0] & /*light*/ 8) {
				toggle_class(div0, "bx--select--light", /*light*/ ctx[3]);
			}

			if (dirty[0] & /*invalid*/ 128) {
				toggle_class(div0, "bx--select--invalid", /*invalid*/ ctx[7]);
			}

			if (dirty[0] & /*disabled*/ 16) {
				toggle_class(div0, "bx--select--disabled", /*disabled*/ ctx[4]);
			}

			if (dirty[0] & /*warn*/ 512) {
				toggle_class(div0, "bx--select--warning", /*warn*/ ctx[9]);
			}

			set_attributes(div1, div1_data = get_spread_update(div1_levels, [dirty[0] & /*$$restProps*/ 262144 && /*$$restProps*/ ctx[18]]));
			toggle_class(div1, "bx--form-item", true);
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
		}
	};
}

function instance$n($$self, $$props, $$invalidate) {
	let errorId;

	const omit_props_names = [
		"selected","size","inline","light","disabled","id","name","invalid","invalidText","warn","warnText","helperText","noLabel","labelText","hideLabel","ref","required"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let $selectedValue;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { selected = undefined } = $$props;
	let { size = undefined } = $$props;
	let { inline = false } = $$props;
	let { light = false } = $$props;
	let { disabled = false } = $$props;
	let { id = "ccs-" + Math.random().toString(36) } = $$props;
	let { name = undefined } = $$props;
	let { invalid = false } = $$props;
	let { invalidText = "" } = $$props;
	let { warn = false } = $$props;
	let { warnText = "" } = $$props;
	let { helperText = "" } = $$props;
	let { noLabel = false } = $$props;
	let { labelText = "" } = $$props;
	let { hideLabel = false } = $$props;
	let { ref = null } = $$props;
	let { required = false } = $$props;
	const dispatch = createEventDispatcher();
	const selectedValue = writable(selected);
	component_subscribe($$self, selectedValue, value => $$invalidate(32, $selectedValue = value));
	setContext("Select", { selectedValue });

	afterUpdate(() => {
		$$invalidate(19, selected = $selectedValue);
		dispatch("change", $selectedValue);
	});

	function input_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function focus_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function blur_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function input_handler(event) {
		bubble.call(this, $$self, event);
	}

	function focus_handler(event) {
		bubble.call(this, $$self, event);
	}

	function blur_handler(event) {
		bubble.call(this, $$self, event);
	}

	function select_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			ref = $$value;
			$$invalidate(0, ref);
		});
	}

	const change_handler = ({ target }) => {
		selectedValue.set(target.value);
	};

	function select_binding_1($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			ref = $$value;
			$$invalidate(0, ref);
		});
	}

	const change_handler_1 = ({ target }) => {
		selectedValue.set(target.value);
	};

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(18, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('selected' in $$new_props) $$invalidate(19, selected = $$new_props.selected);
		if ('size' in $$new_props) $$invalidate(1, size = $$new_props.size);
		if ('inline' in $$new_props) $$invalidate(2, inline = $$new_props.inline);
		if ('light' in $$new_props) $$invalidate(3, light = $$new_props.light);
		if ('disabled' in $$new_props) $$invalidate(4, disabled = $$new_props.disabled);
		if ('id' in $$new_props) $$invalidate(5, id = $$new_props.id);
		if ('name' in $$new_props) $$invalidate(6, name = $$new_props.name);
		if ('invalid' in $$new_props) $$invalidate(7, invalid = $$new_props.invalid);
		if ('invalidText' in $$new_props) $$invalidate(8, invalidText = $$new_props.invalidText);
		if ('warn' in $$new_props) $$invalidate(9, warn = $$new_props.warn);
		if ('warnText' in $$new_props) $$invalidate(10, warnText = $$new_props.warnText);
		if ('helperText' in $$new_props) $$invalidate(11, helperText = $$new_props.helperText);
		if ('noLabel' in $$new_props) $$invalidate(12, noLabel = $$new_props.noLabel);
		if ('labelText' in $$new_props) $$invalidate(13, labelText = $$new_props.labelText);
		if ('hideLabel' in $$new_props) $$invalidate(14, hideLabel = $$new_props.hideLabel);
		if ('ref' in $$new_props) $$invalidate(0, ref = $$new_props.ref);
		if ('required' in $$new_props) $$invalidate(15, required = $$new_props.required);
		if ('$$scope' in $$new_props) $$invalidate(20, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*id*/ 32) {
			 $$invalidate(16, errorId = `error-${id}`);
		}

		if ($$self.$$.dirty[0] & /*selected*/ 524288) {
			 selectedValue.set(selected);
		}
	};

	return [
		ref,
		size,
		inline,
		light,
		disabled,
		id,
		name,
		invalid,
		invalidText,
		warn,
		warnText,
		helperText,
		noLabel,
		labelText,
		hideLabel,
		required,
		errorId,
		selectedValue,
		$$restProps,
		selected,
		$$scope,
		slots,
		input_handler_1,
		focus_handler_1,
		blur_handler_1,
		input_handler,
		focus_handler,
		blur_handler,
		select_binding,
		change_handler,
		select_binding_1,
		change_handler_1
	];
}

class Select extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$n,
			create_fragment$m,
			safe_not_equal,
			{
				selected: 19,
				size: 1,
				inline: 2,
				light: 3,
				disabled: 4,
				id: 5,
				name: 6,
				invalid: 7,
				invalidText: 8,
				warn: 9,
				warnText: 10,
				helperText: 11,
				noLabel: 12,
				labelText: 13,
				hideLabel: 14,
				ref: 0,
				required: 15
			},
			null,
			[-1, -1]
		);
	}
}

/* node_modules/carbon-components-svelte/src/Select/SelectItem.svelte generated by Svelte v3.47.0 */

function create_fragment$n(ctx) {
	let option;
	let t_value = (/*text*/ ctx[1] || /*value*/ ctx[0]) + "";
	let t;
	let option_class_value;
	let option_style_value;

	return {
		c() {
			option = element("option");
			t = text(t_value);
			option.__value = /*value*/ ctx[0];
			option.value = option.__value;
			option.disabled = /*disabled*/ ctx[3];
			option.hidden = /*hidden*/ ctx[2];
			option.selected = /*selected*/ ctx[4];
			attr(option, "class", option_class_value = /*$$restProps*/ ctx[5].class);
			attr(option, "style", option_style_value = /*$$restProps*/ ctx[5].style);
			toggle_class(option, "bx--select-option", true);
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t);
		},
		p(ctx, [dirty]) {
			if (dirty & /*text, value*/ 3 && t_value !== (t_value = (/*text*/ ctx[1] || /*value*/ ctx[0]) + "")) set_data(t, t_value);

			if (dirty & /*value*/ 1) {
				option.__value = /*value*/ ctx[0];
				option.value = option.__value;
			}

			if (dirty & /*disabled*/ 8) {
				option.disabled = /*disabled*/ ctx[3];
			}

			if (dirty & /*hidden*/ 4) {
				option.hidden = /*hidden*/ ctx[2];
			}

			if (dirty & /*selected*/ 16) {
				option.selected = /*selected*/ ctx[4];
			}

			if (dirty & /*$$restProps*/ 32 && option_class_value !== (option_class_value = /*$$restProps*/ ctx[5].class)) {
				attr(option, "class", option_class_value);
			}

			if (dirty & /*$$restProps*/ 32 && option_style_value !== (option_style_value = /*$$restProps*/ ctx[5].style)) {
				attr(option, "style", option_style_value);
			}

			if (dirty & /*$$restProps*/ 32) {
				toggle_class(option, "bx--select-option", true);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

function instance$o($$self, $$props, $$invalidate) {
	const omit_props_names = ["value","text","hidden","disabled"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { value = "" } = $$props;
	let { text = "" } = $$props;
	let { hidden = false } = $$props;
	let { disabled = false } = $$props;
	const ctx = getContext("Select") || getContext("TimePickerSelect");
	let selected = false;

	const unsubscribe = ctx.selectedValue.subscribe(currentValue => {
		$$invalidate(4, selected = currentValue === value);
	});

	onMount(() => {
		return () => unsubscribe();
	});

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(5, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('value' in $$new_props) $$invalidate(0, value = $$new_props.value);
		if ('text' in $$new_props) $$invalidate(1, text = $$new_props.text);
		if ('hidden' in $$new_props) $$invalidate(2, hidden = $$new_props.hidden);
		if ('disabled' in $$new_props) $$invalidate(3, disabled = $$new_props.disabled);
	};

	return [value, text, hidden, disabled, selected, $$restProps];
}

class SelectItem extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$o, create_fragment$n, safe_not_equal, {
			value: 0,
			text: 1,
			hidden: 2,
			disabled: 3
		});
	}
}

/* node_modules/carbon-components-svelte/src/TextInput/TextInput.svelte generated by Svelte v3.47.0 */
const get_labelText_slot_changes_1 = dirty => ({});
const get_labelText_slot_context_1 = ctx => ({});
const get_labelText_slot_changes$2 = dirty => ({});
const get_labelText_slot_context$2 = ctx => ({});

// (114:2) {#if inline}
function create_if_block_10$1(ctx) {
	let div;
	let t;
	let current;
	let if_block0 = /*labelText*/ ctx[9] && create_if_block_12(ctx);
	let if_block1 = !/*isFluid*/ ctx[20] && /*helperText*/ ctx[6] && create_if_block_11(ctx);

	return {
		c() {
			div = element("div");
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			toggle_class(div, "bx--text-input__label-helper-wrapper", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append(div, t);
			if (if_block1) if_block1.m(div, null);
			current = true;
		},
		p(ctx, dirty) {
			if (/*labelText*/ ctx[9]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*labelText*/ 512) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_12(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div, t);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (!/*isFluid*/ ctx[20] && /*helperText*/ ctx[6]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_11(ctx);
					if_block1.c();
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};
}

// (116:6) {#if labelText}
function create_if_block_12(ctx) {
	let label;
	let label_class_value;
	let current;
	const labelText_slot_template = /*#slots*/ ctx[26].labelText;
	const labelText_slot = create_slot(labelText_slot_template, ctx, /*$$scope*/ ctx[25], get_labelText_slot_context$2);
	const labelText_slot_or_fallback = labelText_slot || fallback_block_1$2(ctx);

	return {
		c() {
			label = element("label");
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.c();
			attr(label, "for", /*id*/ ctx[7]);
			attr(label, "class", label_class_value = /*inline*/ ctx[16] && !!/*size*/ ctx[2] && `bx--label--inline--${/*size*/ ctx[2]}`);
			toggle_class(label, "bx--label", true);
			toggle_class(label, "bx--visually-hidden", /*hideLabel*/ ctx[10]);
			toggle_class(label, "bx--label--disabled", /*disabled*/ ctx[5]);
			toggle_class(label, "bx--label--inline", /*inline*/ ctx[16]);
		},
		m(target, anchor) {
			insert(target, label, anchor);

			if (labelText_slot_or_fallback) {
				labelText_slot_or_fallback.m(label, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (labelText_slot) {
				if (labelText_slot.p && (!current || dirty[0] & /*$$scope*/ 33554432)) {
					update_slot_base(
						labelText_slot,
						labelText_slot_template,
						ctx,
						/*$$scope*/ ctx[25],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[25])
						: get_slot_changes(labelText_slot_template, /*$$scope*/ ctx[25], dirty, get_labelText_slot_changes$2),
						get_labelText_slot_context$2
					);
				}
			} else {
				if (labelText_slot_or_fallback && labelText_slot_or_fallback.p && (!current || dirty[0] & /*labelText*/ 512)) {
					labelText_slot_or_fallback.p(ctx, !current ? [-1, -1] : dirty);
				}
			}

			if (!current || dirty[0] & /*id*/ 128) {
				attr(label, "for", /*id*/ ctx[7]);
			}

			if (!current || dirty[0] & /*inline, size*/ 65540 && label_class_value !== (label_class_value = /*inline*/ ctx[16] && !!/*size*/ ctx[2] && `bx--label--inline--${/*size*/ ctx[2]}`)) {
				attr(label, "class", label_class_value);
			}

			if (dirty[0] & /*inline, size*/ 65540) {
				toggle_class(label, "bx--label", true);
			}

			if (dirty[0] & /*inline, size, hideLabel*/ 66564) {
				toggle_class(label, "bx--visually-hidden", /*hideLabel*/ ctx[10]);
			}

			if (dirty[0] & /*inline, size, disabled*/ 65572) {
				toggle_class(label, "bx--label--disabled", /*disabled*/ ctx[5]);
			}

			if (dirty[0] & /*inline, size, inline*/ 65540) {
				toggle_class(label, "bx--label--inline", /*inline*/ ctx[16]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(labelText_slot_or_fallback, local);
			current = true;
		},
		o(local) {
			transition_out(labelText_slot_or_fallback, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(label);
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.d(detaching);
		}
	};
}

// (125:33)              
function fallback_block_1$2(ctx) {
	let t;

	return {
		c() {
			t = text(/*labelText*/ ctx[9]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*labelText*/ 512) set_data(t, /*labelText*/ ctx[9]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (130:6) {#if !isFluid && helperText}
function create_if_block_11(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*helperText*/ ctx[6]);
			toggle_class(div, "bx--form__helper-text", true);
			toggle_class(div, "bx--form__helper-text--disabled", /*disabled*/ ctx[5]);
			toggle_class(div, "bx--form__helper-text--inline", /*inline*/ ctx[16]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*helperText*/ 64) set_data(t, /*helperText*/ ctx[6]);

			if (dirty[0] & /*disabled*/ 32) {
				toggle_class(div, "bx--form__helper-text--disabled", /*disabled*/ ctx[5]);
			}

			if (dirty[0] & /*inline*/ 65536) {
				toggle_class(div, "bx--form__helper-text--inline", /*inline*/ ctx[16]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (141:2) {#if !inline && (labelText || $$slots.labelText)}
function create_if_block_9$1(ctx) {
	let label;
	let label_class_value;
	let current;
	const labelText_slot_template = /*#slots*/ ctx[26].labelText;
	const labelText_slot = create_slot(labelText_slot_template, ctx, /*$$scope*/ ctx[25], get_labelText_slot_context_1);
	const labelText_slot_or_fallback = labelText_slot || fallback_block$4(ctx);

	return {
		c() {
			label = element("label");
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.c();
			attr(label, "for", /*id*/ ctx[7]);
			attr(label, "class", label_class_value = /*inline*/ ctx[16] && !!/*size*/ ctx[2] && `bx--label--inline--${/*size*/ ctx[2]}`);
			toggle_class(label, "bx--label", true);
			toggle_class(label, "bx--visually-hidden", /*hideLabel*/ ctx[10]);
			toggle_class(label, "bx--label--disabled", /*disabled*/ ctx[5]);
			toggle_class(label, "bx--label--inline", /*inline*/ ctx[16]);
		},
		m(target, anchor) {
			insert(target, label, anchor);

			if (labelText_slot_or_fallback) {
				labelText_slot_or_fallback.m(label, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (labelText_slot) {
				if (labelText_slot.p && (!current || dirty[0] & /*$$scope*/ 33554432)) {
					update_slot_base(
						labelText_slot,
						labelText_slot_template,
						ctx,
						/*$$scope*/ ctx[25],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[25])
						: get_slot_changes(labelText_slot_template, /*$$scope*/ ctx[25], dirty, get_labelText_slot_changes_1),
						get_labelText_slot_context_1
					);
				}
			} else {
				if (labelText_slot_or_fallback && labelText_slot_or_fallback.p && (!current || dirty[0] & /*labelText*/ 512)) {
					labelText_slot_or_fallback.p(ctx, !current ? [-1, -1] : dirty);
				}
			}

			if (!current || dirty[0] & /*id*/ 128) {
				attr(label, "for", /*id*/ ctx[7]);
			}

			if (!current || dirty[0] & /*inline, size*/ 65540 && label_class_value !== (label_class_value = /*inline*/ ctx[16] && !!/*size*/ ctx[2] && `bx--label--inline--${/*size*/ ctx[2]}`)) {
				attr(label, "class", label_class_value);
			}

			if (dirty[0] & /*inline, size*/ 65540) {
				toggle_class(label, "bx--label", true);
			}

			if (dirty[0] & /*inline, size, hideLabel*/ 66564) {
				toggle_class(label, "bx--visually-hidden", /*hideLabel*/ ctx[10]);
			}

			if (dirty[0] & /*inline, size, disabled*/ 65572) {
				toggle_class(label, "bx--label--disabled", /*disabled*/ ctx[5]);
			}

			if (dirty[0] & /*inline, size, inline*/ 65540) {
				toggle_class(label, "bx--label--inline", /*inline*/ ctx[16]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(labelText_slot_or_fallback, local);
			current = true;
		},
		o(local) {
			transition_out(labelText_slot_or_fallback, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(label);
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.d(detaching);
		}
	};
}

// (150:29)          
function fallback_block$4(ctx) {
	let t;

	return {
		c() {
			t = text(/*labelText*/ ctx[9]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*labelText*/ 512) set_data(t, /*labelText*/ ctx[9]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (165:6) {#if invalid}
function create_if_block_8$1(ctx) {
	let warningfilled;
	let current;

	warningfilled = new WarningFilled({
			props: { class: "bx--text-input__invalid-icon" }
		});

	return {
		c() {
			create_component(warningfilled.$$.fragment);
		},
		m(target, anchor) {
			mount_component(warningfilled, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(warningfilled.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(warningfilled.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(warningfilled, detaching);
		}
	};
}

// (168:6) {#if !invalid && warn}
function create_if_block_7$1(ctx) {
	let warningaltfilled;
	let current;

	warningaltfilled = new WarningAltFilled({
			props: {
				class: "bx--text-input__invalid-icon\n            bx--text-input__invalid-icon--warning"
			}
		});

	return {
		c() {
			create_component(warningaltfilled.$$.fragment);
		},
		m(target, anchor) {
			mount_component(warningaltfilled, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(warningaltfilled.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(warningaltfilled.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(warningaltfilled, detaching);
		}
	};
}

// (174:6) {#if readonly}
function create_if_block_6$1(ctx) {
	let editoff;
	let current;

	editoff = new EditOff({
			props: { class: "bx--text-input__readonly-icon" }
		});

	return {
		c() {
			create_component(editoff.$$.fragment);
		},
		m(target, anchor) {
			mount_component(editoff, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(editoff.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(editoff.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(editoff, detaching);
		}
	};
}

// (203:6) {#if isFluid}
function create_if_block_5$1(ctx) {
	let hr;

	return {
		c() {
			hr = element("hr");
			toggle_class(hr, "bx--text-input__divider", true);
		},
		m(target, anchor) {
			insert(target, hr, anchor);
		},
		d(detaching) {
			if (detaching) detach(hr);
		}
	};
}

// (206:6) {#if isFluid && !inline && invalid}
function create_if_block_4$2(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*invalidText*/ ctx[12]);
			attr(div, "id", /*errorId*/ ctx[19]);
			toggle_class(div, "bx--form-requirement", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*invalidText*/ 4096) set_data(t, /*invalidText*/ ctx[12]);

			if (dirty[0] & /*errorId*/ 524288) {
				attr(div, "id", /*errorId*/ ctx[19]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (211:6) {#if isFluid && !inline && warn}
function create_if_block_3$3(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*warnText*/ ctx[14]);
			attr(div, "id", /*warnId*/ ctx[18]);
			toggle_class(div, "bx--form-requirement", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*warnText*/ 16384) set_data(t, /*warnText*/ ctx[14]);

			if (dirty[0] & /*warnId*/ 262144) {
				attr(div, "id", /*warnId*/ ctx[18]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (215:4) {#if !invalid && !warn && !isFluid && !inline && helperText}
function create_if_block_2$4(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*helperText*/ ctx[6]);
			toggle_class(div, "bx--form__helper-text", true);
			toggle_class(div, "bx--form__helper-text--disabled", /*disabled*/ ctx[5]);
			toggle_class(div, "bx--form__helper-text--inline", /*inline*/ ctx[16]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*helperText*/ 64) set_data(t, /*helperText*/ ctx[6]);

			if (dirty[0] & /*disabled*/ 32) {
				toggle_class(div, "bx--form__helper-text--disabled", /*disabled*/ ctx[5]);
			}

			if (dirty[0] & /*inline*/ 65536) {
				toggle_class(div, "bx--form__helper-text--inline", /*inline*/ ctx[16]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (224:4) {#if !isFluid && invalid}
function create_if_block_1$6(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*invalidText*/ ctx[12]);
			attr(div, "id", /*errorId*/ ctx[19]);
			toggle_class(div, "bx--form-requirement", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*invalidText*/ 4096) set_data(t, /*invalidText*/ ctx[12]);

			if (dirty[0] & /*errorId*/ 524288) {
				attr(div, "id", /*errorId*/ ctx[19]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (229:4) {#if !isFluid && !invalid && warn}
function create_if_block$k(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*warnText*/ ctx[14]);
			attr(div, "id", /*warnId*/ ctx[18]);
			toggle_class(div, "bx--form-requirement", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*warnText*/ 16384) set_data(t, /*warnText*/ ctx[14]);

			if (dirty[0] & /*warnId*/ 262144) {
				attr(div, "id", /*warnId*/ ctx[18]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment$o(ctx) {
	let div2;
	let t0;
	let t1;
	let div1;
	let div0;
	let t2;
	let t3;
	let t4;
	let input;
	let input_data_invalid_value;
	let input_aria_invalid_value;
	let input_data_warn_value;
	let input_aria_describedby_value;
	let input_class_value;
	let t5;
	let t6;
	let t7;
	let div0_data_invalid_value;
	let div0_data_warn_value;
	let t8;
	let t9;
	let t10;
	let current;
	let mounted;
	let dispose;
	let if_block0 = /*inline*/ ctx[16] && create_if_block_10$1(ctx);
	let if_block1 = !/*inline*/ ctx[16] && (/*labelText*/ ctx[9] || /*$$slots*/ ctx[24].labelText) && create_if_block_9$1(ctx);
	let if_block2 = /*invalid*/ ctx[11] && create_if_block_8$1();
	let if_block3 = !/*invalid*/ ctx[11] && /*warn*/ ctx[13] && create_if_block_7$1();
	let if_block4 = /*readonly*/ ctx[17] && create_if_block_6$1();

	let input_levels = [
		{
			"data-invalid": input_data_invalid_value = /*invalid*/ ctx[11] || undefined
		},
		{
			"aria-invalid": input_aria_invalid_value = /*invalid*/ ctx[11] || undefined
		},
		{
			"data-warn": input_data_warn_value = /*warn*/ ctx[13] || undefined
		},
		{
			"aria-describedby": input_aria_describedby_value = /*invalid*/ ctx[11]
			? /*errorId*/ ctx[19]
			: /*warn*/ ctx[13] ? /*warnId*/ ctx[18] : undefined
		},
		{ disabled: /*disabled*/ ctx[5] },
		{ id: /*id*/ ctx[7] },
		{ name: /*name*/ ctx[8] },
		{ placeholder: /*placeholder*/ ctx[3] },
		{ required: /*required*/ ctx[15] },
		{ readOnly: /*readonly*/ ctx[17] },
		/*$$restProps*/ ctx[23],
		{
			class: input_class_value = /*size*/ ctx[2] && `bx--text-input--${/*size*/ ctx[2]}`
		}
	];

	let input_data = {};

	for (let i = 0; i < input_levels.length; i += 1) {
		input_data = assign(input_data, input_levels[i]);
	}

	let if_block5 = /*isFluid*/ ctx[20] && create_if_block_5$1();
	let if_block6 = /*isFluid*/ ctx[20] && !/*inline*/ ctx[16] && /*invalid*/ ctx[11] && create_if_block_4$2(ctx);
	let if_block7 = /*isFluid*/ ctx[20] && !/*inline*/ ctx[16] && /*warn*/ ctx[13] && create_if_block_3$3(ctx);
	let if_block8 = !/*invalid*/ ctx[11] && !/*warn*/ ctx[13] && !/*isFluid*/ ctx[20] && !/*inline*/ ctx[16] && /*helperText*/ ctx[6] && create_if_block_2$4(ctx);
	let if_block9 = !/*isFluid*/ ctx[20] && /*invalid*/ ctx[11] && create_if_block_1$6(ctx);
	let if_block10 = !/*isFluid*/ ctx[20] && !/*invalid*/ ctx[11] && /*warn*/ ctx[13] && create_if_block$k(ctx);

	return {
		c() {
			div2 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			div1 = element("div");
			div0 = element("div");
			if (if_block2) if_block2.c();
			t2 = space();
			if (if_block3) if_block3.c();
			t3 = space();
			if (if_block4) if_block4.c();
			t4 = space();
			input = element("input");
			t5 = space();
			if (if_block5) if_block5.c();
			t6 = space();
			if (if_block6) if_block6.c();
			t7 = space();
			if (if_block7) if_block7.c();
			t8 = space();
			if (if_block8) if_block8.c();
			t9 = space();
			if (if_block9) if_block9.c();
			t10 = space();
			if (if_block10) if_block10.c();
			set_attributes(input, input_data);
			toggle_class(input, "bx--text-input", true);
			toggle_class(input, "bx--text-input--light", /*light*/ ctx[4]);
			toggle_class(input, "bx--text-input--invalid", /*invalid*/ ctx[11]);
			toggle_class(input, "bx--text-input--warn", /*warn*/ ctx[13]);
			attr(div0, "data-invalid", div0_data_invalid_value = /*invalid*/ ctx[11] || undefined);
			attr(div0, "data-warn", div0_data_warn_value = /*warn*/ ctx[13] || undefined);
			toggle_class(div0, "bx--text-input__field-wrapper", true);
			toggle_class(div0, "bx--text-input__field-wrapper--warning", !/*invalid*/ ctx[11] && /*warn*/ ctx[13]);
			toggle_class(div1, "bx--text-input__field-outer-wrapper", true);
			toggle_class(div1, "bx--text-input__field-outer-wrapper--inline", /*inline*/ ctx[16]);
			toggle_class(div2, "bx--form-item", true);
			toggle_class(div2, "bx--text-input-wrapper", true);
			toggle_class(div2, "bx--text-input-wrapper--inline", /*inline*/ ctx[16]);
			toggle_class(div2, "bx--text-input-wrapper--light", /*light*/ ctx[4]);
			toggle_class(div2, "bx--text-input-wrapper--readonly", /*readonly*/ ctx[17]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			if (if_block0) if_block0.m(div2, null);
			append(div2, t0);
			if (if_block1) if_block1.m(div2, null);
			append(div2, t1);
			append(div2, div1);
			append(div1, div0);
			if (if_block2) if_block2.m(div0, null);
			append(div0, t2);
			if (if_block3) if_block3.m(div0, null);
			append(div0, t3);
			if (if_block4) if_block4.m(div0, null);
			append(div0, t4);
			append(div0, input);
			if (input.autofocus) input.focus();
			/*input_binding*/ ctx[35](input);
			set_input_value(input, /*value*/ ctx[0]);
			append(div0, t5);
			if (if_block5) if_block5.m(div0, null);
			append(div0, t6);
			if (if_block6) if_block6.m(div0, null);
			append(div0, t7);
			if (if_block7) if_block7.m(div0, null);
			append(div1, t8);
			if (if_block8) if_block8.m(div1, null);
			append(div1, t9);
			if (if_block9) if_block9.m(div1, null);
			append(div1, t10);
			if (if_block10) if_block10.m(div1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[36]),
					listen(input, "change", /*onChange*/ ctx[22]),
					listen(input, "input", /*onInput*/ ctx[21]),
					listen(input, "keydown", /*keydown_handler*/ ctx[31]),
					listen(input, "keyup", /*keyup_handler*/ ctx[32]),
					listen(input, "focus", /*focus_handler*/ ctx[33]),
					listen(input, "blur", /*blur_handler*/ ctx[34]),
					listen(div2, "click", /*click_handler*/ ctx[27]),
					listen(div2, "mouseover", /*mouseover_handler*/ ctx[28]),
					listen(div2, "mouseenter", /*mouseenter_handler*/ ctx[29]),
					listen(div2, "mouseleave", /*mouseleave_handler*/ ctx[30])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (/*inline*/ ctx[16]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*inline*/ 65536) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_10$1(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div2, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (!/*inline*/ ctx[16] && (/*labelText*/ ctx[9] || /*$$slots*/ ctx[24].labelText)) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*inline, labelText, $$slots*/ 16843264) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_9$1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div2, t1);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*invalid*/ ctx[11]) {
				if (if_block2) {
					if (dirty[0] & /*invalid*/ 2048) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_8$1();
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div0, t2);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (!/*invalid*/ ctx[11] && /*warn*/ ctx[13]) {
				if (if_block3) {
					if (dirty[0] & /*invalid, warn*/ 10240) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block_7$1();
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(div0, t3);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}

			if (/*readonly*/ ctx[17]) {
				if (if_block4) {
					if (dirty[0] & /*readonly*/ 131072) {
						transition_in(if_block4, 1);
					}
				} else {
					if_block4 = create_if_block_6$1();
					if_block4.c();
					transition_in(if_block4, 1);
					if_block4.m(div0, t4);
				}
			} else if (if_block4) {
				group_outros();

				transition_out(if_block4, 1, 1, () => {
					if_block4 = null;
				});

				check_outros();
			}

			set_attributes(input, input_data = get_spread_update(input_levels, [
				(!current || dirty[0] & /*invalid*/ 2048 && input_data_invalid_value !== (input_data_invalid_value = /*invalid*/ ctx[11] || undefined)) && { "data-invalid": input_data_invalid_value },
				(!current || dirty[0] & /*invalid*/ 2048 && input_aria_invalid_value !== (input_aria_invalid_value = /*invalid*/ ctx[11] || undefined)) && { "aria-invalid": input_aria_invalid_value },
				(!current || dirty[0] & /*warn*/ 8192 && input_data_warn_value !== (input_data_warn_value = /*warn*/ ctx[13] || undefined)) && { "data-warn": input_data_warn_value },
				(!current || dirty[0] & /*invalid, errorId, warn, warnId*/ 796672 && input_aria_describedby_value !== (input_aria_describedby_value = /*invalid*/ ctx[11]
				? /*errorId*/ ctx[19]
				: /*warn*/ ctx[13] ? /*warnId*/ ctx[18] : undefined)) && {
					"aria-describedby": input_aria_describedby_value
				},
				(!current || dirty[0] & /*disabled*/ 32) && { disabled: /*disabled*/ ctx[5] },
				(!current || dirty[0] & /*id*/ 128) && { id: /*id*/ ctx[7] },
				(!current || dirty[0] & /*name*/ 256) && { name: /*name*/ ctx[8] },
				(!current || dirty[0] & /*placeholder*/ 8) && { placeholder: /*placeholder*/ ctx[3] },
				(!current || dirty[0] & /*required*/ 32768) && { required: /*required*/ ctx[15] },
				(!current || dirty[0] & /*readonly*/ 131072) && { readOnly: /*readonly*/ ctx[17] },
				dirty[0] & /*$$restProps*/ 8388608 && /*$$restProps*/ ctx[23],
				(!current || dirty[0] & /*size*/ 4 && input_class_value !== (input_class_value = /*size*/ ctx[2] && `bx--text-input--${/*size*/ ctx[2]}`)) && { class: input_class_value }
			]));

			if (dirty[0] & /*value*/ 1 && input.value !== /*value*/ ctx[0]) {
				set_input_value(input, /*value*/ ctx[0]);
			}

			toggle_class(input, "bx--text-input", true);
			toggle_class(input, "bx--text-input--light", /*light*/ ctx[4]);
			toggle_class(input, "bx--text-input--invalid", /*invalid*/ ctx[11]);
			toggle_class(input, "bx--text-input--warn", /*warn*/ ctx[13]);

			if (/*isFluid*/ ctx[20]) {
				if (if_block5) ; else {
					if_block5 = create_if_block_5$1();
					if_block5.c();
					if_block5.m(div0, t6);
				}
			} else if (if_block5) {
				if_block5.d(1);
				if_block5 = null;
			}

			if (/*isFluid*/ ctx[20] && !/*inline*/ ctx[16] && /*invalid*/ ctx[11]) {
				if (if_block6) {
					if_block6.p(ctx, dirty);
				} else {
					if_block6 = create_if_block_4$2(ctx);
					if_block6.c();
					if_block6.m(div0, t7);
				}
			} else if (if_block6) {
				if_block6.d(1);
				if_block6 = null;
			}

			if (/*isFluid*/ ctx[20] && !/*inline*/ ctx[16] && /*warn*/ ctx[13]) {
				if (if_block7) {
					if_block7.p(ctx, dirty);
				} else {
					if_block7 = create_if_block_3$3(ctx);
					if_block7.c();
					if_block7.m(div0, null);
				}
			} else if (if_block7) {
				if_block7.d(1);
				if_block7 = null;
			}

			if (!current || dirty[0] & /*invalid*/ 2048 && div0_data_invalid_value !== (div0_data_invalid_value = /*invalid*/ ctx[11] || undefined)) {
				attr(div0, "data-invalid", div0_data_invalid_value);
			}

			if (!current || dirty[0] & /*warn*/ 8192 && div0_data_warn_value !== (div0_data_warn_value = /*warn*/ ctx[13] || undefined)) {
				attr(div0, "data-warn", div0_data_warn_value);
			}

			if (dirty[0] & /*invalid, warn*/ 10240) {
				toggle_class(div0, "bx--text-input__field-wrapper--warning", !/*invalid*/ ctx[11] && /*warn*/ ctx[13]);
			}

			if (!/*invalid*/ ctx[11] && !/*warn*/ ctx[13] && !/*isFluid*/ ctx[20] && !/*inline*/ ctx[16] && /*helperText*/ ctx[6]) {
				if (if_block8) {
					if_block8.p(ctx, dirty);
				} else {
					if_block8 = create_if_block_2$4(ctx);
					if_block8.c();
					if_block8.m(div1, t9);
				}
			} else if (if_block8) {
				if_block8.d(1);
				if_block8 = null;
			}

			if (!/*isFluid*/ ctx[20] && /*invalid*/ ctx[11]) {
				if (if_block9) {
					if_block9.p(ctx, dirty);
				} else {
					if_block9 = create_if_block_1$6(ctx);
					if_block9.c();
					if_block9.m(div1, t10);
				}
			} else if (if_block9) {
				if_block9.d(1);
				if_block9 = null;
			}

			if (!/*isFluid*/ ctx[20] && !/*invalid*/ ctx[11] && /*warn*/ ctx[13]) {
				if (if_block10) {
					if_block10.p(ctx, dirty);
				} else {
					if_block10 = create_if_block$k(ctx);
					if_block10.c();
					if_block10.m(div1, null);
				}
			} else if (if_block10) {
				if_block10.d(1);
				if_block10 = null;
			}

			if (dirty[0] & /*inline*/ 65536) {
				toggle_class(div1, "bx--text-input__field-outer-wrapper--inline", /*inline*/ ctx[16]);
			}

			if (dirty[0] & /*inline*/ 65536) {
				toggle_class(div2, "bx--text-input-wrapper--inline", /*inline*/ ctx[16]);
			}

			if (dirty[0] & /*light*/ 16) {
				toggle_class(div2, "bx--text-input-wrapper--light", /*light*/ ctx[4]);
			}

			if (dirty[0] & /*readonly*/ 131072) {
				toggle_class(div2, "bx--text-input-wrapper--readonly", /*readonly*/ ctx[17]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(if_block3);
			transition_in(if_block4);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(if_block3);
			transition_out(if_block4);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			/*input_binding*/ ctx[35](null);
			if (if_block5) if_block5.d();
			if (if_block6) if_block6.d();
			if (if_block7) if_block7.d();
			if (if_block8) if_block8.d();
			if (if_block9) if_block9.d();
			if (if_block10) if_block10.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$p($$self, $$props, $$invalidate) {
	let isFluid;
	let errorId;
	let warnId;

	const omit_props_names = [
		"size","value","placeholder","light","disabled","helperText","id","name","labelText","hideLabel","invalid","invalidText","warn","warnText","ref","required","inline","readonly"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	const $$slots = compute_slots(slots);
	let { size = undefined } = $$props;
	let { value = "" } = $$props;
	let { placeholder = "" } = $$props;
	let { light = false } = $$props;
	let { disabled = false } = $$props;
	let { helperText = "" } = $$props;
	let { id = "ccs-" + Math.random().toString(36) } = $$props;
	let { name = undefined } = $$props;
	let { labelText = "" } = $$props;
	let { hideLabel = false } = $$props;
	let { invalid = false } = $$props;
	let { invalidText = "" } = $$props;
	let { warn = false } = $$props;
	let { warnText = "" } = $$props;
	let { ref = null } = $$props;
	let { required = false } = $$props;
	let { inline = false } = $$props;
	let { readonly = false } = $$props;
	const ctx = getContext("Form");
	const dispatch = createEventDispatcher();

	function parse(raw) {
		if ($$restProps.type !== "number") return raw;
		return raw != "" ? Number(raw) : null;
	}

	/** @type {(e: Event) => void} */
	const onInput = e => {
		$$invalidate(0, value = parse(e.target.value));
		dispatch("input", value);
	};

	/** @type {(e: Event) => void} */
	const onChange = e => {
		dispatch("change", parse(e.target.value));
	};

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	function keydown_handler(event) {
		bubble.call(this, $$self, event);
	}

	function keyup_handler(event) {
		bubble.call(this, $$self, event);
	}

	function focus_handler(event) {
		bubble.call(this, $$self, event);
	}

	function blur_handler(event) {
		bubble.call(this, $$self, event);
	}

	function input_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			ref = $$value;
			$$invalidate(1, ref);
		});
	}

	function input_input_handler() {
		value = this.value;
		$$invalidate(0, value);
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(23, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(2, size = $$new_props.size);
		if ('value' in $$new_props) $$invalidate(0, value = $$new_props.value);
		if ('placeholder' in $$new_props) $$invalidate(3, placeholder = $$new_props.placeholder);
		if ('light' in $$new_props) $$invalidate(4, light = $$new_props.light);
		if ('disabled' in $$new_props) $$invalidate(5, disabled = $$new_props.disabled);
		if ('helperText' in $$new_props) $$invalidate(6, helperText = $$new_props.helperText);
		if ('id' in $$new_props) $$invalidate(7, id = $$new_props.id);
		if ('name' in $$new_props) $$invalidate(8, name = $$new_props.name);
		if ('labelText' in $$new_props) $$invalidate(9, labelText = $$new_props.labelText);
		if ('hideLabel' in $$new_props) $$invalidate(10, hideLabel = $$new_props.hideLabel);
		if ('invalid' in $$new_props) $$invalidate(11, invalid = $$new_props.invalid);
		if ('invalidText' in $$new_props) $$invalidate(12, invalidText = $$new_props.invalidText);
		if ('warn' in $$new_props) $$invalidate(13, warn = $$new_props.warn);
		if ('warnText' in $$new_props) $$invalidate(14, warnText = $$new_props.warnText);
		if ('ref' in $$new_props) $$invalidate(1, ref = $$new_props.ref);
		if ('required' in $$new_props) $$invalidate(15, required = $$new_props.required);
		if ('inline' in $$new_props) $$invalidate(16, inline = $$new_props.inline);
		if ('readonly' in $$new_props) $$invalidate(17, readonly = $$new_props.readonly);
		if ('$$scope' in $$new_props) $$invalidate(25, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*id*/ 128) {
			 $$invalidate(19, errorId = `error-${id}`);
		}

		if ($$self.$$.dirty[0] & /*id*/ 128) {
			 $$invalidate(18, warnId = `warn-${id}`);
		}
	};

	 $$invalidate(20, isFluid = !!ctx && ctx.isFluid);

	return [
		value,
		ref,
		size,
		placeholder,
		light,
		disabled,
		helperText,
		id,
		name,
		labelText,
		hideLabel,
		invalid,
		invalidText,
		warn,
		warnText,
		required,
		inline,
		readonly,
		warnId,
		errorId,
		isFluid,
		onInput,
		onChange,
		$$restProps,
		$$slots,
		$$scope,
		slots,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler,
		keydown_handler,
		keyup_handler,
		focus_handler,
		blur_handler,
		input_binding,
		input_input_handler
	];
}

class TextInput extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$p,
			create_fragment$o,
			safe_not_equal,
			{
				size: 2,
				value: 0,
				placeholder: 3,
				light: 4,
				disabled: 5,
				helperText: 6,
				id: 7,
				name: 8,
				labelText: 9,
				hideLabel: 10,
				invalid: 11,
				invalidText: 12,
				warn: 13,
				warnText: 14,
				ref: 1,
				required: 15,
				inline: 16,
				readonly: 17
			},
			null,
			[-1, -1]
		);
	}
}

/* node_modules/carbon-components-svelte/src/Toggle/Toggle.svelte generated by Svelte v3.47.0 */
const get_labelB_slot_changes = dirty => ({});
const get_labelB_slot_context = ctx => ({});
const get_labelA_slot_changes = dirty => ({});
const get_labelA_slot_context = ctx => ({});
const get_labelText_slot_changes$3 = dirty => ({});
const get_labelText_slot_context$3 = ctx => ({});

// (79:27)        
function fallback_block_2$1(ctx) {
	let t;

	return {
		c() {
			t = text(/*labelText*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*labelText*/ 32) set_data(t, /*labelText*/ ctx[5]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (84:28)            
function fallback_block_1$3(ctx) {
	let t;

	return {
		c() {
			t = text(/*labelA*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*labelA*/ 8) set_data(t, /*labelA*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (89:28)            
function fallback_block$5(ctx) {
	let t;

	return {
		c() {
			t = text(/*labelB*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*labelB*/ 16) set_data(t, /*labelB*/ ctx[4]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment$p(ctx) {
	let div;
	let input;
	let t0;
	let label;
	let t1;
	let span2;
	let span0;
	let t2;
	let span1;
	let label_aria_label_value;
	let current;
	let mounted;
	let dispose;
	const labelText_slot_template = /*#slots*/ ctx[11].labelText;
	const labelText_slot = create_slot(labelText_slot_template, ctx, /*$$scope*/ ctx[10], get_labelText_slot_context$3);
	const labelText_slot_or_fallback = labelText_slot || fallback_block_2$1(ctx);
	const labelA_slot_template = /*#slots*/ ctx[11].labelA;
	const labelA_slot = create_slot(labelA_slot_template, ctx, /*$$scope*/ ctx[10], get_labelA_slot_context);
	const labelA_slot_or_fallback = labelA_slot || fallback_block_1$3(ctx);
	const labelB_slot_template = /*#slots*/ ctx[11].labelB;
	const labelB_slot = create_slot(labelB_slot_template, ctx, /*$$scope*/ ctx[10], get_labelB_slot_context);
	const labelB_slot_or_fallback = labelB_slot || fallback_block$5(ctx);
	let div_levels = [/*$$restProps*/ ctx[8]];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			input = element("input");
			t0 = space();
			label = element("label");
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.c();
			t1 = space();
			span2 = element("span");
			span0 = element("span");
			if (labelA_slot_or_fallback) labelA_slot_or_fallback.c();
			t2 = space();
			span1 = element("span");
			if (labelB_slot_or_fallback) labelB_slot_or_fallback.c();
			attr(input, "type", "checkbox");
			input.checked = /*toggled*/ ctx[0];
			input.disabled = /*disabled*/ ctx[2];
			attr(input, "id", /*id*/ ctx[6]);
			attr(input, "name", /*name*/ ctx[7]);
			toggle_class(input, "bx--toggle-input", true);
			toggle_class(input, "bx--toggle-input--small", /*size*/ ctx[1] === 'sm');
			attr(span0, "aria-hidden", "true");
			toggle_class(span0, "bx--toggle__text--off", true);
			attr(span1, "aria-hidden", "true");
			toggle_class(span1, "bx--toggle__text--on", true);
			toggle_class(span2, "bx--toggle__switch", true);

			attr(label, "aria-label", label_aria_label_value = /*labelText*/ ctx[5]
			? undefined
			: /*$$props*/ ctx[9]['aria-label'] || 'Toggle');

			attr(label, "for", /*id*/ ctx[6]);
			toggle_class(label, "bx--toggle-input__label", true);
			set_attributes(div, div_data);
			toggle_class(div, "bx--form-item", true);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, input);
			append(div, t0);
			append(div, label);

			if (labelText_slot_or_fallback) {
				labelText_slot_or_fallback.m(label, null);
			}

			append(label, t1);
			append(label, span2);
			append(span2, span0);

			if (labelA_slot_or_fallback) {
				labelA_slot_or_fallback.m(span0, null);
			}

			append(span2, t2);
			append(span2, span1);

			if (labelB_slot_or_fallback) {
				labelB_slot_or_fallback.m(span1, null);
			}

			current = true;

			if (!mounted) {
				dispose = [
					listen(input, "change", /*change_handler*/ ctx[16]),
					listen(input, "change", /*change_handler_1*/ ctx[20]),
					listen(input, "keyup", /*keyup_handler*/ ctx[17]),
					listen(input, "keyup", /*keyup_handler_1*/ ctx[21]),
					listen(input, "focus", /*focus_handler*/ ctx[18]),
					listen(input, "blur", /*blur_handler*/ ctx[19]),
					listen(div, "click", /*click_handler*/ ctx[12]),
					listen(div, "mouseover", /*mouseover_handler*/ ctx[13]),
					listen(div, "mouseenter", /*mouseenter_handler*/ ctx[14]),
					listen(div, "mouseleave", /*mouseleave_handler*/ ctx[15])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*toggled*/ 1) {
				input.checked = /*toggled*/ ctx[0];
			}

			if (!current || dirty & /*disabled*/ 4) {
				input.disabled = /*disabled*/ ctx[2];
			}

			if (!current || dirty & /*id*/ 64) {
				attr(input, "id", /*id*/ ctx[6]);
			}

			if (!current || dirty & /*name*/ 128) {
				attr(input, "name", /*name*/ ctx[7]);
			}

			if (dirty & /*size*/ 2) {
				toggle_class(input, "bx--toggle-input--small", /*size*/ ctx[1] === 'sm');
			}

			if (labelText_slot) {
				if (labelText_slot.p && (!current || dirty & /*$$scope*/ 1024)) {
					update_slot_base(
						labelText_slot,
						labelText_slot_template,
						ctx,
						/*$$scope*/ ctx[10],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[10])
						: get_slot_changes(labelText_slot_template, /*$$scope*/ ctx[10], dirty, get_labelText_slot_changes$3),
						get_labelText_slot_context$3
					);
				}
			} else {
				if (labelText_slot_or_fallback && labelText_slot_or_fallback.p && (!current || dirty & /*labelText*/ 32)) {
					labelText_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (labelA_slot) {
				if (labelA_slot.p && (!current || dirty & /*$$scope*/ 1024)) {
					update_slot_base(
						labelA_slot,
						labelA_slot_template,
						ctx,
						/*$$scope*/ ctx[10],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[10])
						: get_slot_changes(labelA_slot_template, /*$$scope*/ ctx[10], dirty, get_labelA_slot_changes),
						get_labelA_slot_context
					);
				}
			} else {
				if (labelA_slot_or_fallback && labelA_slot_or_fallback.p && (!current || dirty & /*labelA*/ 8)) {
					labelA_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (labelB_slot) {
				if (labelB_slot.p && (!current || dirty & /*$$scope*/ 1024)) {
					update_slot_base(
						labelB_slot,
						labelB_slot_template,
						ctx,
						/*$$scope*/ ctx[10],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[10])
						: get_slot_changes(labelB_slot_template, /*$$scope*/ ctx[10], dirty, get_labelB_slot_changes),
						get_labelB_slot_context
					);
				}
			} else {
				if (labelB_slot_or_fallback && labelB_slot_or_fallback.p && (!current || dirty & /*labelB*/ 16)) {
					labelB_slot_or_fallback.p(ctx, !current ? -1 : dirty);
				}
			}

			if (!current || dirty & /*labelText, $$props*/ 544 && label_aria_label_value !== (label_aria_label_value = /*labelText*/ ctx[5]
			? undefined
			: /*$$props*/ ctx[9]['aria-label'] || 'Toggle')) {
				attr(label, "aria-label", label_aria_label_value);
			}

			if (!current || dirty & /*id*/ 64) {
				attr(label, "for", /*id*/ ctx[6]);
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [dirty & /*$$restProps*/ 256 && /*$$restProps*/ ctx[8]]));
			toggle_class(div, "bx--form-item", true);
		},
		i(local) {
			if (current) return;
			transition_in(labelText_slot_or_fallback, local);
			transition_in(labelA_slot_or_fallback, local);
			transition_in(labelB_slot_or_fallback, local);
			current = true;
		},
		o(local) {
			transition_out(labelText_slot_or_fallback, local);
			transition_out(labelA_slot_or_fallback, local);
			transition_out(labelB_slot_or_fallback, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (labelText_slot_or_fallback) labelText_slot_or_fallback.d(detaching);
			if (labelA_slot_or_fallback) labelA_slot_or_fallback.d(detaching);
			if (labelB_slot_or_fallback) labelB_slot_or_fallback.d(detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$q($$self, $$props, $$invalidate) {
	const omit_props_names = ["size","toggled","disabled","labelA","labelB","labelText","id","name"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { size = "default" } = $$props;
	let { toggled = false } = $$props;
	let { disabled = false } = $$props;
	let { labelA = "Off" } = $$props;
	let { labelB = "On" } = $$props;
	let { labelText = "" } = $$props;
	let { id = "ccs-" + Math.random().toString(36) } = $$props;
	let { name = undefined } = $$props;
	const dispatch = createEventDispatcher();

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseover_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseenter_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseleave_handler(event) {
		bubble.call(this, $$self, event);
	}

	function change_handler(event) {
		bubble.call(this, $$self, event);
	}

	function keyup_handler(event) {
		bubble.call(this, $$self, event);
	}

	function focus_handler(event) {
		bubble.call(this, $$self, event);
	}

	function blur_handler(event) {
		bubble.call(this, $$self, event);
	}

	const change_handler_1 = () => {
		$$invalidate(0, toggled = !toggled);
	};

	const keyup_handler_1 = e => {
		if (e.key === ' ' || e.key === 'Enter') {
			e.preventDefault();
			$$invalidate(0, toggled = !toggled);
		}
	};

	$$self.$$set = $$new_props => {
		$$invalidate(9, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(8, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(1, size = $$new_props.size);
		if ('toggled' in $$new_props) $$invalidate(0, toggled = $$new_props.toggled);
		if ('disabled' in $$new_props) $$invalidate(2, disabled = $$new_props.disabled);
		if ('labelA' in $$new_props) $$invalidate(3, labelA = $$new_props.labelA);
		if ('labelB' in $$new_props) $$invalidate(4, labelB = $$new_props.labelB);
		if ('labelText' in $$new_props) $$invalidate(5, labelText = $$new_props.labelText);
		if ('id' in $$new_props) $$invalidate(6, id = $$new_props.id);
		if ('name' in $$new_props) $$invalidate(7, name = $$new_props.name);
		if ('$$scope' in $$new_props) $$invalidate(10, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*toggled*/ 1) {
			 dispatch("toggle", { toggled });
		}
	};

	$$props = exclude_internal_props($$props);

	return [
		toggled,
		size,
		disabled,
		labelA,
		labelB,
		labelText,
		id,
		name,
		$$restProps,
		$$props,
		$$scope,
		slots,
		click_handler,
		mouseover_handler,
		mouseenter_handler,
		mouseleave_handler,
		change_handler,
		keyup_handler,
		focus_handler,
		blur_handler,
		change_handler_1,
		keyup_handler_1
	];
}

class Toggle extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$q, create_fragment$p, safe_not_equal, {
			size: 1,
			toggled: 0,
			disabled: 2,
			labelA: 3,
			labelB: 4,
			labelText: 5,
			id: 6,
			name: 7
		});
	}
}

/* node_modules/carbon-components-svelte/src/Theme/Theme.svelte generated by Svelte v3.47.0 */
const get_default_slot_changes$4 = dirty => ({ theme: dirty & /*theme*/ 1 });
const get_default_slot_context$4 = ctx => ({ theme: /*theme*/ ctx[0] });

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[0] = list[i];
	return child_ctx;
}

// (98:0) {#if persist}
function create_if_block_2$5(ctx) {
	let localstorage;
	let updating_value;
	let current;

	function localstorage_value_binding(value) {
		/*localstorage_value_binding*/ ctx[9](value);
	}

	let localstorage_props = { key: /*persistKey*/ ctx[2] };

	if (/*theme*/ ctx[0] !== void 0) {
		localstorage_props.value = /*theme*/ ctx[0];
	}

	localstorage = new LocalStorage({ props: localstorage_props });
	binding_callbacks.push(() => bind(localstorage, 'value', localstorage_value_binding));

	return {
		c() {
			create_component(localstorage.$$.fragment);
		},
		m(target, anchor) {
			mount_component(localstorage, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const localstorage_changes = {};
			if (dirty & /*persistKey*/ 4) localstorage_changes.key = /*persistKey*/ ctx[2];

			if (!updating_value && dirty & /*theme*/ 1) {
				updating_value = true;
				localstorage_changes.value = /*theme*/ ctx[0];
				add_flush_callback(() => updating_value = false);
			}

			localstorage.$set(localstorage_changes);
		},
		i(local) {
			if (current) return;
			transition_in(localstorage.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(localstorage.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(localstorage, detaching);
		}
	};
}

// (110:30) 
function create_if_block_1$7(ctx) {
	let select_1;
	let updating_selected;
	let current;
	const select_1_spread_levels = [/*select*/ ctx[5]];

	function select_1_selected_binding(value) {
		/*select_1_selected_binding*/ ctx[11](value);
	}

	let select_1_props = {
		$$slots: { default: [create_default_slot] },
		$$scope: { ctx }
	};

	for (let i = 0; i < select_1_spread_levels.length; i += 1) {
		select_1_props = assign(select_1_props, select_1_spread_levels[i]);
	}

	if (/*theme*/ ctx[0] !== void 0) {
		select_1_props.selected = /*theme*/ ctx[0];
	}

	select_1 = new Select({ props: select_1_props });
	binding_callbacks.push(() => bind(select_1, 'selected', select_1_selected_binding));

	return {
		c() {
			create_component(select_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(select_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const select_1_changes = (dirty & /*select*/ 32)
			? get_spread_update(select_1_spread_levels, [get_spread_object(/*select*/ ctx[5])])
			: {};

			if (dirty & /*$$scope, select*/ 4128) {
				select_1_changes.$$scope = { dirty, ctx };
			}

			if (!updating_selected && dirty & /*theme*/ 1) {
				updating_selected = true;
				select_1_changes.selected = /*theme*/ ctx[0];
				add_flush_callback(() => updating_selected = false);
			}

			select_1.$set(select_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(select_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(select_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(select_1, detaching);
		}
	};
}

// (102:0) {#if render === "toggle"}
function create_if_block$l(ctx) {
	let toggle_1;
	let current;

	const toggle_1_spread_levels = [
		/*toggle*/ ctx[4],
		{
			toggled: /*theme*/ ctx[0] === /*toggle*/ ctx[4].themes[1]
		}
	];

	let toggle_1_props = {};

	for (let i = 0; i < toggle_1_spread_levels.length; i += 1) {
		toggle_1_props = assign(toggle_1_props, toggle_1_spread_levels[i]);
	}

	toggle_1 = new Toggle({ props: toggle_1_props });
	toggle_1.$on("toggle", /*toggle_handler*/ ctx[10]);

	return {
		c() {
			create_component(toggle_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(toggle_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const toggle_1_changes = (dirty & /*toggle, theme*/ 17)
			? get_spread_update(toggle_1_spread_levels, [
					dirty & /*toggle*/ 16 && get_spread_object(/*toggle*/ ctx[4]),
					{
						toggled: /*theme*/ ctx[0] === /*toggle*/ ctx[4].themes[1]
					}
				])
			: {};

			toggle_1.$set(toggle_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(toggle_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(toggle_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(toggle_1, detaching);
		}
	};
}

// (112:4) {#each select.themes as theme (theme)}
function create_each_block(key_1, ctx) {
	let first;
	let selectitem;
	let current;

	selectitem = new SelectItem({
			props: {
				value: /*theme*/ ctx[0],
				text: /*themes*/ ctx[6][/*theme*/ ctx[0]]
			}
		});

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(selectitem.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(selectitem, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const selectitem_changes = {};
			if (dirty & /*select*/ 32) selectitem_changes.value = /*theme*/ ctx[0];
			if (dirty & /*select*/ 32) selectitem_changes.text = /*themes*/ ctx[6][/*theme*/ ctx[0]];
			selectitem.$set(selectitem_changes);
		},
		i(local) {
			if (current) return;
			transition_in(selectitem.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(selectitem.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(first);
			destroy_component(selectitem, detaching);
		}
	};
}

// (111:2) <Select {...select} bind:selected="{theme}">
function create_default_slot(ctx) {
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let each_value = /*select*/ ctx[5].themes;
	const get_key = ctx => /*theme*/ ctx[0];

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*select, themes*/ 96) {
				each_value = /*select*/ ctx[5].themes;
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block, each_1_anchor, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (detaching) detach(each_1_anchor);
		}
	};
}

function create_fragment$q(ctx) {
	let t0;
	let current_block_type_index;
	let if_block1;
	let t1;
	let current;
	let if_block0 = /*persist*/ ctx[1] && create_if_block_2$5(ctx);
	const if_block_creators = [create_if_block$l, create_if_block_1$7];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*render*/ ctx[3] === "toggle") return 0;
		if (/*render*/ ctx[3] === "select") return 1;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx))) {
		if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	const default_slot_template = /*#slots*/ ctx[8].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[12], get_default_slot_context$4);

	return {
		c() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t0, anchor);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(target, anchor);
			}

			insert(target, t1, anchor);

			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (/*persist*/ ctx[1]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*persist*/ 2) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2$5(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t0.parentNode, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block1) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block1 = if_blocks[current_block_type_index];

					if (!if_block1) {
						if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block1.c();
					} else {
						if_block1.p(ctx, dirty);
					}

					transition_in(if_block1, 1);
					if_block1.m(t1.parentNode, t1);
				} else {
					if_block1 = null;
				}
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope, theme*/ 4097)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[12],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[12])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[12], dirty, get_default_slot_changes$4),
						get_default_slot_context$4
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t0);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d(detaching);
			}

			if (detaching) detach(t1);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance$r($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { theme = "white" } = $$props;
	let { tokens = {} } = $$props;
	let { persist = false } = $$props;
	let { persistKey = "theme" } = $$props;
	let { render = undefined } = $$props;

	let { toggle = {
		themes: ["white", "g100"],
		labelA: "",
		labelB: "",
		labelText: "Dark mode",
		hideLabel: false
	} } = $$props;

	/** @type {Record<CarbonTheme, string>} */
	const themes = {
		white: "White",
		g10: "Gray 10",
		g80: "Gray 80",
		g90: "Gray 90",
		g100: "Gray 100"
	};

	/** @type {CarbonTheme} */
	const themeKeys = Object.keys(themes);

	let { select = {
		themes: themeKeys,
		labelText: "Themes",
		hideLabel: false
	} } = $$props;

	const dispatch = createEventDispatcher();

	function localstorage_value_binding(value) {
		theme = value;
		$$invalidate(0, theme);
	}

	const toggle_handler = ({ detail }) => {
		$$invalidate(0, theme = detail.toggled ? toggle.themes[1] : toggle.themes[0]);
	};

	function select_1_selected_binding(value) {
		theme = value;
		$$invalidate(0, theme);
	}

	$$self.$$set = $$props => {
		if ('theme' in $$props) $$invalidate(0, theme = $$props.theme);
		if ('tokens' in $$props) $$invalidate(7, tokens = $$props.tokens);
		if ('persist' in $$props) $$invalidate(1, persist = $$props.persist);
		if ('persistKey' in $$props) $$invalidate(2, persistKey = $$props.persistKey);
		if ('render' in $$props) $$invalidate(3, render = $$props.render);
		if ('toggle' in $$props) $$invalidate(4, toggle = $$props.toggle);
		if ('select' in $$props) $$invalidate(5, select = $$props.select);
		if ('$$scope' in $$props) $$invalidate(12, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*tokens, theme*/ 129) {
			 if (typeof window !== "undefined") {
				Object.entries(tokens).forEach(([token, value]) => {
					document.documentElement.style.setProperty(`--cds-${token}`, value);
				});

				if (theme in themes) {
					document.documentElement.setAttribute("theme", theme);
					dispatch("update", { theme });
				} else {
					console.warn(`[Theme.svelte] invalid theme "${theme}". Value must be one of: ${JSON.stringify(Object.keys(themes))}`);
				}
			}
		}
	};

	return [
		theme,
		persist,
		persistKey,
		render,
		toggle,
		select,
		themes,
		tokens,
		slots,
		localstorage_value_binding,
		toggle_handler,
		select_1_selected_binding,
		$$scope
	];
}

class Theme extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$r, create_fragment$q, safe_not_equal, {
			theme: 0,
			tokens: 7,
			persist: 1,
			persistKey: 2,
			render: 3,
			toggle: 4,
			select: 5
		});
	}
}

/* node_modules/carbon-components-svelte/src/icons/Information.svelte generated by Svelte v3.47.0 */

function create_if_block$m(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$r(ctx) {
	let svg;
	let path0;
	let path1;
	let if_block = /*title*/ ctx[1] && create_if_block$m(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "d", "M17 22L17 14 13 14 13 16 15 16 15 22 12 22 12 24 20 24 20 22 17 22zM16 8a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 8z");
			attr(path1, "d", "M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$m(ctx);
					if_block.c();
					if_block.m(svg, path0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$s($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class Information extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$s, create_fragment$r, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-components-svelte/src/Tooltip/Tooltip.svelte generated by Svelte v3.47.0 */
const get_triggerText_slot_changes_1 = dirty => ({});
const get_triggerText_slot_context_1 = ctx => ({});
const get_icon_slot_changes = dirty => ({});
const get_icon_slot_context = ctx => ({});
const get_triggerText_slot_changes = dirty => ({});
const get_triggerText_slot_context = ctx => ({});

// (222:2) {:else}
function create_else_block$7(ctx) {
	let div;
	let current;
	let mounted;
	let dispose;
	const triggerText_slot_template = /*#slots*/ ctx[21].triggerText;
	const triggerText_slot = create_slot(triggerText_slot_template, ctx, /*$$scope*/ ctx[20], get_triggerText_slot_context_1);
	const triggerText_slot_or_fallback = triggerText_slot || fallback_block_2$2(ctx);
	let div_levels = [/*buttonProps*/ ctx[12], { "aria-describedby": /*tooltipId*/ ctx[9] }];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			if (triggerText_slot_or_fallback) triggerText_slot_or_fallback.c();
			set_attributes(div, div_data);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (triggerText_slot_or_fallback) {
				triggerText_slot_or_fallback.m(div, null);
			}

			/*div_binding*/ ctx[28](div);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div, "mousedown", /*onMousedown*/ ctx[16]),
					listen(div, "focus", /*onFocus*/ ctx[15]),
					listen(div, "blur", /*onBlur*/ ctx[14]),
					listen(div, "keydown", /*onKeydown*/ ctx[13])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (triggerText_slot) {
				if (triggerText_slot.p && (!current || dirty[0] & /*$$scope*/ 1048576)) {
					update_slot_base(
						triggerText_slot,
						triggerText_slot_template,
						ctx,
						/*$$scope*/ ctx[20],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[20])
						: get_slot_changes(triggerText_slot_template, /*$$scope*/ ctx[20], dirty, get_triggerText_slot_changes_1),
						get_triggerText_slot_context_1
					);
				}
			} else {
				if (triggerText_slot_or_fallback && triggerText_slot_or_fallback.p && (!current || dirty[0] & /*triggerText*/ 2048)) {
					triggerText_slot_or_fallback.p(ctx, !current ? [-1, -1] : dirty);
				}
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [
				dirty[0] & /*buttonProps*/ 4096 && /*buttonProps*/ ctx[12],
				(!current || dirty[0] & /*tooltipId*/ 512) && { "aria-describedby": /*tooltipId*/ ctx[9] }
			]));
		},
		i(local) {
			if (current) return;
			transition_in(triggerText_slot_or_fallback, local);
			current = true;
		},
		o(local) {
			transition_out(triggerText_slot_or_fallback, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (triggerText_slot_or_fallback) triggerText_slot_or_fallback.d(detaching);
			/*div_binding*/ ctx[28](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (206:2) {#if !hideIcon}
function create_if_block_1$8(ctx) {
	let div1;
	let t;
	let div0;
	let current;
	let mounted;
	let dispose;
	const triggerText_slot_template = /*#slots*/ ctx[21].triggerText;
	const triggerText_slot = create_slot(triggerText_slot_template, ctx, /*$$scope*/ ctx[20], get_triggerText_slot_context);
	const triggerText_slot_or_fallback = triggerText_slot || fallback_block_1$4(ctx);
	const icon_slot_template = /*#slots*/ ctx[21].icon;
	const icon_slot = create_slot(icon_slot_template, ctx, /*$$scope*/ ctx[20], get_icon_slot_context);
	const icon_slot_or_fallback = icon_slot || fallback_block$6(ctx);
	let div0_levels = [/*buttonProps*/ ctx[12], { "aria-describedby": /*tooltipId*/ ctx[9] }];
	let div0_data = {};

	for (let i = 0; i < div0_levels.length; i += 1) {
		div0_data = assign(div0_data, div0_levels[i]);
	}

	return {
		c() {
			div1 = element("div");
			if (triggerText_slot_or_fallback) triggerText_slot_or_fallback.c();
			t = space();
			div0 = element("div");
			if (icon_slot_or_fallback) icon_slot_or_fallback.c();
			set_attributes(div0, div0_data);
			attr(div1, "id", /*triggerId*/ ctx[10]);
			toggle_class(div1, "bx--tooltip__label", true);
		},
		m(target, anchor) {
			insert(target, div1, anchor);

			if (triggerText_slot_or_fallback) {
				triggerText_slot_or_fallback.m(div1, null);
			}

			append(div1, t);
			append(div1, div0);

			if (icon_slot_or_fallback) {
				icon_slot_or_fallback.m(div0, null);
			}

			/*div0_binding*/ ctx[26](div0);
			/*div1_binding*/ ctx[27](div1);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div0, "mousedown", /*onMousedown*/ ctx[16]),
					listen(div0, "focus", /*onFocus*/ ctx[15]),
					listen(div0, "keydown", /*onKeydown*/ ctx[13])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (triggerText_slot) {
				if (triggerText_slot.p && (!current || dirty[0] & /*$$scope*/ 1048576)) {
					update_slot_base(
						triggerText_slot,
						triggerText_slot_template,
						ctx,
						/*$$scope*/ ctx[20],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[20])
						: get_slot_changes(triggerText_slot_template, /*$$scope*/ ctx[20], dirty, get_triggerText_slot_changes),
						get_triggerText_slot_context
					);
				}
			} else {
				if (triggerText_slot_or_fallback && triggerText_slot_or_fallback.p && (!current || dirty[0] & /*triggerText*/ 2048)) {
					triggerText_slot_or_fallback.p(ctx, !current ? [-1, -1] : dirty);
				}
			}

			if (icon_slot) {
				if (icon_slot.p && (!current || dirty[0] & /*$$scope*/ 1048576)) {
					update_slot_base(
						icon_slot,
						icon_slot_template,
						ctx,
						/*$$scope*/ ctx[20],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[20])
						: get_slot_changes(icon_slot_template, /*$$scope*/ ctx[20], dirty, get_icon_slot_changes),
						get_icon_slot_context
					);
				}
			} else {
				if (icon_slot_or_fallback && icon_slot_or_fallback.p && (!current || dirty[0] & /*icon, iconName*/ 384)) {
					icon_slot_or_fallback.p(ctx, !current ? [-1, -1] : dirty);
				}
			}

			set_attributes(div0, div0_data = get_spread_update(div0_levels, [
				dirty[0] & /*buttonProps*/ 4096 && /*buttonProps*/ ctx[12],
				(!current || dirty[0] & /*tooltipId*/ 512) && { "aria-describedby": /*tooltipId*/ ctx[9] }
			]));

			if (!current || dirty[0] & /*triggerId*/ 1024) {
				attr(div1, "id", /*triggerId*/ ctx[10]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(triggerText_slot_or_fallback, local);
			transition_in(icon_slot_or_fallback, local);
			current = true;
		},
		o(local) {
			transition_out(triggerText_slot_or_fallback, local);
			transition_out(icon_slot_or_fallback, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (triggerText_slot_or_fallback) triggerText_slot_or_fallback.d(detaching);
			if (icon_slot_or_fallback) icon_slot_or_fallback.d(detaching);
			/*div0_binding*/ ctx[26](null);
			/*div1_binding*/ ctx[27](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (232:31) {triggerText}
function fallback_block_2$2(ctx) {
	let t;

	return {
		c() {
			t = text(/*triggerText*/ ctx[11]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*triggerText*/ 2048) set_data(t, /*triggerText*/ ctx[11]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (208:31) {triggerText}
function fallback_block_1$4(ctx) {
	let t;

	return {
		c() {
			t = text(/*triggerText*/ ctx[11]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*triggerText*/ 2048) set_data(t, /*triggerText*/ ctx[11]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (217:26)            
function fallback_block$6(ctx) {
	let switch_instance;
	let switch_instance_anchor;
	let current;
	var switch_value = /*icon*/ ctx[7];

	function switch_props(ctx) {
		return { props: { name: /*iconName*/ ctx[8] } };
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	return {
		c() {
			if (switch_instance) create_component(switch_instance.$$.fragment);
			switch_instance_anchor = empty();
		},
		m(target, anchor) {
			if (switch_instance) {
				mount_component(switch_instance, target, anchor);
			}

			insert(target, switch_instance_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const switch_instance_changes = {};
			if (dirty[0] & /*iconName*/ 256) switch_instance_changes.name = /*iconName*/ ctx[8];

			if (switch_value !== (switch_value = /*icon*/ ctx[7])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, switch_instance_anchor.parentNode, switch_instance_anchor);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(switch_instance_anchor);
			if (switch_instance) destroy_component(switch_instance, detaching);
		}
	};
}

// (235:2) {#if open}
function create_if_block$n(ctx) {
	let div1;
	let span;
	let t;
	let div0;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[21].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[20], null);

	return {
		c() {
			div1 = element("div");
			span = element("span");
			t = space();
			div0 = element("div");
			if (default_slot) default_slot.c();
			toggle_class(span, "bx--tooltip__caret", true);
			attr(div0, "tabindex", "-1");
			attr(div0, "role", "dialog");
			toggle_class(div0, "bx--tooltip__content", true);
			attr(div1, "id", /*tooltipId*/ ctx[9]);
			attr(div1, "data-floating-menu-direction", /*direction*/ ctx[5]);
			toggle_class(div1, "bx--tooltip", true);
			toggle_class(div1, "bx--tooltip--shown", /*open*/ ctx[0]);
			toggle_class(div1, "bx--tooltip--top", /*direction*/ ctx[5] === 'top');
			toggle_class(div1, "bx--tooltip--right", /*direction*/ ctx[5] === 'right');
			toggle_class(div1, "bx--tooltip--bottom", /*direction*/ ctx[5] === 'bottom');
			toggle_class(div1, "bx--tooltip--left", /*direction*/ ctx[5] === 'left');
			toggle_class(div1, "bx--tooltip--align-center", /*align*/ ctx[4] === 'center');
			toggle_class(div1, "bx--tooltip--align-start", /*align*/ ctx[4] === 'start');
			toggle_class(div1, "bx--tooltip--align-end", /*align*/ ctx[4] === 'end');
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, span);
			append(div1, t);
			append(div1, div0);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			/*div1_binding_1*/ ctx[29](div1);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div0, "click", stop_propagation(/*click_handler*/ ctx[22])),
					listen(div0, "mousedown", stop_propagation(/*mousedown_handler*/ ctx[23])),
					listen(div1, "keydown", /*onKeydown*/ ctx[13])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty[0] & /*$$scope*/ 1048576)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[20],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[20])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[20], dirty, null),
						null
					);
				}
			}

			if (!current || dirty[0] & /*tooltipId*/ 512) {
				attr(div1, "id", /*tooltipId*/ ctx[9]);
			}

			if (!current || dirty[0] & /*direction*/ 32) {
				attr(div1, "data-floating-menu-direction", /*direction*/ ctx[5]);
			}

			if (dirty[0] & /*open*/ 1) {
				toggle_class(div1, "bx--tooltip--shown", /*open*/ ctx[0]);
			}

			if (dirty[0] & /*direction*/ 32) {
				toggle_class(div1, "bx--tooltip--top", /*direction*/ ctx[5] === 'top');
			}

			if (dirty[0] & /*direction*/ 32) {
				toggle_class(div1, "bx--tooltip--right", /*direction*/ ctx[5] === 'right');
			}

			if (dirty[0] & /*direction*/ 32) {
				toggle_class(div1, "bx--tooltip--bottom", /*direction*/ ctx[5] === 'bottom');
			}

			if (dirty[0] & /*direction*/ 32) {
				toggle_class(div1, "bx--tooltip--left", /*direction*/ ctx[5] === 'left');
			}

			if (dirty[0] & /*align*/ 16) {
				toggle_class(div1, "bx--tooltip--align-center", /*align*/ ctx[4] === 'center');
			}

			if (dirty[0] & /*align*/ 16) {
				toggle_class(div1, "bx--tooltip--align-start", /*align*/ ctx[4] === 'start');
			}

			if (dirty[0] & /*align*/ 16) {
				toggle_class(div1, "bx--tooltip--align-end", /*align*/ ctx[4] === 'end');
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (default_slot) default_slot.d(detaching);
			/*div1_binding_1*/ ctx[29](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment$s(ctx) {
	let div;
	let current_block_type_index;
	let if_block0;
	let t;
	let div_style_value;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block_1$8, create_else_block$7];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (!/*hideIcon*/ ctx[6]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	let if_block1 = /*open*/ ctx[0] && create_if_block$n(ctx);

	let div_levels = [
		/*$$restProps*/ ctx[17],
		{
			style: div_style_value = "" + ((/*open*/ ctx[0] ? 'z-index: 1;' : '') + /*$$restProps*/ ctx[17].style + "; position: relative;")
		}
	];

	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			set_attributes(div, div_data);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			append(div, t);
			if (if_block1) if_block1.m(div, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(window, "mousedown", /*mousedown_handler_1*/ ctx[24]),
					listen(window, "click", /*click_handler_1*/ ctx[25], true)
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block0 = if_blocks[current_block_type_index];

				if (!if_block0) {
					if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block0.c();
				} else {
					if_block0.p(ctx, dirty);
				}

				transition_in(if_block0, 1);
				if_block0.m(div, t);
			}

			if (/*open*/ ctx[0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*open*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block$n(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [
				dirty[0] & /*$$restProps*/ 131072 && /*$$restProps*/ ctx[17],
				(!current || dirty[0] & /*open, $$restProps*/ 131073 && div_style_value !== (div_style_value = "" + ((/*open*/ ctx[0] ? 'z-index: 1;' : '') + /*$$restProps*/ ctx[17].style + "; position: relative;"))) && { style: div_style_value }
			]));
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
			if (if_block1) if_block1.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$t($$self, $$props, $$invalidate) {
	let buttonProps;

	const omit_props_names = [
		"align","direction","open","hideIcon","icon","iconDescription","iconName","tabindex","tooltipId","triggerId","triggerText","ref","refTooltip","refIcon"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { align = "center" } = $$props;
	let { direction = "bottom" } = $$props;
	let { open = false } = $$props;
	let { hideIcon = false } = $$props;
	let { icon = Information } = $$props;
	let { iconDescription = "" } = $$props;
	let { iconName = "" } = $$props;
	let { tabindex = "0" } = $$props;
	let { tooltipId = "ccs-" + Math.random().toString(36) } = $$props;
	let { triggerId = "ccs-" + Math.random().toString(36) } = $$props;
	let { triggerText = "" } = $$props;
	let { ref = null } = $$props;
	let { refTooltip = null } = $$props;
	let { refIcon = null } = $$props;
	const dispatch = createEventDispatcher();
	const tooltipOpen = writable(open);
	setContext("Tooltip", { tooltipOpen });

	function onKeydown(e) {
		if (e.key === "Escape" || e.key === "Tab") {
			e.stopPropagation();
			if (e.key === "Escape") refIcon?.focus();
			$$invalidate(0, open = false);
		} else if (e.key === " " || e.key === "Enter") {
			e.stopPropagation();
			e.preventDefault();
			$$invalidate(0, open = true);
		}
	}

	function onBlur({ relatedTarget }) {
		if (refTooltip && !refTooltip.contains(relatedTarget)) {
			$$invalidate(0, open = false);
		}
	}

	function onFocus() {
		$$invalidate(0, open = true);
	}

	function onMousedown() {
		// determine the desired state before the focus event triggers.
		const shouldClose = open;

		// ensure changes are scheduled at the end, i.e. after the possible focus event.
		setTimeout(() => {
			$$invalidate(0, open = shouldClose ? false : true);
		});
	}

	afterUpdate(() => {
		if (open) {
			const button = ref.getBoundingClientRect();
			const tooltip = refTooltip.getBoundingClientRect();
			let iconWidth = 16;
			let iconHeight = 16;

			if (refIcon) {
				const icon = refIcon.getBoundingClientRect();
				iconWidth = icon.width;
				iconHeight = icon.height;
			}

			let offsetX = 0;
			let offsetY = 0;

			switch (direction) {
				case "bottom":
					if (hideIcon) {
						offsetX = -1 * (tooltip.width / 2 - button.width / 2);
					} else {
						offsetX = -1 * (tooltip.width / 2 - button.width + iconWidth / 2);
					}
					offsetY = iconHeight / 2;
					break;
				case "right":
					offsetX = button.width + 6;
					offsetY = -1 * (tooltip.height / 2 + iconWidth / 2 - 3);
					break;
				case "left":
					if (hideIcon) {
						offsetX = -1 * (tooltip.width + 6 + 1);
					} else {
						offsetX = -1 * (tooltip.width - button.width + iconWidth + 8);
					}
					offsetY = -1 * (tooltip.height / 2 + button.height) - 2;
					break;
				case "top":
					if (hideIcon) {
						offsetX = -1 * (tooltip.width / 2 - button.width / 2);
					} else {
						offsetX = -1 * (tooltip.width / 2 - button.width + iconWidth / 2 + 1);
					}
					offsetY = -1 * (tooltip.height + button.height + iconWidth / 2 - 1);
					break;
			}

			$$invalidate(2, refTooltip.style.left = offsetX + "px", refTooltip);
			$$invalidate(2, refTooltip.style.marginTop = offsetY + "px", refTooltip);
		}
	});

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mousedown_handler(event) {
		bubble.call(this, $$self, event);
	}

	const mousedown_handler_1 = ({ target }) => {
		if (open) {
			if (target.contains(refTooltip)) {
				if (refIcon) {
					refIcon.focus();
				} else if (ref) {
					ref.focus();
				}
			}
		}
	};

	const click_handler_1 = ({ target }) => {
		if (open && !ref.contains(target) && !refTooltip.contains(target)) {
			setTimeout(() => {
				$$invalidate(0, open = false);
			});
		}
	};

	function div0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refIcon = $$value;
			$$invalidate(3, refIcon);
		});
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			ref = $$value;
			$$invalidate(1, ref);
		});
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			ref = $$value;
			$$invalidate(1, ref);
		});
	}

	function div1_binding_1($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refTooltip = $$value;
			$$invalidate(2, refTooltip);
		});
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(17, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('align' in $$new_props) $$invalidate(4, align = $$new_props.align);
		if ('direction' in $$new_props) $$invalidate(5, direction = $$new_props.direction);
		if ('open' in $$new_props) $$invalidate(0, open = $$new_props.open);
		if ('hideIcon' in $$new_props) $$invalidate(6, hideIcon = $$new_props.hideIcon);
		if ('icon' in $$new_props) $$invalidate(7, icon = $$new_props.icon);
		if ('iconDescription' in $$new_props) $$invalidate(18, iconDescription = $$new_props.iconDescription);
		if ('iconName' in $$new_props) $$invalidate(8, iconName = $$new_props.iconName);
		if ('tabindex' in $$new_props) $$invalidate(19, tabindex = $$new_props.tabindex);
		if ('tooltipId' in $$new_props) $$invalidate(9, tooltipId = $$new_props.tooltipId);
		if ('triggerId' in $$new_props) $$invalidate(10, triggerId = $$new_props.triggerId);
		if ('triggerText' in $$new_props) $$invalidate(11, triggerText = $$new_props.triggerText);
		if ('ref' in $$new_props) $$invalidate(1, ref = $$new_props.ref);
		if ('refTooltip' in $$new_props) $$invalidate(2, refTooltip = $$new_props.refTooltip);
		if ('refIcon' in $$new_props) $$invalidate(3, refIcon = $$new_props.refIcon);
		if ('$$scope' in $$new_props) $$invalidate(20, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*open*/ 1) {
			 tooltipOpen.set(open);
		}

		if ($$self.$$.dirty[0] & /*open*/ 1) {
			 dispatch(open ? "open" : "close");
		}

		 $$invalidate(12, buttonProps = {
			role: "button",
			"aria-haspopup": "true",
			id: hideIcon ? triggerId : undefined,
			class: hideIcon ? "bx--tooltip__label" : "bx--tooltip__trigger",
			"aria-expanded": open,
			"aria-describedby": open ? tooltipId : undefined,
			"aria-labelledby": triggerText ? triggerId : undefined,
			"aria-label": triggerText ? undefined : iconDescription,
			tabindex,
			style: hideIcon ? $$restProps.style : undefined
		});
	};

	return [
		open,
		ref,
		refTooltip,
		refIcon,
		align,
		direction,
		hideIcon,
		icon,
		iconName,
		tooltipId,
		triggerId,
		triggerText,
		buttonProps,
		onKeydown,
		onBlur,
		onFocus,
		onMousedown,
		$$restProps,
		iconDescription,
		tabindex,
		$$scope,
		slots,
		click_handler,
		mousedown_handler,
		mousedown_handler_1,
		click_handler_1,
		div0_binding,
		div1_binding,
		div_binding,
		div1_binding_1
	];
}

class Tooltip extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$t,
			create_fragment$s,
			safe_not_equal,
			{
				align: 4,
				direction: 5,
				open: 0,
				hideIcon: 6,
				icon: 7,
				iconDescription: 18,
				iconName: 8,
				tabindex: 19,
				tooltipId: 9,
				triggerId: 10,
				triggerText: 11,
				ref: 1,
				refTooltip: 2,
				refIcon: 3
			},
			null,
			[-1, -1]
		);
	}
}

/* node_modules/carbon-components-svelte/src/UIShell/Content.svelte generated by Svelte v3.47.0 */

function create_fragment$t(ctx) {
	let main;
	let current;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);
	let main_levels = [{ id: /*id*/ ctx[0] }, /*$$restProps*/ ctx[1]];
	let main_data = {};

	for (let i = 0; i < main_levels.length; i += 1) {
		main_data = assign(main_data, main_levels[i]);
	}

	return {
		c() {
			main = element("main");
			if (default_slot) default_slot.c();
			set_attributes(main, main_data);
			toggle_class(main, "bx--content", true);
		},
		m(target, anchor) {
			insert(target, main, anchor);

			if (default_slot) {
				default_slot.m(main, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}

			set_attributes(main, main_data = get_spread_update(main_levels, [
				(!current || dirty & /*id*/ 1) && { id: /*id*/ ctx[0] },
				dirty & /*$$restProps*/ 2 && /*$$restProps*/ ctx[1]
			]));

			toggle_class(main, "bx--content", true);
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance$u($$self, $$props, $$invalidate) {
	const omit_props_names = ["id"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { id = "main-content" } = $$props;

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(1, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('id' in $$new_props) $$invalidate(0, id = $$new_props.id);
		if ('$$scope' in $$new_props) $$invalidate(2, $$scope = $$new_props.$$scope);
	};

	return [id, $$restProps, $$scope, slots];
}

class Content extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$u, create_fragment$t, safe_not_equal, { id: 0 });
	}
}

export { Button, Checkbox, Column, Content, Grid, InlineLoading, InlineNotification, Loading, Row, TextInput, Theme, ToastNotification, Tooltip };
