/* src/features/financeDataForm/presentation/components/ClosingButton.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	safe_not_equal,
	text,
	transition_in,
	transition_out
} from "../../../../../_snowpack/pkg/svelte/internal.js";

import { Button } from "../../../../../_snowpack/pkg/carbon-components-svelte.js";

function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Zurück zum Exposé");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let button;
	let current;

	button = new Button({
			props: {
				style: "border-radius: 8px;\n  padding: 50px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  font-size: 20px;\n  font-weight: 600;\n  background-color: #0b3478",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	button.$on("click", /*handleClick*/ ctx[0]);

	return {
		c() {
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const button_changes = {};

			if (dirty & /*$$scope*/ 2) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
		}
	};
}

function instance($$self) {
	const handleClick = () => {
		window.top?.postMessage("iFrameClose", "*");
	};

	return [handleClick];
}

class ClosingButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ClosingButton;