/* src/presentation/routes/ContactPage.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	init,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import ContactComponent from "../../features/financeDataForm/presentation/ContactComponent.svelte.js";

function create_fragment(ctx) {
	let contactcomponent;
	let current;
	contactcomponent = new ContactComponent({});

	return {
		c() {
			create_component(contactcomponent.$$.fragment);
		},
		m(target, anchor) {
			mount_component(contactcomponent, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(contactcomponent.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(contactcomponent.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(contactcomponent, detaching);
		}
	};
}

class ContactPage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ContactPage;