import './ServiceTimesComponent.svelte.css';
/* src/features/financeDataForm/presentation/ServiceTimesComponent.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { Column, Grid, Row, Tooltip } from "../../../../_snowpack/pkg/carbon-components-svelte.js";

function create_default_slot_22(ctx) {
	let t;

	return {
		c() {
			t = text("Montag:");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (34:8) <Column>
function create_default_slot_21(ctx) {
	let t_value = /*getTimes*/ ctx[1](1) + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (32:6) <Row>
function create_default_slot_20(ctx) {
	let column0;
	let t;
	let column1;
	let current;

	column0 = new Column({
			props: {
				$$slots: { default: [create_default_slot_22] },
				$$scope: { ctx }
			}
		});

	column1 = new Column({
			props: {
				$$slots: { default: [create_default_slot_21] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(column0.$$.fragment);
			t = space();
			create_component(column1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(column0, target, anchor);
			insert(target, t, anchor);
			mount_component(column1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const column0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column0_changes.$$scope = { dirty, ctx };
			}

			column0.$set(column0_changes);
			const column1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column1_changes.$$scope = { dirty, ctx };
			}

			column1.$set(column1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(column0.$$.fragment, local);
			transition_in(column1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(column0.$$.fragment, local);
			transition_out(column1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(column0, detaching);
			if (detaching) detach(t);
			destroy_component(column1, detaching);
		}
	};
}

// (37:8) <Column>
function create_default_slot_19(ctx) {
	let t;

	return {
		c() {
			t = text("Dienstag:");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (38:8) <Column>
function create_default_slot_18(ctx) {
	let t_value = /*getTimes*/ ctx[1](2) + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (36:6) <Row>
function create_default_slot_17(ctx) {
	let column0;
	let t;
	let column1;
	let current;

	column0 = new Column({
			props: {
				$$slots: { default: [create_default_slot_19] },
				$$scope: { ctx }
			}
		});

	column1 = new Column({
			props: {
				$$slots: { default: [create_default_slot_18] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(column0.$$.fragment);
			t = space();
			create_component(column1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(column0, target, anchor);
			insert(target, t, anchor);
			mount_component(column1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const column0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column0_changes.$$scope = { dirty, ctx };
			}

			column0.$set(column0_changes);
			const column1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column1_changes.$$scope = { dirty, ctx };
			}

			column1.$set(column1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(column0.$$.fragment, local);
			transition_in(column1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(column0.$$.fragment, local);
			transition_out(column1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(column0, detaching);
			if (detaching) detach(t);
			destroy_component(column1, detaching);
		}
	};
}

// (41:8) <Column>
function create_default_slot_16(ctx) {
	let t;

	return {
		c() {
			t = text("Mittwoch:");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (42:8) <Column>
function create_default_slot_15(ctx) {
	let t_value = /*getTimes*/ ctx[1](3) + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (40:6) <Row>
function create_default_slot_14(ctx) {
	let column0;
	let t;
	let column1;
	let current;

	column0 = new Column({
			props: {
				$$slots: { default: [create_default_slot_16] },
				$$scope: { ctx }
			}
		});

	column1 = new Column({
			props: {
				$$slots: { default: [create_default_slot_15] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(column0.$$.fragment);
			t = space();
			create_component(column1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(column0, target, anchor);
			insert(target, t, anchor);
			mount_component(column1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const column0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column0_changes.$$scope = { dirty, ctx };
			}

			column0.$set(column0_changes);
			const column1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column1_changes.$$scope = { dirty, ctx };
			}

			column1.$set(column1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(column0.$$.fragment, local);
			transition_in(column1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(column0.$$.fragment, local);
			transition_out(column1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(column0, detaching);
			if (detaching) detach(t);
			destroy_component(column1, detaching);
		}
	};
}

// (45:8) <Column>
function create_default_slot_13(ctx) {
	let t;

	return {
		c() {
			t = text("Donnerstag:");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (46:8) <Column>
function create_default_slot_12(ctx) {
	let t_value = /*getTimes*/ ctx[1](4) + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (44:6) <Row>
function create_default_slot_11(ctx) {
	let column0;
	let t;
	let column1;
	let current;

	column0 = new Column({
			props: {
				$$slots: { default: [create_default_slot_13] },
				$$scope: { ctx }
			}
		});

	column1 = new Column({
			props: {
				$$slots: { default: [create_default_slot_12] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(column0.$$.fragment);
			t = space();
			create_component(column1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(column0, target, anchor);
			insert(target, t, anchor);
			mount_component(column1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const column0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column0_changes.$$scope = { dirty, ctx };
			}

			column0.$set(column0_changes);
			const column1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column1_changes.$$scope = { dirty, ctx };
			}

			column1.$set(column1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(column0.$$.fragment, local);
			transition_in(column1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(column0.$$.fragment, local);
			transition_out(column1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(column0, detaching);
			if (detaching) detach(t);
			destroy_component(column1, detaching);
		}
	};
}

// (49:8) <Column>
function create_default_slot_10(ctx) {
	let t;

	return {
		c() {
			t = text("Freitag:");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (50:8) <Column>
function create_default_slot_9(ctx) {
	let t_value = /*getTimes*/ ctx[1](5) + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (48:6) <Row>
function create_default_slot_8(ctx) {
	let column0;
	let t;
	let column1;
	let current;

	column0 = new Column({
			props: {
				$$slots: { default: [create_default_slot_10] },
				$$scope: { ctx }
			}
		});

	column1 = new Column({
			props: {
				$$slots: { default: [create_default_slot_9] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(column0.$$.fragment);
			t = space();
			create_component(column1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(column0, target, anchor);
			insert(target, t, anchor);
			mount_component(column1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const column0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column0_changes.$$scope = { dirty, ctx };
			}

			column0.$set(column0_changes);
			const column1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column1_changes.$$scope = { dirty, ctx };
			}

			column1.$set(column1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(column0.$$.fragment, local);
			transition_in(column1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(column0.$$.fragment, local);
			transition_out(column1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(column0, detaching);
			if (detaching) detach(t);
			destroy_component(column1, detaching);
		}
	};
}

// (53:8) <Column>
function create_default_slot_7(ctx) {
	let t;

	return {
		c() {
			t = text("Samstag:");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (54:8) <Column>
function create_default_slot_6(ctx) {
	let t_value = /*getTimes*/ ctx[1](6) + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (52:6) <Row>
function create_default_slot_5(ctx) {
	let column0;
	let t;
	let column1;
	let current;

	column0 = new Column({
			props: {
				$$slots: { default: [create_default_slot_7] },
				$$scope: { ctx }
			}
		});

	column1 = new Column({
			props: {
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(column0.$$.fragment);
			t = space();
			create_component(column1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(column0, target, anchor);
			insert(target, t, anchor);
			mount_component(column1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const column0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column0_changes.$$scope = { dirty, ctx };
			}

			column0.$set(column0_changes);
			const column1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column1_changes.$$scope = { dirty, ctx };
			}

			column1.$set(column1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(column0.$$.fragment, local);
			transition_in(column1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(column0.$$.fragment, local);
			transition_out(column1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(column0, detaching);
			if (detaching) detach(t);
			destroy_component(column1, detaching);
		}
	};
}

// (57:8) <Column>
function create_default_slot_4(ctx) {
	let t;

	return {
		c() {
			t = text("Sonntag:");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (58:8) <Column>
function create_default_slot_3(ctx) {
	let t_value = /*getTimes*/ ctx[1](7) + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (56:6) <Row style={"margin-bottom:0px"}>
function create_default_slot_2(ctx) {
	let column0;
	let t;
	let column1;
	let current;

	column0 = new Column({
			props: {
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			}
		});

	column1 = new Column({
			props: {
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(column0.$$.fragment);
			t = space();
			create_component(column1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(column0, target, anchor);
			insert(target, t, anchor);
			mount_component(column1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const column0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column0_changes.$$scope = { dirty, ctx };
			}

			column0.$set(column0_changes);
			const column1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				column1_changes.$$scope = { dirty, ctx };
			}

			column1.$set(column1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(column0.$$.fragment, local);
			transition_in(column1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(column0.$$.fragment, local);
			transition_out(column1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(column0, detaching);
			if (detaching) detach(t);
			destroy_component(column1, detaching);
		}
	};
}

// (31:4) <Grid>
function create_default_slot_1(ctx) {
	let row0;
	let t0;
	let row1;
	let t1;
	let row2;
	let t2;
	let row3;
	let t3;
	let row4;
	let t4;
	let row5;
	let t5;
	let row6;
	let current;

	row0 = new Row({
			props: {
				$$slots: { default: [create_default_slot_20] },
				$$scope: { ctx }
			}
		});

	row1 = new Row({
			props: {
				$$slots: { default: [create_default_slot_17] },
				$$scope: { ctx }
			}
		});

	row2 = new Row({
			props: {
				$$slots: { default: [create_default_slot_14] },
				$$scope: { ctx }
			}
		});

	row3 = new Row({
			props: {
				$$slots: { default: [create_default_slot_11] },
				$$scope: { ctx }
			}
		});

	row4 = new Row({
			props: {
				$$slots: { default: [create_default_slot_8] },
				$$scope: { ctx }
			}
		});

	row5 = new Row({
			props: {
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	row6 = new Row({
			props: {
				style: "margin-bottom:0px",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(row0.$$.fragment);
			t0 = space();
			create_component(row1.$$.fragment);
			t1 = space();
			create_component(row2.$$.fragment);
			t2 = space();
			create_component(row3.$$.fragment);
			t3 = space();
			create_component(row4.$$.fragment);
			t4 = space();
			create_component(row5.$$.fragment);
			t5 = space();
			create_component(row6.$$.fragment);
		},
		m(target, anchor) {
			mount_component(row0, target, anchor);
			insert(target, t0, anchor);
			mount_component(row1, target, anchor);
			insert(target, t1, anchor);
			mount_component(row2, target, anchor);
			insert(target, t2, anchor);
			mount_component(row3, target, anchor);
			insert(target, t3, anchor);
			mount_component(row4, target, anchor);
			insert(target, t4, anchor);
			mount_component(row5, target, anchor);
			insert(target, t5, anchor);
			mount_component(row6, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const row0_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row0_changes.$$scope = { dirty, ctx };
			}

			row0.$set(row0_changes);
			const row1_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row1_changes.$$scope = { dirty, ctx };
			}

			row1.$set(row1_changes);
			const row2_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row2_changes.$$scope = { dirty, ctx };
			}

			row2.$set(row2_changes);
			const row3_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row3_changes.$$scope = { dirty, ctx };
			}

			row3.$set(row3_changes);
			const row4_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row4_changes.$$scope = { dirty, ctx };
			}

			row4.$set(row4_changes);
			const row5_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row5_changes.$$scope = { dirty, ctx };
			}

			row5.$set(row5_changes);
			const row6_changes = {};

			if (dirty & /*$$scope*/ 16) {
				row6_changes.$$scope = { dirty, ctx };
			}

			row6.$set(row6_changes);
		},
		i(local) {
			if (current) return;
			transition_in(row0.$$.fragment, local);
			transition_in(row1.$$.fragment, local);
			transition_in(row2.$$.fragment, local);
			transition_in(row3.$$.fragment, local);
			transition_in(row4.$$.fragment, local);
			transition_in(row5.$$.fragment, local);
			transition_in(row6.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(row0.$$.fragment, local);
			transition_out(row1.$$.fragment, local);
			transition_out(row2.$$.fragment, local);
			transition_out(row3.$$.fragment, local);
			transition_out(row4.$$.fragment, local);
			transition_out(row5.$$.fragment, local);
			transition_out(row6.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(row0, detaching);
			if (detaching) detach(t0);
			destroy_component(row1, detaching);
			if (detaching) detach(t1);
			destroy_component(row2, detaching);
			if (detaching) detach(t2);
			destroy_component(row3, detaching);
			if (detaching) detach(t3);
			destroy_component(row4, detaching);
			if (detaching) detach(t4);
			destroy_component(row5, detaching);
			if (detaching) detach(t5);
			destroy_component(row6, detaching);
		}
	};
}

// (25:2) <Tooltip     hideIcon     triggerText="Alle Servicezeiten"     open={tooltipOpen}     on:click={handleTooltipOpen}   >
function create_default_slot(ctx) {
	let grid;
	let current;

	grid = new Grid({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(grid.$$.fragment);
		},
		m(target, anchor) {
			mount_component(grid, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const grid_changes = {};

			if (dirty & /*$$scope*/ 16) {
				grid_changes.$$scope = { dirty, ctx };
			}

			grid.$set(grid_changes);
		},
		i(local) {
			if (current) return;
			transition_in(grid.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(grid.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(grid, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let tooltip;
	let current;

	tooltip = new Tooltip({
			props: {
				hideIcon: true,
				triggerText: "Alle Servicezeiten",
				open: /*tooltipOpen*/ ctx[0],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	tooltip.$on("click", /*handleTooltipOpen*/ ctx[2]);

	return {
		c() {
			div = element("div");
			create_component(tooltip.$$.fragment);
			attr(div, "class", "tooltipContainer svelte-xzp3eh");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(tooltip, div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const tooltip_changes = {};
			if (dirty & /*tooltipOpen*/ 1) tooltip_changes.open = /*tooltipOpen*/ ctx[0];

			if (dirty & /*$$scope*/ 16) {
				tooltip_changes.$$scope = { dirty, ctx };
			}

			tooltip.$set(tooltip_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tooltip.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tooltip.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(tooltip);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { ServiceTimeMap } = $$props;
	let tooltipOpen = false;

	const getTimes = index => {
		if (ServiceTimeMap.get(index) !== undefined) {
			const timesArr = ServiceTimeMap.get(index)?.map(sT => sT.from + "-" + sT.to);
			let timesString = timesArr?.at(0);

			if (timesArr !== undefined && timesArr.length > 1) {
				timesString = timesArr.join(" & ") + "\n";
			}

			return timesString + " Uhr";
		} else {
			return "Keine Servicezeit";
		}
	};

	const handleTooltipOpen = e => {
		e.preventDefault();
		e.stopPropagation();
		$$invalidate(0, tooltipOpen = !tooltipOpen);
	};

	$$self.$$set = $$props => {
		if ('ServiceTimeMap' in $$props) $$invalidate(3, ServiceTimeMap = $$props.ServiceTimeMap);
	};

	return [tooltipOpen, getTimes, handleTooltipOpen, ServiceTimeMap];
}

class ServiceTimesComponent extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { ServiceTimeMap: 3 });
	}
}

export default ServiceTimesComponent;