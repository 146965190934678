import notificationController from "../../application/notifications/application/NotificationControllerImpl.js";
class PromiseWrapperImpl {
  constructor() {
    this.notificationController = notificationController;
  }
  wrapPromise(promise, updater) {
    const wrapperPromise = new Promise(async (res, rej) => {
      try {
        const response = await promise;
        updater(response);
        res(response);
      } catch (e) {
        if (e instanceof Error) {
          this.notificationController.add({
            type: "error",
            title: e.name,
            caption: e.stack ?? `${new Date()}`,
            subtitle: e.message
          });
        }
        rej(e);
      }
    });
    return wrapperPromise;
  }
}
export default PromiseWrapperImpl;
