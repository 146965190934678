export const initialFormValues = {
  equityTotal: void 0,
  remainingDebtTotal: void 0,
  employedSinceDate1: void 0,
  monthlyNetIncome1: void 0,
  birthDate1: "",
  employedSinceDate2: void 0,
  monthlyNetIncome2: void 0,
  birthDate2: void 0,
  isDataForwardingToRealEstateAgentOk: false,
  isHomeContactWanted: false
};
export default initialFormValues;
