import ControllerImpl from "../../../application/controller/ControllerImpl.js";
import initialFormValues from "../domain/initialFormValues.js";
export class FormControllerImpl extends ControllerImpl {
  constructor() {
    super(initialFormValues);
  }
  set(data) {
    this._setState(data);
  }
}
const formController = new FormControllerImpl();
export default formController;
