import { S as SvelteComponent, i as init, s as safe_not_equal, L as svg_element, m as text, a as insert, o as append, z as set_data, d as detach, b as assign, K as attr, M as set_svg_attributes, k as get_spread_update, n as noop, c as compute_rest_props, f as exclude_internal_props } from './common/index-05235cee.js';

/* node_modules/carbon-icons-svelte/lib/Email.svelte generated by Svelte v3.47.0 */

function create_if_block(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment(ctx) {
	let svg;
	let path;
	let if_block = /*title*/ ctx[1] && create_if_block(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path = svg_element("path");
			attr(path, "d", "M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6ZM25.8,8,16,14.78,6.2,8ZM4,24V8.91l11.43,7.91a1,1,0,0,0,1.14,0L28,8.91V24Z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(svg, path);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class Email extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-icons-svelte/lib/Phone.svelte generated by Svelte v3.47.0 */

function create_if_block$1(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$1(ctx) {
	let svg;
	let path;
	let if_block = /*title*/ ctx[1] && create_if_block$1(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path = svg_element("path");
			attr(path, "d", "M26,29h-.17C6.18,27.87,3.39,11.29,3,6.23A3,3,0,0,1,5.76,3h5.51a2,2,0,0,1,1.86,1.26L14.65,8a2,2,0,0,1-.44,2.16l-2.13,2.15a9.37,9.37,0,0,0,7.58,7.6l2.17-2.15A2,2,0,0,1,24,17.35l3.77,1.51A2,2,0,0,1,29,20.72V26A3,3,0,0,1,26,29ZM6,5A1,1,0,0,0,5,6v.08C5.46,12,8.41,26,25.94,27A1,1,0,0,0,27,26.06V20.72l-3.77-1.51-2.87,2.85L19.88,22C11.18,20.91,10,12.21,10,12.12l-.06-.48,2.84-2.87L11.28,5Z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$1(ctx);
					if_block.c();
					if_block.m(svg, path);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class Phone extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, { size: 0, title: 1 });
	}
}

/* node_modules/carbon-icons-svelte/lib/Time.svelte generated by Svelte v3.47.0 */

function create_if_block$2(ctx) {
	let title_1;
	let t;

	return {
		c() {
			title_1 = svg_element("title");
			t = text(/*title*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, title_1, anchor);
			append(title_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 2) set_data(t, /*title*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(title_1);
		}
	};
}

function create_fragment$2(ctx) {
	let svg;
	let path0;
	let path1;
	let if_block = /*title*/ ctx[1] && create_if_block$2(ctx);

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ viewBox: "0 0 32 32" },
		{ fill: "currentColor" },
		{ preserveAspectRatio: "xMidYMid meet" },
		{ width: /*size*/ ctx[0] },
		{ height: /*size*/ ctx[0] },
		/*attributes*/ ctx[2],
		/*$$restProps*/ ctx[3]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			if (if_block) if_block.c();
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "d", "M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z");
			attr(path1, "d", "M20.59 22L15 16.41 15 7 17 7 17 15.58 22 20.59 20.59 22z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			if (if_block) if_block.m(svg, null);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, [dirty]) {
			if (/*title*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$2(ctx);
					if_block.c();
					if_block.m(svg, path0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			set_svg_attributes(svg, svg_data = get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ viewBox: "0 0 32 32" },
				{ fill: "currentColor" },
				{ preserveAspectRatio: "xMidYMid meet" },
				dirty & /*size*/ 1 && { width: /*size*/ ctx[0] },
				dirty & /*size*/ 1 && { height: /*size*/ ctx[0] },
				dirty & /*attributes*/ 4 && /*attributes*/ ctx[2],
				dirty & /*$$restProps*/ 8 && /*$$restProps*/ ctx[3]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (if_block) if_block.d();
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let labelled;
	let attributes;
	const omit_props_names = ["size","title"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { size = 16 } = $$props;
	let { title = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		$$invalidate(3, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('size' in $$new_props) $$invalidate(0, size = $$new_props.size);
		if ('title' in $$new_props) $$invalidate(1, title = $$new_props.title);
	};

	$$self.$$.update = () => {
		 $$invalidate(4, labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title);

		 $$invalidate(2, attributes = {
			"aria-hidden": labelled ? undefined : true,
			role: labelled ? "img" : undefined,
			focusable: Number($$props["tabindex"]) === 0 ? true : undefined
		});
	};

	$$props = exclude_internal_props($$props);
	return [size, title, attributes, $$restProps, labelled];
}

class Time extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$2, create_fragment$2, safe_not_equal, { size: 0, title: 1 });
	}
}

export { Email, Phone, Time };
