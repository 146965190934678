import './App.svelte.css';
/* src/presentation/App.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import "../../_snowpack/pkg/@carbon/charts/styles.min.css";
import { Content, Theme } from "../../_snowpack/pkg/carbon-components-svelte.js";
import { navigate, Route, Router } from "../../_snowpack/pkg/svelte-routing.js";
import ToastNotification from "../application/notifications/presentation/ToastNotification.svelte.js";
import HomecloudIcon from "../features/financeDataForm/presentation/icons/HomecloudIcon.svelte.js";
import ContactPage from "./routes/ContactPage.svelte.js";
import HomeRoute from "./routes/HomeRoute.svelte.js";
import ResultPage from "./routes/ResultPage.svelte.js";
import themeColors from "./theme/colors.js";

function create_default_slot_2(ctx) {
	let route0;
	let t0;
	let route1;
	let t1;
	let route2;
	let current;

	route0 = new Route({
			props: { path: "/", component: HomeRoute }
		});

	route1 = new Route({
			props: { path: "result", component: ResultPage }
		});

	route2 = new Route({
			props: { path: "contact", component: ContactPage }
		});

	return {
		c() {
			create_component(route0.$$.fragment);
			t0 = space();
			create_component(route1.$$.fragment);
			t1 = space();
			create_component(route2.$$.fragment);
		},
		m(target, anchor) {
			mount_component(route0, target, anchor);
			insert(target, t0, anchor);
			mount_component(route1, target, anchor);
			insert(target, t1, anchor);
			mount_component(route2, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(route0.$$.fragment, local);
			transition_in(route1.$$.fragment, local);
			transition_in(route2.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(route0.$$.fragment, local);
			transition_out(route1.$$.fragment, local);
			transition_out(route2.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(route0, detaching);
			if (detaching) detach(t0);
			destroy_component(route1, detaching);
			if (detaching) detach(t1);
			destroy_component(route2, detaching);
		}
	};
}

// (18:2) <Theme tokens={themeColors}>
function create_default_slot_1(ctx) {
	let content;
	let t0;
	let div7;
	let div0;
	let homecloudicon;
	let t1;
	let h3;
	let t3;
	let div6;
	let div1;
	let t5;
	let div2;
	let t6;
	let div3;
	let t8;
	let div4;
	let t9;
	let div5;
	let current;
	let mounted;
	let dispose;

	content = new Content({
			props: {
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	homecloudicon = new HomecloudIcon({});

	return {
		c() {
			create_component(content.$$.fragment);
			t0 = space();
			div7 = element("div");
			div0 = element("div");
			create_component(homecloudicon.$$.fragment);
			t1 = space();
			h3 = element("h3");
			h3.textContent = "Homecloud";
			t3 = space();
			div6 = element("div");
			div1 = element("div");
			div1.textContent = "Impressum";
			t5 = space();
			div2 = element("div");
			t6 = space();
			div3 = element("div");
			div3.textContent = "AGB";
			t8 = space();
			div4 = element("div");
			t9 = space();
			div5 = element("div");
			div5.textContent = "Datenschutz";
			attr(div0, "class", "homeCloudContainer");
			attr(div1, "class", "link");
			attr(div2, "class", "divider");
			attr(div3, "class", "link");
			attr(div4, "class", "divider");
			attr(div5, "class", "link");
			attr(div6, "class", "linkContainer");
			attr(div7, "class", "bottomBar");
		},
		m(target, anchor) {
			mount_component(content, target, anchor);
			insert(target, t0, anchor);
			insert(target, div7, anchor);
			append(div7, div0);
			mount_component(homecloudicon, div0, null);
			append(div0, t1);
			append(div0, h3);
			append(div7, t3);
			append(div7, div6);
			append(div6, div1);
			append(div6, t5);
			append(div6, div2);
			append(div6, t6);
			append(div6, div3);
			append(div6, t8);
			append(div6, div4);
			append(div6, t9);
			append(div6, div5);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div1, "click", /*click_handler*/ ctx[1]),
					listen(div3, "click", /*click_handler_1*/ ctx[2]),
					listen(div5, "click", /*click_handler_2*/ ctx[3])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			const content_changes = {};

			if (dirty & /*$$scope*/ 16) {
				content_changes.$$scope = { dirty, ctx };
			}

			content.$set(content_changes);
		},
		i(local) {
			if (current) return;
			transition_in(content.$$.fragment, local);
			transition_in(homecloudicon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(content.$$.fragment, local);
			transition_out(homecloudicon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(content, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div7);
			destroy_component(homecloudicon);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (17:0) <Router>
function create_default_slot(ctx) {
	let theme;
	let t;
	let toastnotification;
	let current;

	theme = new Theme({
			props: {
				tokens: themeColors,
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	toastnotification = new ToastNotification({});

	return {
		c() {
			create_component(theme.$$.fragment);
			t = space();
			create_component(toastnotification.$$.fragment);
		},
		m(target, anchor) {
			mount_component(theme, target, anchor);
			insert(target, t, anchor);
			mount_component(toastnotification, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const theme_changes = {};

			if (dirty & /*$$scope*/ 16) {
				theme_changes.$$scope = { dirty, ctx };
			}

			theme.$set(theme_changes);
		},
		i(local) {
			if (current) return;
			transition_in(theme.$$.fragment, local);
			transition_in(toastnotification.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(theme.$$.fragment, local);
			transition_out(toastnotification.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(theme, detaching);
			if (detaching) detach(t);
			destroy_component(toastnotification, detaching);
		}
	};
}

function create_fragment(ctx) {
	let router;
	let current;

	router = new Router({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(router.$$.fragment);
		},
		m(target, anchor) {
			mount_component(router, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const router_changes = {};

			if (dirty & /*$$scope*/ 16) {
				router_changes.$$scope = { dirty, ctx };
			}

			router.$set(router_changes);
		},
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(router, detaching);
		}
	};
}

function openInNewTap(url) {
	window.open(url, "_blank")?.focus();
}

function instance($$self, $$props, $$invalidate) {
	let currentPath;
	const click_handler = () => openInNewTap("https://www.homecloud.de/impressum");
	const click_handler_1 = () => openInNewTap("https://www.homecloud.de/nutzungsbedingungen");
	const click_handler_2 = () => openInNewTap("https://www.homecloud.de/datenschutz");

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*currentPath*/ 1) {
			$: navigate(currentPath);
		}
	};

	$: $$invalidate(0, currentPath = "");
	return [currentPath, click_handler, click_handler_1, click_handler_2];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;