import {format} from "../../_snowpack/pkg/date-fns.js";
export const euroFieldTransform = (_value, originalValue) => {
  if (originalValue) {
    return Number(originalValue.replace(/,/, "."));
  } else {
    return void 0;
  }
};
export const dateTransform = (_value, originalValue) => {
  if (originalValue) {
    return format(new Date(_value), "yyyy-MM-dd");
  } else {
    return "";
  }
};
