import ControllerImpl from "../../../application/controller/ControllerImpl.js";
import PromiseWrapperImpl from "../../../infrastructure/api/PromiseWrapperImpl.js";
import SurveyServiceImpl from "../infrastructure/SurveyServiceImpl.js";
export class SurveyControllerImpl extends ControllerImpl {
  constructor() {
    super(void 0);
    this.surveyService = new SurveyServiceImpl();
    this.wrapper = new PromiseWrapperImpl();
  }
  getResponse(surveyRequest) {
    return this.wrapper.wrapPromise(this.surveyService.getSurveyHasBeenCompleted(surveyRequest), (response) => {
      this._setState(response);
    });
  }
}
const surveyController = new SurveyControllerImpl();
export default surveyController;
