import tokenFetcher from "../../token/tokenFetcher.js";
import PromiseParser from "./PromiseParser.js";
class ApiServiceImpl {
  constructor() {
    this.tokenGetter = new tokenFetcher();
  }
  getAll(url, init) {
    const promise = fetch(url, {...init, method: "GET"});
    return this.promiseHandler(promise);
  }
  get(url, init) {
    const promise = fetch(url, {...init, method: "GET"});
    return this.promiseHandler(promise);
  }
  async post(url, body, init, options) {
    let convertedValue;
    if (options?.bodyTransformer) {
      convertedValue = options.bodyTransformer(body);
    } else {
      convertedValue = JSON.stringify(body);
    }
    const promise = fetch(url, {
      ...init,
      body: convertedValue,
      method: "POST",
      headers: {"content-type": "application/json"}
    });
    return this.promiseHandler(promise);
  }
  async postWithoutParse(url, body, init, options) {
    let convertedValue;
    if (options?.bodyTransformer) {
      convertedValue = options.bodyTransformer(body);
    } else {
      convertedValue = JSON.stringify(body);
    }
    const response = await fetch(url, {
      ...init,
      body: convertedValue,
      method: "POST",
      headers: {"content-type": "application/json"}
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return;
  }
  patch(url, body, init, options) {
    let convertedValue;
    if (options?.bodyTransformer) {
      convertedValue = options.bodyTransformer(body);
    } else {
      convertedValue = JSON.stringify(body);
    }
    const promise = fetch(url, {
      ...init,
      body: convertedValue,
      method: "PATCH"
    });
    return this.promiseHandler(promise);
  }
  put(url, body, init, options) {
    let convertedValue;
    if (options?.bodyTransformer) {
      convertedValue = options.bodyTransformer(body);
    } else {
      convertedValue = JSON.stringify(body);
    }
    const promise = fetch(url, {
      ...init,
      body: convertedValue,
      method: "PUT"
    });
    return this.promiseHandler(promise);
  }
  delete(url, init) {
    const promise = fetch(url, {...init, method: "DELETE"});
    return this.promiseHandler(promise);
  }
  async promiseHandler(promise) {
    const promiseParser = new PromiseParser(promise);
    return promiseParser.parse();
  }
  async getAuthHeader() {
    const token = await this.tokenGetter.getToken();
    if (token) {
      return {
        Authorization: `Bearer ${token}`
      };
    }
    throw Error("Missing Token");
  }
  _getDefaultContentType() {
    return {
      "Content-Type": "application/json"
    };
  }
}
export default ApiServiceImpl;
