import * as __SNOWPACK_ENV__ from '../../../../_snowpack/env.js';

import './ToastNotification.svelte.css';
/* src/application/notifications/presentation/ToastNotification.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { InlineNotification, ToastNotification } from '../../../../_snowpack/pkg/carbon-components-svelte.js';
import notificationController from '../application/NotificationControllerImpl.js';

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (22:1) {:else}
function create_else_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value_1 = /*$notificationController*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$notificationController, timeout, handleClose*/ 3) {
				each_value_1 = /*$notificationController*/ ctx[0];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (10:1) {#if __SNOWPACK_ENV__.SNOWPACK_PUBLIC_ENABLE_ADVANCED_DEBUGGING === 'true'}
function create_if_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value = /*$notificationController*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$notificationController, timeout, handleClose*/ 3) {
				each_value = /*$notificationController*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (23:2) {#each $notificationController as notification}
function create_each_block_1(ctx) {
	let inlinenotification;
	let current;

	function close_handler_1() {
		return /*close_handler_1*/ ctx[3](/*notification*/ ctx[4]);
	}

	inlinenotification = new InlineNotification({
			props: {
				class: "toast",
				title: /*notification*/ ctx[4].title,
				subtitle: /*notification*/ ctx[4].subtitle,
				timeout,
				kind: /*notification*/ ctx[4].type
			}
		});

	inlinenotification.$on("close", close_handler_1);

	return {
		c() {
			create_component(inlinenotification.$$.fragment);
		},
		m(target, anchor) {
			mount_component(inlinenotification, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const inlinenotification_changes = {};
			if (dirty & /*$notificationController*/ 1) inlinenotification_changes.title = /*notification*/ ctx[4].title;
			if (dirty & /*$notificationController*/ 1) inlinenotification_changes.subtitle = /*notification*/ ctx[4].subtitle;
			if (dirty & /*$notificationController*/ 1) inlinenotification_changes.kind = /*notification*/ ctx[4].type;
			inlinenotification.$set(inlinenotification_changes);
		},
		i(local) {
			if (current) return;
			transition_in(inlinenotification.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(inlinenotification.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(inlinenotification, detaching);
		}
	};
}

// (11:2) {#each $notificationController as notification}
function create_each_block(ctx) {
	let toastnotification;
	let current;

	function close_handler() {
		return /*close_handler*/ ctx[2](/*notification*/ ctx[4]);
	}

	toastnotification = new ToastNotification({
			props: {
				class: "toast",
				title: /*notification*/ ctx[4].title,
				subtitle: /*notification*/ ctx[4].subtitle,
				caption: /*notification*/ ctx[4].caption,
				timeout,
				kind: /*notification*/ ctx[4].type
			}
		});

	toastnotification.$on("close", close_handler);

	return {
		c() {
			create_component(toastnotification.$$.fragment);
		},
		m(target, anchor) {
			mount_component(toastnotification, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const toastnotification_changes = {};
			if (dirty & /*$notificationController*/ 1) toastnotification_changes.title = /*notification*/ ctx[4].title;
			if (dirty & /*$notificationController*/ 1) toastnotification_changes.subtitle = /*notification*/ ctx[4].subtitle;
			if (dirty & /*$notificationController*/ 1) toastnotification_changes.caption = /*notification*/ ctx[4].caption;
			if (dirty & /*$notificationController*/ 1) toastnotification_changes.kind = /*notification*/ ctx[4].type;
			toastnotification.$set(toastnotification_changes);
		},
		i(local) {
			if (current) return;
			transition_in(toastnotification.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(toastnotification.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(toastnotification, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current_block_type_index;
	let if_block;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (__SNOWPACK_ENV__.SNOWPACK_PUBLIC_ENABLE_ADVANCED_DEBUGGING === 'true') return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			if_block.c();
			attr(div, "class", "root svelte-1dlzhk3");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if_block.p(ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
		}
	};
}

const timeout = 5000;

function instance($$self, $$props, $$invalidate) {
	let $notificationController;
	component_subscribe($$self, notificationController, $$value => $$invalidate(0, $notificationController = $$value));

	function handleClose(notification) {
		notificationController.remove(notification);
	}

	const close_handler = notification => handleClose(notification);
	const close_handler_1 = notification => handleClose(notification);
	return [$notificationController, handleClose, close_handler, close_handler_1];
}

class ToastNotification_1 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ToastNotification_1;