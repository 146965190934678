import {writable} from "../../../_snowpack/pkg/svelte/store.js";
class Controller {
  constructor(initialValue, storage) {
    this.state = writable(initialValue);
    this.storage = storage;
    if (this.storage) {
      this.storage.dataStore = this.state;
    }
  }
  subscribe(run, invalidate) {
    return this.state.subscribe(run, invalidate);
  }
}
export default Controller;
