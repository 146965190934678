import './ContactComponent.svelte.css';
/* src/features/financeDataForm/presentation/ContactComponent.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	handle_promise,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out,
	update_await_block_branch
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { Loading } from "../../../../_snowpack/pkg/carbon-components-svelte.js";
import bankContactInfoController from "../application/BankContactInfoControllerImpl.js";
import loanLeadController from "../application/LoanLeadControllerImpl.js";
import userInformationController from "../application/UserInformationControllerImpl.js";
import ClosingButton from "./components/ClosingButton.svelte.js";
import PictureInfo from "./PictureInfo.svelte.js";

function create_catch_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<h4 class="svelte-13k7fyh">Es ist ein Fehler aufgetreten</h4>`;
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (21:0) {:then value}
function create_then_block(ctx) {
	let div1;
	let div0;
	let t1;
	let pictureinfo;
	let t2;
	let closingbutton;
	let current;
	pictureinfo = new PictureInfo({ props: { bankData: /*value*/ ctx[2] } });

	closingbutton = new ClosingButton({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");

			div0.innerHTML = `<p class="svelte-13k7fyh">Deine persönlicher Finanzierungsprofi meldet sich bei Dir, um Dich auf
        deinem Weg ins neue Zuhause zu begleiten.</p>`;

			t1 = space();
			create_component(pictureinfo.$$.fragment);
			t2 = space();
			create_component(closingbutton.$$.fragment);
			attr(div0, "class", "textContainer svelte-13k7fyh");
			attr(div1, "class", "container svelte-13k7fyh");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div1, t1);
			mount_component(pictureinfo, div1, null);
			append(div1, t2);
			mount_component(closingbutton, div1, null);
			current = true;
		},
		p(ctx, dirty) {
			const pictureinfo_changes = {};
			if (dirty & /*bankContactPromise*/ 1) pictureinfo_changes.bankData = /*value*/ ctx[2];
			pictureinfo.$set(pictureinfo_changes);
			const closingbutton_changes = {};

			if (dirty & /*$$scope*/ 8) {
				closingbutton_changes.$$scope = { dirty, ctx };
			}

			closingbutton.$set(closingbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(pictureinfo.$$.fragment, local);
			transition_in(closingbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pictureinfo.$$.fragment, local);
			transition_out(closingbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(pictureinfo);
			destroy_component(closingbutton);
		}
	};
}

// (30:4) <ClosingButton>
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Zurück zum Exposé");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (19:27)    <Loading /> {:then value}
function create_pending_block(ctx) {
	let loading;
	let current;
	loading = new Loading({});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

function create_fragment(ctx) {
	let await_block_anchor;
	let promise;
	let current;

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: true,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 2,
		blocks: [,,,]
	};

	handle_promise(promise = /*bankContactPromise*/ ctx[0], info);

	return {
		c() {
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			info.ctx = ctx;

			if (dirty & /*bankContactPromise*/ 1 && promise !== (promise = /*bankContactPromise*/ ctx[0]) && handle_promise(promise, info)) {
				
			} else {
				update_await_block_branch(info, ctx, dirty);
			}
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $userInformationController;
	component_subscribe($$self, userInformationController, $$value => $$invalidate(1, $userInformationController = $$value));
	let bankContactPromise;

	if ($userInformationController !== undefined) {
		bankContactPromise = bankContactInfoController.getResponse({
			fioEstateId: $userInformationController.estate_id
		});

		loanLeadController.createLateLoanLead({
			fioEstateId: $userInformationController.estate_id,
			fioContactId: $userInformationController.contact_id
		});
	}

	return [bankContactPromise];
}

class ContactComponent extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ContactComponent;