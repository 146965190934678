const themeColors = {
  "interactive-01": "#0062ff",
  "interactive-02": "#171717",
  "interactive-03": "#0062ff",
  "interactive-04": "#0062ff",
  "ui-background": "#ffffff",
  "ui-01": "#f9f9f9",
  "ui-02": "#ffffff",
  "ui-03": "#dcdcdc",
  "ui-04": "#8c8c8c",
  "ui-05": "#171717",
  "text-01": "#171717",
  "text-02": "#565656",
  "text-03": "#8c8c8c",
  "text-04": "#ffffff",
  "icon-01": "#171717",
  "icon-02": "#565656",
  "icon-03": "#ffffff",
  "link-01": "#0062ff",
  "link-02": "#0043ce",
  "field-01": "#f3f3f3",
  "field-02": "#ffffff",
  "inverse-01": "#ffffff",
  "inverse-02": "#3d3d3d",
  "support-01": "#da1e28",
  "support-02": "#24a148",
  "support-03": "#fdd13a",
  "support-04": "#054ada",
  "inverse-support-01": "#fb4b53",
  "inverse-support-02": "#3dbb61",
  "inverse-support-03": "#fdd13a",
  "inverse-support-04": "#408bfc",
  "overlay-01": "rgba(23, 23, 23, 0.5)",
  focus: "#0062ff",
  "hover-primary": "#0353e9",
  "active-primary": "#0530ad",
  "hover-primary-text": "#054ada",
  "hover-secondary": "#4c4c4c",
  "active-secondary": "#6f6f6f",
  "hover-tertiary": "#0353e9",
  "active-tertiary": "#0530ad",
  "hover-ui": "#e5e5e5",
  "active-ui": "#bebebe",
  "selected-ui": "#dcdcdc",
  "hover-selected-ui": "#cacaca",
  "hover-danger": "#ba1b23",
  "active-danger": "#750e13",
  "hover-row": "#e5e5e5",
  "visited-link": "#8a3ffc",
  "disabled-01": "#f3f3f3",
  "disabled-02": "#bebebe",
  "disabled-03": "#8c8c8c",
  highlight: "#c9deff",
  "skeleton-01": "#e5e5e5",
  "skeleton-02": "#bebebe",
  "brand-01": "#0062ff",
  "brand-02": "#171717",
  "brand-03": "#0062ff",
  "active-01": "#bebebe",
  "hover-field": "#e5e5e5",
  "button-color": "#0b3478",
  "bottomBar-color": "#8c8c8c"
};
export default themeColors;
