/* src/features/financeDataForm/presentation/components/FormNumberTextInput.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	add_flush_callback,
	bind,
	binding_callbacks,
	create_component,
	destroy_component,
	init,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../../../../_snowpack/pkg/svelte/internal.js";

import { TextInput } from "../../../../../_snowpack/pkg/carbon-components-svelte.js";

function create_fragment(ctx) {
	let textinput;
	let updating_value;
	let current;

	function textinput_value_binding(value) {
		/*textinput_value_binding*/ ctx[12](value);
	}

	let textinput_props = {
		id: /*id*/ ctx[1],
		name: /*name*/ ctx[0],
		labelText: /*labelText*/ ctx[2],
		invalid: /*invalid*/ ctx[4],
		invalidText: /*invalidText*/ ctx[3],
		light: true
	};

	if (/*value*/ ctx[5] !== void 0) {
		textinput_props.value = /*value*/ ctx[5];
	}

	textinput = new TextInput({ props: textinput_props });
	binding_callbacks.push(() => bind(textinput, 'value', textinput_value_binding));
	textinput.$on("input", /*handleInput*/ ctx[7]);
	textinput.$on("focus", /*handleFocus*/ ctx[8]);
	textinput.$on("blur", /*handleBlur*/ ctx[6]);

	return {
		c() {
			create_component(textinput.$$.fragment);
		},
		m(target, anchor) {
			mount_component(textinput, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const textinput_changes = {};
			if (dirty & /*id*/ 2) textinput_changes.id = /*id*/ ctx[1];
			if (dirty & /*name*/ 1) textinput_changes.name = /*name*/ ctx[0];
			if (dirty & /*labelText*/ 4) textinput_changes.labelText = /*labelText*/ ctx[2];
			if (dirty & /*invalid*/ 16) textinput_changes.invalid = /*invalid*/ ctx[4];
			if (dirty & /*invalidText*/ 8) textinput_changes.invalidText = /*invalidText*/ ctx[3];

			if (!updating_value && dirty & /*value*/ 32) {
				updating_value = true;
				textinput_changes.value = /*value*/ ctx[5];
				add_flush_callback(() => updating_value = false);
			}

			textinput.$set(textinput_changes);
		},
		i(local) {
			if (current) return;
			transition_in(textinput.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(textinput.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(textinput, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { name } = $$props;
	let { id } = $$props;
	let { labelText } = $$props;
	let { updateField } = $$props;
	let { updateTouched } = $$props;
	let { invalidText } = $$props;
	let { invalid } = $$props;
	let { wasValidated } = $$props;
	let value;
	let valueNumber;

	function handleBlur() {
		if (valueNumber !== undefined) {
			$$invalidate(5, value = Intl.NumberFormat("de-DE", { currency: "EUR", style: "currency" }).format(Number(valueNumber)));

			if (wasValidated) {
				updateField(name, valueNumber);
				updateTouched(name, false);
			} else {
				updateField(name, valueNumber);
				updateTouched(name, false);
			}
		}
	}

	function handleInput(e) {
		const value = e.detail;

		if (value !== null) {
			if (typeof value === "string") {
				const number = value.replace("€", "").replace(",", ".");
				valueNumber = number;
			}
		}
	}

	function handleFocus() {
		$$invalidate(5, value = undefined);
		valueNumber = undefined;
	}

	function textinput_value_binding(value$1) {
		value = value$1;
		$$invalidate(5, value);
	}

	$$self.$$set = $$props => {
		if ('name' in $$props) $$invalidate(0, name = $$props.name);
		if ('id' in $$props) $$invalidate(1, id = $$props.id);
		if ('labelText' in $$props) $$invalidate(2, labelText = $$props.labelText);
		if ('updateField' in $$props) $$invalidate(9, updateField = $$props.updateField);
		if ('updateTouched' in $$props) $$invalidate(10, updateTouched = $$props.updateTouched);
		if ('invalidText' in $$props) $$invalidate(3, invalidText = $$props.invalidText);
		if ('invalid' in $$props) $$invalidate(4, invalid = $$props.invalid);
		if ('wasValidated' in $$props) $$invalidate(11, wasValidated = $$props.wasValidated);
	};

	return [
		name,
		id,
		labelText,
		invalidText,
		invalid,
		value,
		handleBlur,
		handleInput,
		handleFocus,
		updateField,
		updateTouched,
		wasValidated,
		textinput_value_binding
	];
}

class FormNumberTextInput extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			name: 0,
			id: 1,
			labelText: 2,
			updateField: 9,
			updateTouched: 10,
			invalidText: 3,
			invalid: 4,
			wasValidated: 11
		});
	}
}

export default FormNumberTextInput;