import ControllerImpl from "../../../application/controller/ControllerImpl.js";
import PromiseWrapperImpl from "../../../infrastructure/api/PromiseWrapperImpl.js";
import BankContactServiceImpl from "../infrastructure/BankContactServiceImpl.js";
export class BankContactInfoControllerImpl extends ControllerImpl {
  constructor() {
    super(void 0);
    this.bankService = new BankContactServiceImpl();
    this.wrapper = new PromiseWrapperImpl();
  }
  getResponse(id) {
    return this.wrapper.wrapPromise(this.bankService.getBankContactInfo(id), (response) => {
      this._setState(response);
    });
  }
}
const bankContactInfoController = new BankContactInfoControllerImpl();
export default bankContactInfoController;
