import Controller from "./controller.js";
class ControllerImpl extends Controller {
  _setState(response) {
    this.state.set(response);
  }
  _updateState(response, indexSearcher) {
    this.state.update((values) => {
      if (values instanceof Array) {
        if (indexSearcher) {
          const index = indexSearcher(response, values);
          values.splice(index, 1, response);
        } else {
          values.splice(0, 0, response);
        }
        return values;
      }
      return response;
    });
  }
  _deleteState(value, indexSearcher) {
    this.state.update((values) => {
      if (values instanceof Array) {
        if (value && indexSearcher) {
          const index = indexSearcher(value, values);
          values.splice(index, 1);
          return values;
        }
        if (value) {
          throw Error("Can´t delete value with missing indexSearcher");
        }
        return new Array();
      }
      return void 0;
    });
  }
}
export default ControllerImpl;
