/* src/features/financeDataForm/presentation/icons/HomecloudIcon.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	init,
	insert,
	noop,
	safe_not_equal,
	svg_element
} from "../../../../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "d", "M14.4199 47C10.0761 47 6.27887 44.0074 5.31317 39.8619C2.10632 38.3454 0.025509 35.1362 0 31.6332V15.5504C0 11.8969 2.0881 8.61422 5.448 6.98391L16.5809 0.962031C17.8782 0.330469 19.3213 0 20.7716 0C22.222 0 23.6615 0.330469 24.9442 0.951016L36.1135 6.99125C39.2548 8.51508 41.5433 11.7206 41.5433 14.603V34.1558C41.5433 35.742 41.5324 36.4397 41.4522 36.7518C40.7197 42.5974 35.6653 46.9963 29.6634 46.9963H14.4199V47ZM14.3762 32.0224C11.2058 32.0224 8.57833 34.5523 8.51637 37.6624C8.51637 37.7138 8.51637 37.7432 8.51637 37.7762C8.51637 38.5694 8.67672 39.3331 8.99376 40.0455C9.92302 42.1641 12.0366 43.5301 14.3798 43.5301H29.339C33.927 43.5301 37.7607 39.9463 37.8919 35.5364C37.9975 31.9416 35.6507 28.6663 32.2325 27.5097C32.4183 28.1192 32.5532 28.7471 32.637 29.3787C32.6989 29.8597 32.5459 30.348 32.2216 30.7079C31.8972 31.0677 31.4344 31.2734 30.9461 31.2734C30.0897 31.2734 29.3645 30.6345 29.2552 29.7899C28.7268 25.6921 25.1483 22.6004 20.9393 22.6004C17.0911 22.6004 13.7603 25.1707 12.8201 28.7875C13.3339 28.703 13.8587 28.659 14.3835 28.659C18.9313 28.659 22.7759 31.8572 23.5266 36.2671C23.6104 36.7518 23.472 37.2438 23.1476 37.622C22.8233 38.0002 22.3459 38.2169 21.843 38.2169C21.0085 38.2169 20.3052 37.633 20.1667 36.8289C19.6966 34.042 17.2623 32.0224 14.3835 32.0224H14.3762ZM20.7716 3.51031C19.8606 3.51031 18.9569 3.71594 18.1515 4.10883L7.12431 10.0756C4.94511 11.1331 3.60042 13.2445 3.60042 15.5908V31.457C3.60042 33.0395 4.28552 34.567 5.45529 35.6502C5.96911 33.4141 7.33203 31.4312 9.278 30.1241C9.59504 23.9516 14.7187 19.1452 21.0231 19.1452C24.6855 19.1452 28.0599 20.7755 30.312 23.6212C33.1909 23.7974 35.9167 25.0275 37.9465 27.0434V15.5908C37.9465 13.2445 36.6018 11.1368 34.4408 10.0866L23.3772 4.10148C22.5901 3.71961 21.6827 3.51398 20.7753 3.51398L20.7716 3.51031Z");
			attr(path, "fill", "white");
			attr(svg, "width", "30px");
			attr(svg, "height", "30px");
			attr(svg, "viewBox", "0 0 42 47");
			attr(svg, "fill", "none");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

function instance($$self) {
	"use strict";
	return [];
}

class HomecloudIcon extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default HomecloudIcon;