import './ResultDiv.svelte.css';
/* src/features/financeDataForm/presentation/ResultDiv.svelte generated by Svelte v3.47.0 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../../../_snowpack/pkg/svelte/internal.js";

function create_if_block_1(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<strong>Finanzierung wahrscheinlich möglich</strong>`;
			attr(div, "class", "greenContainer svelte-1qalh5b");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (5:2) {#if leadScore === "yellow"}
function create_if_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<strong>Weitere Prüfung erforderlich</strong>`;
			attr(div, "class", "yellowContainer svelte-1qalh5b");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*leadScore*/ ctx[0] === "yellow") return create_if_block;
		if (/*leadScore*/ ctx[0] === "green") return create_if_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type && current_block_type(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "container svelte-1qalh5b");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if (if_block) if_block.d(1);
				if_block = current_block_type && current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);

			if (if_block) {
				if_block.d();
			}
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { leadScore } = $$props;

	$$self.$$set = $$props => {
		if ('leadScore' in $$props) $$invalidate(0, leadScore = $$props.leadScore);
	};

	return [leadScore];
}

class ResultDiv extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { leadScore: 0 });
	}
}

export default ResultDiv;